import moment from "moment";
import { Link, Route, Routes, useSearchParams } from "react-router-dom";
import {
  useGetAllChurchesQuery,
  useGetCountQuery,
} from "../redux/churches.api";
import { Paging } from "../components/paging.component";
import { RowIndex } from "../components";
import { ChurchDetails } from "./church/church.details";
const Churches = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { data, isLoading } = useGetAllChurchesQuery({
    rows: searchParams.get("rows"),
    page: searchParams.get("page"),
  });
  const countQuery = useGetCountQuery();
  return (
    <div className="card">
      <div className="card-body">
        {isLoading && <>loading...</>}
        {!isLoading && (
          <table className="table">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data?.map(({ Id, title, about, createdDate }, index) => (
                <tr key={Id}>
                  <td>
                    <RowIndex index={index} />
                  </td>
                  <td>
                    <Link to={`${Id}`}>{title}</Link>{" "}
                  </td>
                  <td>{about}</td>
                  <td>{moment(createdDate).format("DD/MM/yyyy")}</td> <td></td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {!countQuery.isLoading && (
          <Paging
            total={countQuery.data}
            pageSize={searchParams.get("rows") ?? 5}
          />
        )}
      </div>
    </div>
  );
};

const ChurchRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Churches />}></Route>
        <Route path="/:id" element={<ChurchDetails />} />
      </Routes>
    </>
  );
};

export { ChurchRoutes, Churches };
