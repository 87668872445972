export const About = () => (
  <>
    <section className="ftco-section">
      <div className="container-xl">
        <div className="row g-lg-5">
          <div className="col-lg-6 order-lg-last heading-section d-flex align-items-center">
            <div className="mt-0 my-lg-5 py-5">
              <span className="subheading">About Union Corporation</span>
              <h2 className="mb-4">
                More than 40M+ Trusted Our Financial &amp; Conusultation
                Institution
              </h2>
              <p>
                Far far away, behind the word mountains, far from the countries
                Vokalia and Consonantia, there live the blind texts. Separated
                they live in Bookmarksgrove right at the coast of the Semantics,
                a large language ocean.
              </p>
              <p>
                A small river named Duden flows by their place and supplies it
                with the necessary regelialia. It is a paradisematic country, in
                which roasted parts of sentences fly into your mouth.
              </p>
              <p className="mt-4">
                <a href="#" className="btn btn-primary py-3 px-4">
                  Learn More
                </a>
              </p>
            </div>
          </div>
          <div className="col-lg-6 d-flex align-items-stretch">
            <div className="row">
              <div className="col-md-6 d-flex align-items-stretch services-wrap">
                <div className="services">
                  <div className="icon">
                    <span className="flaticon-accountant"></span>
                  </div>
                  <div className="text">
                    <h2>Professional Consultants</h2>
                    <p className="mb-0">
                      Far far away, behind the word mountains, far from the
                      countries Vokalia.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 d-flex align-items-stretch services-wrap">
                <div className="services active">
                  <div className="icon">
                    <span className="flaticon-research"></span>
                  </div>
                  <div className="text">
                    <h2>Comprehensive Services</h2>
                    <p className="mb-0">
                      Far far away, behind the word mountains, far from the
                      countries Vokalia.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 d-flex align-items-stretch services-wrap">
                <div className="services">
                  <div className="icon">
                    <span className="flaticon-recession"></span>
                  </div>
                  <div className="text">
                    <h2>A Culture that Delivers</h2>
                    <p className="mb-0">
                      Far far away, behind the word mountains, far from the
                      countries Vokalia.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-md-6 d-flex align-items-stretch services-wrap">
                <div className="services">
                  <div className="icon">
                    <span className="flaticon-money"></span>
                  </div>
                  <div className="text">
                    <h2>Industry Experience</h2>
                    <p className="mb-0">
                      Far far away, behind the word mountains, far from the
                      countries Vokalia.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section
      className="ftco-section ftco-image img"
      style={{
        backgroundImage: `url(../assets/images/xbg_4.jpg.pagespeed.ic.u02rQcfKh_.jpg)`,
      }}
    >
      <div className="container">
        <div className="row justify-content-end">
          <div className="col-md-6 heading-section">
            <span className="subheading">About Union Corporation</span>
            <h2 className="mb-4">Quality Makes the Belief for Customers</h2>
            <p>
              Far far away, behind the word mountains, far from the countries
              Vokalia and Consonantia, there live the blind texts. Separated
              they live in Bookmarksgrove right at the coast of the Semantics, a
              large language ocean.
            </p>
            <a
              href="https://www.youtube.com/watch?v=9ZZ7pq331Dc"
              className="d-flex glightbox pt-4 align-items-center"
            >
              <div className="icon">
                <span className="fa fa-play"></span>
              </div>
              <div className="text ps-4">
                <span>Watch Video</span>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
  </>
);
