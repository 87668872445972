import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiBaseUrl as baseUrl } from "../_constants";

export const prayerApi = createApi({
  reducerPath: "prayerApi",
  baseQuery: fetchBaseQuery({ baseUrl }),
  tagTypes: ["Post"],
  endpoints: (builder) => ({
    getAll: builder.query({
      query: () => "prayer-request/index.php",
      providesTags: ["Post"],
    }),
    getPrayerRequest: builder.query({
      query: (id) => `prayer-request/details.php?id=${id}`,
    }),
    postRequest: builder.mutation({
      query: (obj) => ({
        url: `prayer-request/index.php`,
        body: obj,
        method: "POST",
      }),
      invalidatesTags: ["Post"],
    }),
    count: builder.query({
      query: () => `/prayer-request/count.php`,
      invalidatesTags: ["Post"],
    }),
  }),
});

export const { useGetAllQuery, useGetPrayerRequestQuery, useCountQuery, usePostRequestMutation } =
  prayerApi;
