export const CaseStudies = () => (
  <section className="ftco-gallery ftco-no-pb ftco-section">
    <div className="container-xl-fluid">
      <div className="row justify-content-center">
        <div className="col-lg-5 heading-section text-center mb-5">
          <span className="subheading">Case Studies</span>
          <h2 className="mb-4">We Take Every Case Studies Very Seriously</h2>
        </div>
      </div>
      <div className="row g-0">
        <div className="col-md-3">
          <a
            href="images/gallery-1.jpg"
            className="gallery-wrap img d-flex align-items-end justify-content-center glightbox"
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/image-01.jpg)`,
            }}
          >
            <div className="icon d-flex align-items-center justify-content-center">
              <span className="fa fa-search"></span>
            </div>
            <div className="text">
              <h2 className="mb-0">Business Finance Consulting</h2>
            </div>
          </a>
        </div>
        <div className="col-md-3">
          <a
            href="images/gallery-1.jpg"
            className="gallery-wrap img d-flex align-items-end justify-content-center glightbox"
            style={{
              backgroundImage: `url(../assets/images/xgallery-1.jpg.pagespeed.ic.hLNkZmeXOJ.jpg)`,
            }}
          >
            <div className="icon d-flex align-items-center justify-content-center">
              <span className="fa fa-search"></span>
            </div>
            <div className="text">
              <h2 className="mb-0">Business Finance Consulting</h2>
            </div>
          </a>
        </div>
        <div className="col-md-3">
          <a
            href="images/gallery-3.jpg"
            className="gallery-wrap img d-flex align-items-end justify-content-center glightbox"
            style={{
              backgroundImage: `url(../assets/images/xgallery-1.jpg.pagespeed.ic.hLNkZmeXOJ.jpg)`,
            }}
          >
            <div className="icon d-flex align-items-center justify-content-center">
              <span className="fa fa-search"></span>
            </div>
            <div className="text">
              <h2 className="mb-0">Business Finance Consulting</h2>
            </div>
          </a>
        </div>
        <div className="col-md-3">
          <a
            href="images/gallery-4.jpg"
            className="gallery-wrap img d-flex align-items-end justify-content-center glightbox"
            style={{
              backgroundImage: `url(../assets/images/xgallery-1.jpg.pagespeed.ic.hLNkZmeXOJ.jpg)`,
            }}
          >
            <div className="icon d-flex align-items-center justify-content-center">
              <span className="fa fa-search"></span>
            </div>
            <div className="text">
              <h2 className="mb-0">Business Finance Consulting</h2>
            </div>
          </a>
        </div>
      </div>
    </div>
  </section>
);
