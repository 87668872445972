import { NavLink, Link, useNavigate } from "react-router-dom";
import { PencilFill, TrashFill, Gear } from "react-bootstrap-icons";

import { useGetAllConfigsQuery, useDeleteConfigMutation } from "../redux/config.api";

const TableView = ({ data: configs, onDelete, onEdit }) => {
  return (
    <table className="table">
      <thead>
        <tr>
          <th>#</th>
          <th>Config Name</th>
          <th>Config Value</th>
          <th>IsDeleted?</th>
        </tr>
      </thead>
      <tbody>
        {configs.map((item, index) => (
          <tr key={`config-${item.Id}`}>
            <td>{index + 1}.</td>
            <td>{item.configName}</td>
            <td>{item.configValue}</td>
            <td>{item.status == 1 ? "Yes" : "No"}</td>
            <td>
              <button
                className="btn btn-danger me-2"
                onClick={() => onDelete(item.Id)}
              >
                <TrashFill />
              </button>
              <button
                className="btn btn-primary"
                onClick={() => onEdit(item.Id)}
              >
                <PencilFill />
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export const ConfigMaster = () => {
  const navigate = useNavigate();
  const configs = useGetAllConfigsQuery();
  const [deleteConfig] = useDeleteConfigMutation();

  const handleDelete = async (id) => {
    await deleteConfig(id);
  };

  const handleEdit = async (id) => {
    navigate(`edit/${id}`);
  };

  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h3>Configs</h3>
            <div className="d-flex">
              <Link className="btn btn-primary me-2" to="/settings">
                <Gear className="icon" size={20} />
              </Link>
              <Link className="btn btn-primary me-2" to="create">
                ADD
              </Link>
              <button className="btn btn-primary" onClick={configs.refetch}>
                Refresh
              </button>
            </div>
          </div>
          {configs.isLoading && <span>loading...</span>}
          {configs.isSuccess && configs.data && (
            <TableView
              data={configs.data}
              onDelete={handleDelete}
              onEdit={handleEdit}
            />
          )}
        </div>
      </div>
    </>
  );
};
