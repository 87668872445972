import moment from "moment";
import { Link } from "react-router-dom";
import { Pencil, Trash, Calendar2Check } from "react-bootstrap-icons";
import { useGetChurchEventsQuery } from "../../redux/church-events.api";

const EventWidget = ({ churchId, options }) => {
  const { data, isLoading } = useGetChurchEventsQuery(churchId);

  return (
    <div className="card">
      <div className="card-body">
        <div style={{maxHeight:'50vh', overflowY:'auto'}}>
          {isLoading ? (
            <>loading...</>
          ) : (
            <>
              <h4>Events</h4> <hr />
              <ul className="widget">
                {data &&
                  data.map((item, index) => (
                    <li key={`event-${item.Id}`} className="mb-2 widget-item">
                      <small>
                        <Calendar2Check className="me-2" />
                        {moment(item.eventDate).format("MMM D, yyyy")}
                      </small>
                      <h5>{item.description}</h5>
                      <p>
                        some more short info can be displayed here in two lines
                      </p>
                    </li>
                  ))}
              </ul>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export { EventWidget };
