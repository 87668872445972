import { useParams, useNavigate } from "react-router-dom";
import { useGetCompanyQuery } from "../redux/company.api";
import { Post } from "../jobs/jobs.master";

const JobItem = ({ item }) => {
  return (
    <div className="card">
      <div className="card-body">{JSON.stringify(item)}</div>
    </div>
  );
};
const Details = ({ data }) => {
  const { title, description, address } = data;
  const navigate = useNavigate();
  return (
    <div className="row mb-3">
      <div className="card">
        <div className="card-body">
          <h4>{title}</h4>
          <p>{description}</p>
          <p>{address}</p>{" "}
          <button className="btn btn-primary" onClick={() => navigate(-1)}>
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export const CompanyDetails = (props) => {
  const { id } = useParams();
  const query = useGetCompanyQuery(id);

  return (
    <div>
      <div className="">
        {!query.isFetching && query.data && (
          <Details data={query.data.company} />
        )}
      </div>
      <div className="row">
        {!query.isFetching &&
          query.data.jobs &&
          query.data.jobs.map((item) => (
            <Post key={`job-${item.Id}`} item={item} />
          ))}
      </div>
    </div>
  );
};
