import { Routes, Route, Outlet } from "react-router-dom";
import { CompanyMaster } from "./company.master";

const Company = () => {
  return (
    <div className="ftco-section">
      <div className="container">
        <Routes>
          <Route exact path="/" element={<CompanyMaster />} />
        </Routes>
        <Outlet />
      </div>
    </div>
  );
};

export { Company };
export * from "./company.details";
export * from "./company.form";
