import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorMessage } from "@hookform/error-message";
import * as yup from "yup";
import { Link } from "react-router-dom";
import {
  usePostConfigMutation,
  useGetConfigQuery,
  usePutConfigMutation,
} from "../redux/config.api";
import { useEffect, useState } from "react";

export const ConfigForm = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const isAddMode = !id;
  const [skip, setSkip] = useState(isAddMode);
  const { data, isLoading } = useGetConfigQuery(id, { skip });

  const [postConfig] = usePostConfigMutation();
  const [putConfig] = usePutConfigMutation();

  const schema = yup.object({
    configName: yup.string().required("Config name is required"),
    configValue: yup.string().required("Config value is required"),
    status: yup.number().required("Status is required"),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (!isAddMode && data != null) {
      const fields = ["Id", "configName", "configValue", "status"];
      for (const field of fields) {
        setValue(field, data[field]);
      }
    }
  }, [isLoading]);

  const ShowErrorMessage = ({ field }) => (
    <ErrorMessage
      errors={errors}
      name={field}
      render={({ message }) => <p className="invalid-feedback">{message}</p>}
    />
  );

  // save new config
  function __postConfig(data) {
    postConfig(data)
      .unwrap()
      .then((respose) => {
        navigate("/configs");
      })
      .catch((error) => alert(error));
  }

  // update existing config
  function __updatConfig(data) {
    putConfig(data)
      .unwrap()
      .then((respose) => {
        navigate("/configs");
      })
      .catch((error) => console.log(error));
  }

  const onSubmit = async (data) => {
    return isAddMode ? __postConfig(data) : __updatConfig(data);
  };

  return (
    <div className="card">
      <div className="card-header">
        <div className="card-title">Config Form</div>
      </div>
      <div className="card-body">
        {isLoading ? (
          <>loading...</>
        ) : (
          <form onSubmit={handleSubmit(onSubmit)}>
            {!isAddMode && <input type="hidden" {...register("Id")}></input>}
            <div className="row mb-3">
              <label
                className={`col-md-2 col-form-label ${
                  errors.configName && "text-danger"
                } `}
              >
                Name
              </label>
              <div className="col-md-10">
                <input
                  className={`form-control  ${
                    errors.configName && "is-invalid"
                  }  `}
                  placeholder="Enter config name"
                  {...register("configName")}
                ></input>

                <ShowErrorMessage field={"configName"} />
              </div>
            </div>
            <div className="row mb-3">
              <label
                className={`col-md-2 col-form-label ${
                  errors.configValue && "text-danger"
                } `}
              >
                Value
              </label>
              <div className="col-md-10">
                <input
                  className={`form-control  ${
                    errors.configValue && "is-invalid"
                  }  `}
                  placeholder="Enter config value"
                  {...register("configValue")}
                ></input>

                <ShowErrorMessage field={"configValue"} />
              </div>
            </div>
            <div className="row mb-3">
              <label
                className={`col-md-2 col-form-label ${
                  errors.status && "text-danger"
                } `}
              >
                Status
              </label>
              <div className="col-md-10">
                <select
                  className={`form-control  ${errors.status && "is-invalid"}`}
                  {...register("status")}
                >
                  <option value={0}>No</option>
                  <option value={1}>Yes</option>
                </select>

                <ShowErrorMessage field={"isDeleted"} />
              </div>
            </div>
            <div className="row">
              <div className="col-md-10 offset-md-2">
                <button className="btn btn-primary me-2">SAVE</button>
                <Link to={"../"} className="btn btn-secondary">
                  CANCEL
                </Link>
              </div>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};
