import { useEffect, useState } from "react";
import { useGetSubscriptionByIdQuery } from "../redux/subscription.api";
import { useAuthentication } from "./auth.hook";
const profileMenuItems = [
  { url: "", title: "Dashboard" },
  { url: "profile", title: "Profile" },
  { url: "church", title: "My Church" },
  { url: "church", title: "Events" },
  { url: "church", title: "Notifications" },
  { url: "messages", title: "Messages" },
  { url: "job", title: "Job" },
  { url: "matrimony", title: "Matrimony" },
];

const churchMenuItems = [
  { url: "", title: "Dashboard" },
  { url: "profile", title: "Profile" },
  { url: "services", title: "Services" },
  { url: "events", title: "Events" },
  // { url: "notifications", title: "Notifications" },
  { url: "members", title: "Members" },
];

const employeeMenuItems = [
  { url: "", title: "Dashboard" },
  { url: "profile", title: "Profile" },
  { url: "jobs-applied", title: "Applied Jobs" },
  { url: "jobs-liked", title: "Liked Jobs" },
  { url: "messages", title: "Messages" },
];

const companyMenuItems = [
  { url: "", title: "Dashboard" },
  { url: "jobs", title: "Jobs" },
  { url: "profile", title: "Profile" },
];

const adinMenuItems = [
  { url: "", title: "Dashboard" },
  { url: "my-profile", title: "Profile" },
  { url: "configs", title: "Configs" },
  { url: "churches", title: "Churches" },
  { url: "profiles", title: "Members" },
  { url: "subscriptions", title: "Subscriptions" },
  { url: "company", title: "Companies" },
  { url: "matrimony", title: "Matrimony" },
  { url: "users", title: "Users" },
];

const useSubscriptions = (id) => {
  const { data, isLoading, refetch } = useGetSubscriptionByIdQuery(id);
  const [response, setResponse] = useState();
  const { user } = useAuthentication();
  useEffect(() => {
    if (!isLoading) {
      const { configValue: profileType } = user.typeInfo;
      const __services = [];
      let __items = [];

      if (data && data.subscriptions) {
        data.subscriptions.forEach((element) => {
          __services.push(element.title);
        });
      }

      __services.map((item) => {
        // if (item == "MEMBER") __items = [...profileMenuItems];
        if (item == "CHURCH") __items = [...churchMenuItems];

        if (item == "EMPLOYEE") __items = [...employeeMenuItems];

        if (item == "COMPANY") __items = [...companyMenuItems];
        if (item == "ADMIN") __items = [...adinMenuItems];
      });
      switch (profileType) {
        case "MEMBER":
          __items = [...profileMenuItems];
          break;
        case "COMPANY":
          __items = [...companyMenuItems];
          break;
        default:
          break;
      }
      setResponse((state) => ({
        ...state,
        services: __services,
        menus: __items,
      }));
    }
  }, [isLoading]);

  return { response };
};

export { useSubscriptions };
