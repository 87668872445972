import { createSlice } from "@reduxjs/toolkit";
import { churchEvents } from "../data";

export const churchEventSlice = createSlice({
  name: "churchEventSlice",
  initialState: { items: churchEvents },
  reducers: {},
});

export const {} = churchEventSlice.actions;
export default churchEventSlice.reducer;
