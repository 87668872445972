import { Routes, Route, Outlet } from "react-router-dom";

import { Configs } from "../config/index";
import { Subscriptons } from "../subscriptions";
import { ChurchRoutes } from "./churches";
import { ProfileRoutes } from "./users";
import { ProfileView } from "../my-account/profile";
import { MessageRoute } from "../messages";
import { Company } from "./company";
import { Matrimony } from "./matrimony";
import { LoginRoutes } from "./login";
import { Dashboard } from "./dashboard";


const AdminAccount = ({ user }) => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/my-profile" element={<ProfileView />} />
        <Route path="/configs/*" element={<Configs />} />
        <Route path="/subscriptions/*" element={<Subscriptons />} />
        <Route path="/churches/*" element={<ChurchRoutes />} />
        <Route path="/profiles/*" element={<ProfileRoutes />} />
        <Route path="/messages/*" element={<MessageRoute />} />
        <Route path="/company/*" element={<Company />} />
        <Route path="/matrimony/*" element={<Matrimony />} />
        <Route path="/users/*" element={<LoginRoutes />} />
      </Routes>
      <Outlet />
    </>
  );
};
export { AdminAccount };
