import { forwardRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorMessage } from "@hookform/error-message";

import moment from "moment";
// import { useGetAllChurchesQuery } from "../redux/churches.api";
import { useSearchConfigQuery } from "../../redux/config.api";
import { useSelector } from "react-redux";
export const StepOne = (props, ref) => {
  const configQuery = useSearchConfigQuery({
    name: "PROFILE_TYPE",
    value: "MEMBER",
  });
  const { data: stepData } = useSelector(
    (state) => state.profileWizard.steps[0]
  );
  const validationSchema = yup.object().shape({
    firstName: yup.string().required("first name is required"),
    lastName: yup.string().required("last name is required"),
    dob: yup
      .date()
      .required("Date of birth is required")
      .typeError("Enter a valid Date"),
    genderId: yup
      .number()
      .required("Gender is required")
      .typeError("Select a valid gender"),
    mobile: yup
      .number()
      .required("Mobile number is required")
      .typeError("Enter a valid number"),
    email: yup.string().required("Email Id is required"),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) });

  useEffect(() => {
    if (stepData) {
      const fields = [
        "title",
        "firstName",
        "lastName",
        "genderId",
        "dob",
        "mobile",
        "email",
      ];
      fields.map((f) => setValue(f, stepData[f]));
    }
  }, []);

  const onSubmit = async (data) => {
    const { Id: profileTypeId } = configQuery.data[0];

    const __data = {
      ...data,
      dob: moment(data.dob).format("yyyy-MM-D"),
      userTypeId: profileTypeId,
    };
    props.onStepSubmit({ ...__data });
  };

  return (
    <div className="card">
      <div className="card-body">
        
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row mb-3">
            <label className="form-label col-md-2">First Name</label>
            <div className="col-md-10 d-flex">
              <div className="col-md-2">
                <select className="form-control col" {...register("title")}>
                  <option value={"Mr."}>Mr.</option>
                  <option value={"Mrs."}>Mrs.</option>
                  <option value={"Ms."}>Ms.</option>
                  <option value={"Master."}>Master.</option>
                </select>
              </div>
              <div className="col mx-3">
                <input
                  type="text"
                  className={`form-control ${
                    errors.firstName && "is-invalid"
                  } `}
                  placeholder="first name"
                  {...register("firstName")}
                />
                <ErrorMessage
                  errors={errors}
                  name="firstName"
                  render={({ message }) => (
                    <p className="invalid-feedback">{message}</p>
                  )}
                />
              </div>
            </div>
          </div>
          <div className="col">
            <label className="form-label col-md-2">Last Name</label>
            <div className="col-md-10">
              <input
                type="text"
                className={`form-control ${errors.lastName && "is-invalid"} `}
                placeholder="last name"
                {...register("lastName")}
              />
              <ErrorMessage
                errors={errors}
                name="lastName"
                render={({ message }) => (
                  <p className="invalid-feedback">{message}</p>
                )}
              />
            </div>
          </div>
          <div className="row mb-3">
            <label className="form-label col-md-2">Gender</label>
            <div className="col-md-10">
              <select
                type="text"
                className={`form-control ${errors.genderId && "is-invalid"} `}
                placeholder="gender"
                {...register("genderId")}
              >
                <option>select</option>
                <option value="1">Male</option>
                <option value="2">FeMale</option>
              </select>{" "}
              <ErrorMessage
                errors={errors}
                name="genderId"
                render={({ message }) => (
                  <p className="invalid-feedback">{message}</p>
                )}
              />
            </div>
          </div>
          <div className="row mb-3">
            <label className="form-label col-md-2">DOB</label>
            <div className="col-md-10">
              <input
                type="date"
                className={`form-control ${errors.dob && "is-invalid"} `}
                {...register("dob")}
              ></input>
              <ErrorMessage
                errors={errors}
                name="dob"
                render={({ message }) => (
                  <p className="invalid-feedback">{message}</p>
                )}
              />
            </div>
          </div>
          <div className="row mb-3">
            <label className="form-label col-md-2">Mobile</label>
            <div className="col-md-10">
              <input
                type="text"
                className={`form-control ${errors.mobile && "is-invalid"} `}
                placeholder="mobile"
                {...register("mobile")}
              />
              <ErrorMessage
                errors={errors}
                name="mobile"
                render={({ message }) => (
                  <p className="invalid-feedback">{message}</p>
                )}
              />
            </div>
          </div>
          <div className="row mb-3">
            <label className="form-label col-md-2">Email</label>
            <div className="col-md-10">
              <input
                type="text"
                className={`form-control ${errors.email && "is-invalid"} `}
                placeholder="enter a valid email address"
                {...register("email")}
              />
              <ErrorMessage
                errors={errors}
                name="email"
                render={({ message }) => (
                  <p className="invalid-feedback">{message}</p>
                )}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-10 offset-md-2">
              <button
                type="submit"
                ref={ref}
                className="btn btn-primary me-2 collapse"
              >
                SAVE
              </button>
              <Link className="btn btn-secondary collapse" to="/">
                CANCEL
              </Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default forwardRef(StepOne);
