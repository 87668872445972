import { useGetAllQuery } from "../redux/wishlist.api";
import moment from "moment";

const JobItem = ({ job, index }) => (
  <div>
    <h5> {`# ${index}. ${job.title} `}</h5>
    <p>
      {`${moment(job.postedDate).format("D/MM/yyyy")} =>  ${moment(
        job.appliedDate
      ).format("D/MM/yyyy")}`}
    </p>
  </div>
);

const JobsLiked = ({ user }) => {
  const { data } = useGetAllQuery({ profileId: user.Id });

  return (
    <div className="card mb-3">
      <div className="card-header">Jobs Liked</div>
      <div className="card-body">
        {data &&
          data.map((item, index) => (
            <JobItem key={`job-${item.Id}`} index={index + 1} job={item} />
          ))}
      </div>
    </div>
  );
};

export { JobsLiked };
