import { forwardRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { ErrorMessage } from "@hookform/error-message";

const StepTwo = (props, ref) => {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const { data: stepData } = useSelector(
    (state) => state.churchWizard.steps[1]
  );
  useEffect(() => {
    if (stepData) {
      const fields = [
        "title",
        "firstName",
        "lastName",
        "genderId",
        "dob",
        "email",
        "mobile",
      ];
      fields.map((f) => setValue(f, stepData[f]));
    }
  }, [stepData]);

  return (
    <div className="">
      <h4>Contact Info</h4> <hr />
      <form onSubmit={handleSubmit(props.onStepSubmit)}>
        <div className="d-flex mb-3">
          <label className="form-label col-md-2">Name</label>
          <div className="col-md-10 d-flex gx-2">
            <div className="col-md-2">
              <select className="form-control col" {...register("title")}>
                <option value={"Mr."}>Mr.</option>
                <option value={"Mrs."}>Mrs.</option>
                <option value={"Ms."}>Ms.</option>
                <option value={"Master."}>Master.</option>
                <option value={"Ps."}>Paster.</option>
              </select>
            </div>
            <div className="col mx-2">
              <input
                type="text"
                className={`form-control ${errors.firstName && "is-invalid"} `}
                placeholder="first name"
                {...register("firstName", {
                  required: "First name is required",
                })}
              />
              <ErrorMessage
                errors={errors}
                name="firstName"
                render={({ message }) => (
                  <p className="invalid-feedback">{message}</p>
                )}
              />
            </div>
            <div className="col">
              <input
                type="text"
                className={`form-control ${errors.lastName && "is-invalid"} `}
                placeholder="last name"
                {...register("lastName", { required: "Lastname is required" })}
              />
              <ErrorMessage
                errors={errors}
                name="lastName"
                render={({ message }) => (
                  <p className="invalid-feedback">{message}</p>
                )}
              />
            </div>
          </div>
        </div>

        <div className="d-flex mb-3">
          <label className="form-label col-md-2">Gender</label>
          <div className="col-md-10">
            <select
              type="text"
              className={`form-control ${errors.genderId && "is-invalid"} `}
              placeholder="gender"
              {...register("genderId", { required: "Gender is required" })}
            >
              <option selected value={null} key={`gender-0`}>
                select
              </option>
              <option value="1" key={`gender-1`}>
                Male
              </option>
              <option value="2" key={`gender-2`}>
                FeMale
              </option>
            </select>
            <ErrorMessage
              errors={errors}
              name="genderId"
              render={({ message }) => (
                <p className="invalid-feedback">{message}</p>
              )}
            />
          </div>
        </div>
        <div className="d-flex mb-3">
          <label className="form-label col-md-2">DOB</label>
          <div className="col-md-10">
            <input
              type="date"
              className={`form-control ${errors.dob && "is-invalid"} `}
              {...register("dob", { required: "Date of birth is required" })}
            ></input>
            <ErrorMessage
              errors={errors}
              name="dob"
              render={({ message }) => (
                <p className="invalid-feedback">{message}</p>
              )}
            />
          </div>
        </div>

        <div className="d-flex mb-3">
          <label className="col-md-2 col-form-label">Mobile</label>
          <div className="col-md-10">
            <input
              className={`form-control ${errors.mobile && "is-invalid"}`}
              placeholder="enter mobile number"
              {...register("mobile", {
                required: "contact mobile number is required",
              })}
            ></input>
            <ErrorMessage
              errors={errors}
              name="mobile"
              render={({ message }) => (
                <p className="invalid-feedback">{message}</p>
              )}
            />
          </div>
        </div>
        <div className="d-flex mb-3">
          <label className="col-md-2 col-form-label">Email</label>
          <div className="col-md-10">
            <input
              className={`form-control ${errors.email && "is-invalid"}`}
              placeholder="enter your email id"
              {...register("email", {
                required: "contact email address is required",
              })}
            ></input>
            <ErrorMessage
              errors={errors}
              name="email"
              render={({ message }) => (
                <p className="invalid-feedback">{message}</p>
              )}
            />
          </div>
        </div>
        <button type="submit" className="collapse" ref={ref}>
          SAVE
        </button>
      </form>
    </div>
  );
};
export default forwardRef(StepTwo);
