import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { buildString } from "../helpers/index";
import { apiBaseUrl as baseUrl } from "../_constants";

export const profilesApi = createApi({
  reducerPath: "profilesApi",
  baseQuery: fetchBaseQuery({ baseUrl }),
  tagTypes: ["POST"],
  endpoints: (builder) => ({
    getAllProfiles: builder.query({
      query: (params) => `/profiles/index.php${buildString(params)}`,
      providesTags: ["POST"],
    }),
    getProfilesCount: builder.query({
      query: () => `/profiles/index.php?dataType=count`,
      providesTags: ["POST"],
    }),
    getProfile: builder.query({
      query: (id) => `/profiles/details.php?id=${id}`,
      providesTags: ["POST"],
    }),
    postProfile: builder.mutation({
      query: (obj) => ({
        url: `/profiles/index.php`,
        method: "POST",
        body: obj,
      }),
      invalidatesTags: ["POST"],
    }),
    putProfile: builder.mutation({
      query: (obj) => ({
        url: `/profiles/index.php?id=${obj.Id}`,
        method: "PUT",
        body: obj,
      }),
      invalidatesTags: ["POST"],
    }),
    deleteProfile: builder.mutation({
      query: (obj) => ({
        url: `/profiles/index.php`,
        method: "DELETE",
        body: obj,
      }),
      invalidatesTags: ["POST"],
    }),
    getImage: builder.query({
      query: (id) => `/profiles/profileImage.php?id=${id}`,
    }),
    updateProfileImage: builder.mutation({
      query: (obj) => ({
        url: `/profiles/profileImage.php`,
        method: "POST",
        body: obj,
      }),
    }),
  }),
});

export const {
  useGetAllProfilesQuery,
  useGetProfileQuery,
  usePostProfileMutation,
  usePutProfileMutation,
  useGetImageQuery,
  useDeleteProfileMutation,
  useGetProfilesCountQuery,
  useUpdateProfileImageMutation,
} = profilesApi;
