import "./styles.css";
import "./assets/css/style.css";

import { RouteComponent, FooterComponent } from "./components";

function App() {
  return (
    <>
      <RouteComponent />
      <FooterComponent />
    </>
  );
}

export default App;
