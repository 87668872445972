import { Camera } from "react-bootstrap-icons";
import { useProfileImageBase } from "../hooks/profile-image.hook";
const ProfileImage = ({
  canupdate = false,
  previewImage,
  user,
  style,
  onChangeClicked,
}) => {
  var intials;
  var fullName = user != null ? `${user.firstName} ${user.lastName}` : "";
  intials = fullName
    .split(" ")
    .map((name) => name[0])
    .join("")
    .toUpperCase();
  if (intials.length > 2) {
    intials = intials.substring(0, 2);
  }

  const { imageBase } = useProfileImageBase(user.Id);
  return (
    <div>
      <div className={`img-preview-container ${style}`}>
        {previewImage ? (
          <img src={previewImage} alt="" className={`profile-image ${style}`} />
        ) : (
          <>
            {user.profileImage ? (
              <img
                src={imageBase}
                className={`profile-image ${style}`}
                alt=""
              />
            ) : (
              <>
                <div className={`profile-initials ${style}`}>{intials}</div>
              </>
            )}
          </>
        )}
        {canupdate && (
          <button
            type="button"
            className="icon-button bg-primary rounded-button"
            onClick={onChangeClicked}
          >
            <Camera />
          </button>
        )}
      </div>
    </div>
  );
};
export { ProfileImage };
