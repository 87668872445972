import { forwardRef, useRef, useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Pencil } from "react-bootstrap-icons";
import { ErrorMessage } from "@hookform/error-message";
import { useSelector } from "react-redux";
import noImage from "../../images/no_image_available.png";

const StepOne = (props, ref) => {
  const {
    control,
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm();

  const fileRef = useRef();
  const [image, setImage] = useState(noImage);
  const { data: stepData } = useSelector(
    (state) => state.churchWizard.steps[0]
  );

  useEffect(() => {
    if (stepData) {
      const fields = ["title", "shortNote", "address"];
      fields.map((f) => setValue(f, stepData[f]));
      if (stepData["churchImage"]) {
        setImage(URL.createObjectURL(stepData["churchImage"]));
      }
    }
  }, [stepData]);

  const onFormSubmit = (data) => {
    // console.log({
    //   ...data,
    //   churchImage:
    //     stepData != null && fileRef.current.files[0] == undefined
    //       ? stepData["churchImage"]
    //       : fileRef.current.files[0],
    // });
    props.onStepSubmit({
      ...data,
      churchImage:
        stepData != null && fileRef.current.files[0] == null
          ? stepData["churchImage"]
          : fileRef.current.files[0],
    });
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <h4>Basic Info</h4> <hr />
      <div className="d-flex">
        <div className="col-md-8">
          <div className="d-flex mb-3">
            <label className="col-md-2 col-form-label">Title</label>
            <div className="col-md-10">
              <input
                className={`form-control ${errors.title && "is-invalid"}`}
                {...register("title", {
                  required: "church name is required",
                })}
                placeholder="enter name/title"
              ></input>
              <ErrorMessage
                errors={errors}
                name="title"
                render={({ message }) => (
                  <p className="invalid-feedback">{message}</p>
                )}
              />
            </div>
          </div>
          <div className="d-flex mb-3">
            <label className="col-md-2 col-form-label">About Church</label>
            <div className="col-md-10">
              <textarea
                rows={4}
                className={`form-control ${errors.shortNote && "is-invalid"}`}
                {...register("shortNote", {
                  required: "church name is required",
                })}
                placeholder="about the church"
              ></textarea>
              <ErrorMessage
                errors={errors}
                name="shortNote"
                render={({ message }) => (
                  <p className="invalid-feedback">{message}</p>
                )}
              />
            </div>
          </div>
          <div className="d-flex mb-3">
            <label className="col-md-2 col-form-label">Address</label>
            <div className="col-md-10">
              <textarea
                rows={4}
                className={`form-control ${errors.address && "is-invalid"}`}
                {...register("address", {
                  required: "church address is required",
                })}
                placeholder="enter church address"
              ></textarea>
              <ErrorMessage
                errors={errors}
                name="address"
                render={({ message }) => (
                  <p className="invalid-feedback">{message}</p>
                )}
              />
            </div>
          </div>
          <div className="d-flex collapse">
            <div className="offset-md-2 col-md-10">
              <button className="collapse" type="submit" ref={ref}>
                SAVE
              </button>
              <button
                type="button"
                className="btn btn-secondary collapse"
                onClick={() => reset()}
              >
                RESET
              </button>
              <button className="btn btn-warning collapse" onClick={() => {}}>
                CANCEL
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="collapse">
            <Controller
              control={control}
              name="churchImage"
              render={({ field: { onChange } }) => (
                <input
                  name="churchImage"
                  type="file"
                  accept="image/jpeg,image/png"
                  {...register("churchImage")}
                  ref={fileRef}
                  onChange={(e) => {
                    console.log(e.target.files[0]);
                    setImage(URL.createObjectURL(e.target.files[0]));
                    onChange(e);
                  }}
                ></input>
              )}
            />
          </div>
          <div
            className={`img-preview-container  ${
              errors.churchImage && "invalid"
            } `}
          >
            <img src={image} alt="" className="img-preview" />
            <button
              type="button"
              onClick={() => fileRef.current.click()}
              className="icon-button bg-primary rounded-button"
            >
              <Pencil />
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default forwardRef(StepOne);
