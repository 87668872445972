import { useState, useEffect } from "react";
import { useGetImageQuery } from "../redux/profile.api";
const useProfileImageBase = (profileId) => {
  const { data, isLoading } = useGetImageQuery(profileId);
  const [imageBase, setImageBase] = useState();
  useEffect(() => {
    if (!isLoading && data) {
      const obj = JSON.parse(data);
      setImageBase(obj.src); 
    }
  }, [isLoading]);
  return { imageBase };
};
export { useProfileImageBase };
