import { useDispatch } from "react-redux";
import { useGetConfigQuery } from "../redux/config.api";
import { updateImage } from "../redux/auth.slice";
const useProfile = (typeId) => {
  const dispatch = useDispatch();
  const { data, isLoading } = useGetConfigQuery(typeId);
  const updateProfileImage = (image) => {
    dispatch(updateImage(image));
  };
  return { data, isLoading, updateProfileImage };
};

export { useProfile };
