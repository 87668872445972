import { configureStore } from "@reduxjs/toolkit";

import authenticationReducer from "./auth.slice";
import configsReducer from "./config.slice";
// import jobsReducer from "./job.slice";
import profilesReducer from "./profile.slice";
import newsReducer from "./news.slice";
import eventReducer from "./events.slice";
import { configApi } from "./config.api";
import { jobsApi } from "./jobs.api";
import { companiesApi } from "./company.api";
import { churchesApi } from "./churches.api";
import { profilesApi } from "./profile.api";
import { prayerApi } from "./prayer.api";
import { matrimonyApi } from "./matrimony.api";
import { wishListApi } from "./wishlist.api";
import { jobApplicationsApi } from "./job-application.api";
import companyFormWizard from "./company.wizard.slice";
import churchFormWizard from "./church.wizard.slice";
import profileFormWizard from "./profile.wizard.slice";
import jobProfileWizard from "./job-profile.wizard.slice";
import chatSlice from "./chat.slice";
import { subscriptionApi } from "./subscription.api";
import { dashboardApi } from "./dashboard.api";
import { usersApi } from "./user.api";
import { churchMappingApi } from "./church-mapping.api";
import { profileMappingApi } from "./profile-mapping.api";
import { churchServicesApi } from "./church-service.api";
import { churchEventsApi } from "./church-events.api";
import { profileCompanyMappingApi } from "./profile-company-mappings.api";
import { jobProfileApi } from "./job-profile.api";
import { matrimonyProfileApi } from "./matrimony-profile.api";
const store = configureStore({
  reducer: {
    auth: authenticationReducer,
    configs: configsReducer,
    // jobs: jobsReducer,
    profiles: profilesReducer,
    news: newsReducer,
    events: eventReducer,
    companyWizard: companyFormWizard,
    churchWizard: churchFormWizard,
    profileWizard: profileFormWizard,
    jobProfileWizard: jobProfileWizard,
    chat: chatSlice,
    [configApi.reducerPath]: configApi.reducer,
    [jobsApi.reducerPath]: jobsApi.reducer,
    [companiesApi.reducerPath]: companiesApi.reducer,
    [profilesApi.reducerPath]: profilesApi.reducer,
    [prayerApi.reducerPath]: prayerApi.reducer,
    [churchesApi.reducerPath]: churchesApi.reducer,
    [matrimonyApi.reducerPath]: matrimonyApi.reducer,
    [wishListApi.reducerPath]: wishListApi.reducer,
    [jobApplicationsApi.reducerPath]: jobApplicationsApi.reducer,
    [subscriptionApi.reducerPath]: subscriptionApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [churchMappingApi.reducerPath]: churchMappingApi.reducer,
    [profileMappingApi.reducerPath]: profileMappingApi.reducer,
    [churchServicesApi.reducerPath]: churchServicesApi.reducer,
    [churchEventsApi.reducerPath]: churchEventsApi.reducer,
    [dashboardApi.reducerPath]: dashboardApi.reducer,
    [profileCompanyMappingApi.reducerPath]: profileCompanyMappingApi.reducer,
    [jobProfileApi.reducerPath]: jobProfileApi.reducer,
    [matrimonyProfileApi.reducerPath]: matrimonyProfileApi.reducer,
  },
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat(
      companiesApi.middleware,
      configApi.middleware,
      profilesApi.middleware,
      jobsApi.middleware,
      prayerApi.middleware,
      churchesApi.middleware,
      matrimonyApi.middleware,
      matrimonyProfileApi.middleware,
      wishListApi.middleware,
      jobApplicationsApi.middleware,
      subscriptionApi.middleware,
      usersApi.middleware,
      churchMappingApi.middleware,
      churchServicesApi.middleware,
      churchEventsApi.middleware,
      profileMappingApi.middleware,
      dashboardApi.middleware,
      profileCompanyMappingApi.middleware,
      jobProfileApi.middleware
    ),
});

export { store };
