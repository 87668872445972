import { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { Link, useSearchParams } from "react-router-dom";
import { AgeComponent } from "../components/age.component";
import { ChurchRoleComponent } from "../components/church-role.component";
import { useGetChurchMembersQuery } from "../redux/churches.api";

const Members = ({ churchId }) => {
  const [filter, setFilter] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const { data, isLoading, error, isError } = useGetChurchMembersQuery({
    churchId,
    role: searchParams.get("f"),
  });
  const updateFilter = (filterName) => {
    setSearchParams({ f: filterName });
    setFilter({ ...filter, name: filterName });
  };
  const filterTypes = [
    { name: "Pastor" },
    { name: "Evangelist" },
    { name: "Gospel Musicians" },
    { name: "Missionary" },
    { name: "Worship Leader" },
    { name: "Student" },
  ];
  return (
    <div className="card">
      <div className="card-body">
        <div className="d-flex justify-content-between">
          <h4>Members</h4>
          <Dropdown>
            <Dropdown.Toggle variant="primary" id="dropdown-basic">
              {filter != null ? filter.name : "Type"}
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {filterTypes.map((item) => (
                <Dropdown.Item onClick={() => updateFilter(item.name)}>
                  {item.name}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <hr />
        <table className="table">
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data.map((item, index) => (
                <tr key={`item-${item.id}`}>
                  <td>{index + 1}</td>
                  <td>
                    <Link to={`${item.profileId}`}>
                      {`${item.title} ${item.firstName} ${item.lastName} `}
                    </Link>
                    <ChurchRoleComponent profileId={item.profileId} />
                  </td>
                  <td>
                    <AgeComponent dob={item.dob} />/
                    {item.genderId == 1 ? "M" : "F"}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export { Members };
