import { Header, SliderComponent } from "../components";

import { GetStarted } from "./get-started.component";
import { RecentBlogs } from "./blogs.component";
import { Testimony } from "./testimony.component";
import { Leadership } from "./leadership.component";
import { CaseStudies } from "./case-studies.component";
import { About } from "./about.component";
import { Services } from "./services.component";

export const HomeComponent = () => {
  return (
    <>
      <SliderComponent />
      <About />
      <Services />
      <CaseStudies />
      <Leadership />
      <Testimony />
      <RecentBlogs />
      <GetStarted />
    </>
  );
};
