import React, { useState, useCallback, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { useDispatch, useSelector } from "react-redux";
import { ResponseForm } from "./response.form";
import { ProfileImage } from "../components/profile-image.component";
import { useGetProfileQuery } from "../redux/profile.api";
import * as Icons from "react-bootstrap-icons";
import { useAuthentication } from "../hooks/auth.hook";
import moment from "moment";
import { sendMessage as sendChat } from "../redux/chat.slice";
const Conversation = () => {
  const { userid } = useParams();
  const { user } = useAuthentication();

  //Public API that will echo messages sent to it back to the client
  const [socketUrl, setSocketUrl] = useState("ws://103.35.142.25:6530/ws");
  const [messageHistory, setMessageHistory] = useState([]);
  const [active, setActive] = useState(Math.floor(Math.random()));

  const { sendMessage, lastMessage, readyState } = useWebSocket(socketUrl, {
    onOpen: () => {},
    shouldReconnect: (closeEvent) => true,
  });
  // get the receipient data;
  const { data, isLoading } = useGetProfileQuery(userid);

  const dispatch = useDispatch();
  const ref = useRef();

  const chats = useSelector((state) =>
    state.chat.items?.filter(
      (f) =>
        (f.sender.Id == user.Id && f.receiverId == userid) ||
        (f.sender.Id == userid && f.receiverId == user.Id)
    )
  );

  useEffect(() => {
    if (lastMessage !== null) {
      setMessageHistory((prev) => prev.concat(lastMessage));
      const { sender, receiverId } = JSON.parse(lastMessage.data);
      // if (receiverId == user.Id || sender.Id == user.Id) {
      dispatch(sendChat(JSON.parse(lastMessage.data)));
      // }
    }
  }, [lastMessage, setMessageHistory]);

  const handleClickChangeSocketUrl = useCallback(
    () => setSocketUrl("ws://103.35.142.25:6530/ws"),
    []
  );

  const handleClickSendMessage = useCallback((message) => {
    sendMessage(JSON.stringify(message));
  }, []);

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState];

  return (
    <div>
      <div className="collapse">
        <button
          onClick={handleClickChangeSocketUrl}
          className="btn btn-primary"
        >
          Click Me to change Socket Url
        </button>
        <button
          className="btn btn-primary"
          onClick={handleClickSendMessage}
          disabled={readyState !== ReadyState.OPEN}
        >
          Click Me to send 'Hello'
        </button>
      </div>
      <div
        className="d-flex header"
        style={{ borderBottom: "solid 1px #ccc", paddingBottom: 10 }}
      >
        {!isLoading && <ProfileImage user={data} />}
        <div className="ms-3 w-100">
          <div className="d-flex  justify-content-between w-100">
            <div className="h6 p-0 m-0">
              {data && `${data.firstName} ${data.lastName} ${user.Id}`}
            </div>
          </div>
          <span style={{ fontSize: 12 }}>
            {active ? "online" : "offline"} {connectionStatus}
            <Icons.ArrowRepeat onClick={handleClickChangeSocketUrl} />
          </span>
        </div>
      </div>
      <div
        className="content"
        ref={ref}
        style={{ height: "65vh", overflow: "auto" }}
      >
        <>
          {chats.map((item, index) => (
            <div className="my-3" key={`item-${index}`}>
              <div className="d-flex">
                <ProfileImage user={item.sender} className="img-sm" />
                <div className="ms-3">
                  <div className="d-flex align-items-center">
                    {item.sender.firstName} {item.sender.lastName}
                    <Icons.Dot />
                    <span style={{ fontSize: 12 }}>
                      {moment(item.message.createdDate).format("hh:mm A")}
                    </span>
                  </div>
                  <div className="">{item.message}</div>
                </div>
              </div>
            </div>
          ))}
          <div className="collapse">
            {messageHistory
              .filter((f) => {
                const { sender, receiverId } = JSON.parse(f.data);
                return receiverId == user.Id || sender.Id == user.Id;
              })
              .map((item, index) => {
                const { sender, message, receiverId } = JSON.parse(item.data);
                return (
                  <div className="my-3" key={`item-${index}`}>
                    <div className="d-flex">
                      <ProfileImage user={sender} className="img-sm" />
                      <div className="ms-3">
                        <div className="d-flex align-items-center">
                          {sender.firstName} {sender.lastName}
                          <Icons.Dot />
                          <span style={{ fontSize: 12 }}>
                            {moment(message.createdDate).format("hh:mm A")}
                          </span>
                        </div>
                        <div className="">{message}</div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </>
      </div>
      <div className="form mt-3">
        <ResponseForm
          onFormSubmit={(e) => {
            const messageObj = {
              sender: { ...user },
              receiverId: userid,
              message: e.response,
              createdDate: new Date(),
            };
            handleClickSendMessage(messageObj);
            // dispatch(sendMessage(e));
            // ref.current.scrollTop = ref.current?.scrollHeight;
          }}
        />
      </div>
    </div>
  );
};
export { Conversation };
