import StepOne from "./step-one";
import StepThree from "./step-three";
import StepTwo from "./step-two";
import StepFour from "./step-four";
import { Summary } from "./summary";

const ActiveStep = ({ currentStep, handleStepSubmit, stepSubmitRef }) => {
  const steps = [
    {
      isActive: currentStep == 1,
      component: (
        <StepOne
          ref={stepSubmitRef[currentStep - 1]}
          onStepSubmit={handleStepSubmit}
        />
      ),
    },
    {
      isActive: currentStep == 2,
      component: (
        <StepTwo
          ref={stepSubmitRef[currentStep - 1]}
          onStepSubmit={handleStepSubmit}
        />
      ),
    },
    {
      isActive: currentStep == 3,
      component: (
        <StepThree
          ref={stepSubmitRef[currentStep - 1]}
          onStepSubmit={handleStepSubmit}
        />
      ),
    },
    {
      isActive: currentStep == 4,
      component: (
        <StepFour
          ref={stepSubmitRef[currentStep - 1]}
          onStepSubmit={handleStepSubmit}
        />
      ),
    }, {
      isActive: currentStep == 5,
      component: (
        <Summary
          ref={stepSubmitRef[currentStep - 1]}
          onStepSubmit={handleStepSubmit}
        />
      ),
    },
  ];

  const activeStep = steps.find((f) => f.isActive == true);
  return <>{activeStep.component}</>;
};

export { ActiveStep };
