import moment from "moment";
import { Link } from "react-router-dom";
import { Pencil, Trash, Calendar2 } from "react-bootstrap-icons";
import { useGetChurchEventsQuery } from "../../redux/church-events.api";

const EventsList = ({ churchId, options }) => {
  const { data, isLoading } = useGetChurchEventsQuery(churchId);

  return (
    <div className="card">
      <div className="card-header">
        <div className="d-flex justify-content-between">
          <h4>Events</h4>
          <Link className="btn btn-primary" to={"create"}>
            ADD
          </Link>
        </div>
      </div>
      <div className="card-body">
        <>
          {isLoading ? (
            <>loading...</>
          ) : (
            <table className="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Title</th>
                  <th>Date</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.map((item, index) => (
                    <tr key={`event-${item.Id}`}>
                      <td>{index + 1}.</td>
                      <td>{item.description}</td>
                      <td>{moment(item.eventDate).format("D/M/yyyy")}</td>

                      <td>
                        <Link to={`edit/${item.Id}`}>
                          <Pencil color="blue" />
                        </Link>
                        <Trash color="red" className="mx-3" />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          )}
        </>
      </div>
    </div>
  );
};

export { EventsList };
