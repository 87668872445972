import * as Icons from "react-bootstrap-icons";
import {
  useGetAllQuery,
  useAddWishMutation,
  useRemoveWishMutation,
} from "../redux/wishlist.api";

const WishComponent = ({ profileId, jobId }) => {
  const { data, isSuccess, isLoading, refetch } = useGetAllQuery({ profileId });
  const [addWish, postQuery] = useAddWishMutation();
  const [removeWish, delQuery] = useRemoveWishMutation();

  const isAddedtoList = () => {
    if (data) {
      if (data.length == 0) return false;

      const __isAdded = data.find((f) => f.jobId == jobId);
      return __isAdded != null;
    }
  };

  const handleAddtoList = () => {
    addWish({ profileId, jobId })
      .unwrap()
      .then((re) => {
        alert("added to wish list");
      })
      .catch((e) => {
        alert(JSON.stringify(e));
      });
  };

  const handleRemoveFromList = () => {
    removeWish({ profileId, jobId })
      .unwrap()
      .then((re) => {
        alert("removed from wish list");
      })
      .catch((e) => {
        alert(JSON.stringify(e));
      });
  };

  return (
    <div>
      {isLoading ? (
        <>...</>
      ) : !isLoading && isSuccess && isAddedtoList() ? (
        <Icons.HeartFill onClick={handleRemoveFromList} color={"#fc004b"} />
      ) : (
        <Icons.Heart onClick={handleAddtoList} color={"#fc004b"} />
      )}
    </div>
  );
};
export { WishComponent };
