import { useSearchParams } from "react-router-dom";

const RowIndex = ({ index }) => {
  const calcrowIndex = () =>
    (searchParams.get("page") - 1) * searchParams.get("rows");

  const [searchParams, setSearchParams] = useSearchParams();
  return <span>{calcrowIndex() + index + 1}.</span>;
};
export { RowIndex };
