import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import {
  Link,
  Routes,
  Route,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useSelector } from "react-redux";
import {
  useGetAllProfilesQuery,
  useDeleteProfileMutation,
  useGetProfilesCountQuery,
} from "../redux/profile.api";
import { Pencil, Trash } from "react-bootstrap-icons";
import { ProfileDetails } from "../profile/profile.details";
import { Paging } from "../components/paging.component";
import { AgeComponent } from "../components/age.component";
import { RowIndex } from "../components/rowIndex.component";
import { ModalComponent } from "../components/modal.component";

const Profiles = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { items: configs, errors } = useSelector((state) => state.configs);
  const countQuery = useGetProfilesCountQuery({});
  const {
    data: profiles,
    isSuccess,
    isLoading,
    refetch,
  } = useGetAllProfilesQuery({
    rows: searchParams.get("rows"),
    page: searchParams.get("page"),
  });
  const [deleteProfile, query] = useDeleteProfileMutation();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [selectedItem, setselectedItem] = useState();

  const handeModalConfirm = () => {
    deleteProfile({ id: selectedItem.Id })
      .unwrap()
      .then((res) => {
        // alert(JSON.stringify(res));
        setShow(false);
      })
      .catch((e) => alert(e));
  };
  const handleModalClose = () => {
    setShow(false);
  };
  return (
    <>
      <div className="card">
        <div className="card-header">
          <div className="d-flex justify-content-between align-items-center">
            <h5>Members</h5>
            <button className="btn btn-sm btn-primary" onClick={refetch}>
              refresh
            </button>
          </div>
        </div>
        <div className="card-body">
          <table className="table">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Gender</th>
                <th>DOB</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {profiles?.map((item, index) => (
                <tr key={item.Id}>
                  <td>
                    <RowIndex index={index} />
                  </td>
                  <td>
                    <Link
                      to={`${item.Id}`}
                    >{`${item.firstName}, ${item.lastName}`}</Link>
                  </td>
                  <td>
                    {configs.find((f) => f.id == item.genderId)?.configValue}
                  </td>
                  <td>
                    <AgeComponent dob={item.dob} />
                  </td>
                  <td>
                    {/* <Pencil
                      className="me-4"
                      style={{ cursor: "pointer" }}
                      onClick={() => navigate(`edit/${item.Id}`)}
                    /> */}
                    <Trash
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setselectedItem(item);
                        setShow(true);
                        //navigate(`delete/${item.Id}`);
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {!countQuery.isLoading && countQuery.data && (
            <Paging
              total={countQuery.data}
              pageSize={searchParams.get("rows") ?? 10}
            />
          )}
        </div>
      </div>
      {selectedItem && (
        <ModalComponent
          showDialog={show}
          title={"DELETE"}
          message={`${selectedItem.title} ${selectedItem.firstName} ${selectedItem.lastName}`}
          onCancelClick={handleModalClose}
          onOkClick={handeModalConfirm}
        />
      )}
    </>
  );
};

const DeleteProfile = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [deleteProfile, query] = useDeleteProfileMutation();

  const handleDelete = () => {
    deleteProfile({ id })
      .unwrap()
      .then((res) => navigate("../"))
      .catch((e) => alert(e));
  };

  return (
    <div className="card">
      <div className="card-body">
        Are you Sure to delete this member? {id}
        <div>
          <button className="btn btn-danger" onClick={handleDelete}>
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

const ProfileRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Profiles />} />
        <Route path="/delete/:id" element={<DeleteProfile />} />
        <Route path="/:id" element={<ProfileDetails />} />
      </Routes>
    </>
  );
};
export { Profiles, ProfileRoutes };
