import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiBaseUrl } from "../_constants";

export const churchesApi = createApi({
  reducerPath: "churchesApi",
  baseQuery: fetchBaseQuery({ baseUrl: apiBaseUrl }),
  tagTypes: ["list"],
  endpoints: (builder) => ({
    getAllChurches: builder.query({
      query: ({ page=1, rows=10 }) => `/churches/index.php?page=${page}&rows=${rows}`,
      providesTags: ["list"],
    }),
    getChurch: builder.query({
      query: (id) => `/churches/details.php?id=${id}`,
    }),
    getChurchTitleImage: builder.query({
      query: (id) => `/churches/profileImage.php?id=${id}`,
    }),
    getChurchMembers: builder.query({
      query: ({ role, churchId }) =>
        `/churches/members.php?id=${churchId}&role${role}`,
    }),
    getChurchServices: builder.query({
      query: (id) => `/churches/services.php?id=${id}`,
    }),
    getChurchOfMembers: builder.query({
      query: (id) => `/churches/memberChurch.php?profileId=${id}`,
    }),

    postChurch: builder.mutation({
      query: (entity) => ({
        url: `/churches/index.php`,
        method: "POST",
        body: entity,
      }),
      invalidatesTags: ["list"],
    }),

    deleteChurch: builder.mutation({
      query: (id) => ({
        url: `/churches/index.php`,
        method: "DELETE",
        body: { id },
      }),
      invalidatesTags: ["list"],
    }),

    getCount: builder.query({ query: () => `/churches/count.php` }),
  }),
});

export const {
  useGetAllChurchesQuery,
  useGetChurchQuery,
  useGetChurchOfMembersQuery,
  useGetChurchTitleImageQuery,
  usePostChurchMutation,
  useDeleteChurchMutation,
  useGetCountQuery,
  useGetChurchMembersQuery,
  useGetChurchServicesQuery,
} = churchesApi;
