import { Outlet, Routes, Route } from "react-router-dom";
import { AboutMatrimony } from "./about";
import { MatrimonyMaster } from "./matrimony.master";
import { ProfileDetails } from "./profile.details";


const Matrimony = () => {
  return (
    <div>
      <Routes>
        <Route exact path="/" element={<AboutMatrimony />} />
        <Route exact path="/profiles/*" element={<MatrimonyMaster />} />
        <Route exact path="/profiles/:id" element={<ProfileDetails />} />
      </Routes>
      <Outlet />
    </div>
  );
};

export { Matrimony };
export * from "./matrimony.master";
export * from "./profile.details";
export * from "./about";