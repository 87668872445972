import { Routes, Route, Outlet } from "react-router-dom";
import { ChurchMaster } from "./church.master";

export const Churches = () => {
  return (
    <div className="ftco-section">
      <div className="container">
        <Routes>
          <Route exact path="/" element={<ChurchMaster />} />
        </Routes>
        <Outlet />
      </div>
    </div>
  );
};

export * from "./church.form";
export * from "./church.details";
