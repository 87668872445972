import { useGetStatsQuery } from "../redux/dashboard.api";
import {
  People,
  Buildings,
  Person,
  Heart,
  Award,
  BookmarkCheck,
  Bank2,
} from "react-bootstrap-icons";

const TileComponent = ({ isLoading, count, title, color, icon, children }) => (
  <div
    className="card m-2"
    style={{ borderColor: color, borderWidth: 1, width:"14rem" }}
  >
    <div className="row p-2">
      <div className="col d-flex align-items-center">
        <div className="">
          {children}
          <p style={{ color: color }}>{title}</p>
        </div>
      </div>
      <div className="col">
        <p style={{ fontSize: "60px", color: color }}>
          {isLoading ? <>...</> : <>{count}</>}
        </p>
      </div>
    </div>
  </div>
);

const Dashboard = () => {
  const { data, isLoading } = useGetStatsQuery();

  return (
    <div className="row">
      <TileComponent
        isLoading={isLoading}
        count={data?.profileCount}
        title="Profiles"
        color="red"
      >
        <People color="red" size={60} />
      </TileComponent>
      <TileComponent
        isLoading={isLoading}
        count={data?.userCount}
        title="Users"
        color="blue"
      >
        <Person color="blue" size={60} />
      </TileComponent>
      <TileComponent
        isLoading={isLoading}
        count={data?.churchCount}
        title="Church"
        color="green"
      >
        <Bank2 size={60} color={"green"} />
      </TileComponent>
      <TileComponent
        isLoading={isLoading}
        count={data?.companyCount}
        title="Company"
        color="#f05"
      >
        <Buildings color="#f05" size={60} />
      </TileComponent>
      <TileComponent
        isLoading={isLoading}
        count={data?.matrimonyCount}
        title="Matimony"
        color="maroon"
      >
        <Heart color="maroon" size={60} />
      </TileComponent>
      <TileComponent
        isLoading={isLoading}
        count={data?.jobsCount}
        title="Jobs"
        color="#336699"
      >
        <BookmarkCheck size={60} color={"#336699"} />
      </TileComponent>
      <TileComponent
        isLoading={isLoading}
        count={data?.jobsAppliedCount}
        title="Jobs Applied"
        color="#336666"
      >
        <Award size={60} color={"#336666"} />
      </TileComponent>
    </div>
  );
};
export { Dashboard };
