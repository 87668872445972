import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorMessage } from "@hookform/error-message";
import {
  useGetProfileQuery,
  usePostProfileMutation,
  usePutProfileMutation,
} from "../redux/profile.api";
import moment from "moment";
import { useGetAllChurchesQuery } from "../redux/churches.api";
import { useSearchConfigQuery } from "../redux/config.api";

export const ProfileForm = (props) => {
  const { id } = useParams();
  const isAddMode = !id;
  const churchesQuery = useGetAllChurchesQuery();
  const { data, isLoading, isSuccess, isError, error } = useGetProfileQuery(
    id,
    { skip: isAddMode }
  );
  const configQuery = useSearchConfigQuery({
    name: "PROFILE_TYPE",
    value: "MEMBER",
  });
  const [postProfile, postQuery] = usePostProfileMutation();
  const [putProfile, putQuery] = usePutProfileMutation();

  const navigate = useNavigate();
  const validationSchema = yup.object().shape({
    firstName: yup.string().required("first name is required"),
    lastName: yup.string().required("last name is required"),
    dob: yup
      .date()
      .required("Date of birth is required")
      .typeError("Enter a valid Date"),
    genderId: yup
      .number()
      .required("Gender is required")
      .typeError("Select a valid gender"),
    mobile: yup
      .number()
      .required("Mobile number is required")
      .typeError("Enter a valid number"),
    email: yup.string().required("Email Id is required"),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) });

  function __postForm(data) {
    return postProfile(data)
      .unwrap()
      .then((res) => true)
      .catch((e) => false);
  }

  function __putForm(data) {
    return putProfile(data)
      .unwrap()
      .then((res) => true)
      .catch((e) => false);
  }

  const onSubmit = async (data) => {
    const { Id: profileTypeId } = configQuery.data[0];

    const __data = {
      ...data,
      dob: moment(data.dob).format("yyyy-MM-D"),
      userTypeId: profileTypeId,
    };
    const result = isAddMode
      ? await __postForm(__data)
      : await __putForm(__data);

    if (result) {
      navigate("/profiles");
    } else {
      alert("Error saving data... try later");
    }
  };

  const Form = (
    <form onSubmit={handleSubmit(onSubmit)}>
      {id && <input type="hidden" {...register("Id")}></input>}
      <div className="row mb-3">
        <label className="form-label col-md-2">First Name</label>
        <div className="col-md-10 d-flex">
          <div className="col-md-2">
            <select className="form-control col" {...register("title")}>
              <option value={"Mr."}>Mr.</option>
              <option value={"Mrs."}>Mrs.</option>
              <option value={"Ms."}>Ms.</option>
              <option value={"Master."}>Master.</option>
            </select>
          </div>
          <div className="col-md-10">
            <input
              type="text"
              className={`form-control ${errors.firstName && "is-invalid"} `}
              placeholder="first name"
              {...register("firstName")}
            />
            <ErrorMessage
              errors={errors}
              name="firstName"
              render={({ message }) => (
                <p className="invalid-feedback">{message}</p>
              )}
            />
          </div>
        </div>
      </div>
      <div className="row mb-3">
        <label className="form-label col-md-2">Last Name</label>
        <div className="col-md-10">
          <input
            type="text"
            className={`form-control ${errors.lastName && "is-invalid"} `}
            placeholder="last name"
            {...register("lastName")}
          />
          <ErrorMessage
            errors={errors}
            name="lastName"
            render={({ message }) => (
              <p className="invalid-feedback">{message}</p>
            )}
          />
        </div>
      </div>
      <div className="row mb-3">
        <label className="form-label col-md-2">Gender</label>
        <div className="col-md-10">
          <select
            type="text"
            className={`form-control ${errors.genderId && "is-invalid"} `}
            placeholder="gender"
            {...register("genderId")}
          >
            <option>select</option>
            <option value="1">Male</option>
            <option value="2">FeMale</option>
          </select>{" "}
          <ErrorMessage
            errors={errors}
            name="genderId"
            render={({ message }) => (
              <p className="invalid-feedback">{message}</p>
            )}
          />
        </div>
      </div>
      <div className="row mb-3">
        <label className="form-label col-md-2">DOB</label>
        <div className="col-md-10">
          <input
            type="date"
            className={`form-control ${errors.dob && "is-invalid"} `}
            {...register("dob")}
          ></input>
          <ErrorMessage
            errors={errors}
            name="dob"
            render={({ message }) => (
              <p className="invalid-feedback">{message}</p>
            )}
          />
        </div>
      </div>
      <div className="row mb-3">
        <label className="form-label col-md-2">Mobile</label>
        <div className="col-md-10">
          <input
            type="text"
            className={`form-control ${errors.mobile && "is-invalid"} `}
            placeholder="mobile"
            {...register("mobile")}
          />
          <ErrorMessage
            errors={errors}
            name="mobile"
            render={({ message }) => (
              <p className="invalid-feedback">{message}</p>
            )}
          />
        </div>
      </div>
      <div className="row mb-3">
        <label className="form-label col-md-2">Email</label>
        <div className="col-md-10">
          <input
            type="text"
            className={`form-control ${errors.email && "is-invalid"} `}
            placeholder="enter a valid email address"
            {...register("email")}
          />
          <ErrorMessage
            errors={errors}
            name="email"
            render={({ message }) => (
              <p className="invalid-feedback">{message}</p>
            )}
          />
        </div>
      </div>
      <div className="row mb-3 collapse">
        <label className="form-label col-md-2">Church</label>
        <div className="col-md-10">
          <select
            className={`form-control ${errors.churchId && "is-invalid"}`}
            {...register("churchId")}
            disabled={isLoading}
          >
            <option value="-1" key="-1">
              Select a church
            </option>
            {churchesQuery.data &&
              churchesQuery.data.map((item, index) => (
                <option value={item.Id} key={`church-${item.Id}`}>
                  {item.title}
                </option>
              ))}
          </select>
          <ErrorMessage
            errors={errors}
            name="churchId"
            render={({ message }) => (
              <p className="invalid-feedback">{message}</p>
            )}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-10 offset-md-2">
          <button type="submit" className="btn btn-primary me-2">
            SAVE
          </button>
          <Link className="btn btn-secondary" to="/">
            CANCEL
          </Link>
        </div>
      </div>
    </form>
  );
  useEffect(() => {
    if (id && data) {
      const fields = [
        "Id",
        "firstName",
        "lastName",
        "genderId",
        "dob",
        "mobile",
        "email",
      ];
      fields.forEach((field) => setValue(field, data[field]));
    }
  }, [isLoading]);
  return (
    <div className="card">
      <div className="card-body">
        {id != null ? (
          isLoading ? (
            <> fetching data .... </>
          ) : (
            <>{Form}</>
          )
        ) : (
          <>{Form}</>
        )}
      </div>
    </div>
  );
};
