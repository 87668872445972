import { useGetAllUsersQuery } from "../redux/user.api";

const Users = () => {
  const { data, isLoading } = useGetAllUsersQuery();

  return (
    <div>
      <div className="card">
        <div className="card-body">
          {isLoading ? (
            <>loading...</>
          ) : (
            <table className="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr>
                    <td>{index + 1}.</td>
                    <td>{JSON.stringify(item)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export { Users };
