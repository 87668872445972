import {
  useGetChurchMembersQuery,
  useGetChurchQuery,
  useGetChurchServicesQuery,
} from "../redux/churches.api";

import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Tabs, Tab } from "react-bootstrap";
import { AgeComponent } from "../components/age.component";
import { ServiceList } from "./services";
import { EventWidget } from "./events/event.widget";
import { ChurchImageComponent } from "../components";
const AboutPastor = () => {
  return <div>some short info about paster</div>;
};

const ChurchMembers = () => {
  const { id } = useParams();
  const query = useGetChurchMembersQuery({ churchId: id });
  return (
    <div className="card">
      <div className="card-body">
        {query.isLoading ? (
          <div>loading...</div>
        ) : (
          query.data && (
            <table className="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {query.data.map((item, index) => (
                  <tr key={`$member-${item.Id}`}>
                    <td>{index + 1}.</td>
                    <td>{`${item.title} ${item.firstName} ${item.lastName}`}</td>
                    <td className="text-center">
                      <AgeComponent dob={item.dob} />/
                      {item.genderId == 1 ? "M" : "F"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )
        )}
      </div>
    </div>
  );
};

const Services = ({ churchId }) => {
  const query = useGetChurchServicesQuery(churchId);
  return (
    <div className="card">
      <div className="card-body">
        Church services listing {JSON.stringify(query.data)}
      </div>
    </div>
  );
};

const Events = () => {
  const { items } = useSelector((state) => state.events);
  return (
    <div>
      Church shared events data <hr /> {JSON.stringify(items)}
    </div>
  );
};

const ChurchDetails = () => {
  const { id } = useParams();
  const { data, isLoading, error, isError } = useGetChurchQuery(id);

  return (
    <>
      {isLoading ? (
        <>loading...</>
      ) : (
        <>
          {isError ? (
            <div className="alert alert-danger">{JSON.stringify(error)}</div>
          ) : (
            data && (
              <div className="row">
                <div className="col-md-8">
                  <div className="row mb-4">
                    <div className="col-md-4">
                      <ChurchImageComponent churchId={id} />
                    </div>
                    <div className="col-md-8">
                      <h4>{data.title} </h4>
                      <h5>{data.about} </h5>
                    </div>
                  </div>
                  <Tabs defaultActiveKey="about">
                    <Tab title="About" eventKey="about">
                      <AboutPastor />
                    </Tab>
                    <Tab title="Members" eventKey="members">
                      <ChurchMembers />
                    </Tab>
                    <Tab title="Gallery" eventKey="gallery">
                      <div>some gallery stuff</div>
                    </Tab>
                    <Tab title="Testimony" eventKey="testimony">
                      <div>some testimony stuff</div>
                    </Tab>
                  </Tabs>
                </div>
                <div className="col-md-4">
                  <div className="mb-3">
                    <ServiceList churchId={data.Id} options={false} />
                  </div>
                  <div className="mb-3">
                    <EventWidget churchId={data.Id} />
                  </div>
                </div>
              </div>
            )
          )}
        </>
      )}
    </>
  );
};

export { ChurchDetails };
