import * as Icon from "react-bootstrap-icons";
import { NavLink } from "react-router-dom";
import moment from "moment";

import { ProfileImage } from "../components/profile-image.component";
import { ChurchNameComponent } from "../components/church-name.component";

const Item = ({ data }) => {
  const fullName = `${data.title} ${data.firstName} ${data.lastName}`;

  return (
    <div className="card mb-4">
      <div className="d-flex justify-content-center my-4">
        <ProfileImage user={data} style={"small rounded"} />
      </div>
      <div className="card-body">
        <NavLink className="h5 mb-5 text-primary" to={data.Id}>
          {fullName}
        </NavLink>
        <div>
          <Icon.Calendar className="me-2" />
          {moment(data.dob).format("D/MM/yyyy")}
        </div>
        <div>
          <Icon.Phone className="me-2" /> {data.mobile}
        </div>
        <div>
          <Icon.Envelope className="me-2" /> <small> {data.email} </small>
        </div>
      </div>
    </div>
  );
};

const GridDisplay = ({ data }) => {
  return (
    <div className="row">
      {data &&
        data.map((item) => (
          <div className="col-md-4 col-lg-3">
            <Item data={item} key={`profile-${item.id}`} />
          </div>
        ))}
    </div>
  );
};

export { GridDisplay };
