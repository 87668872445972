import { Tab, Tabs } from "react-bootstrap";
import { Calendar2 } from "react-bootstrap-icons";
import {
  useGetMatrimonyByProfileQuery,
  usePostMappingMutation,
} from "../../redux/matrimony-profile.api";
import { ProfileRequest } from "./profile-request";

const MatrimonyProfile = ({ user }) => {
  const { data, error, isLoading } = useGetMatrimonyByProfileQuery(user.Id);
  const [postMapping, postQuery] = usePostMappingMutation();

  const handleClick = () => {
    postMapping({ profileId: user.Id })
      .unwrap()
      .then((res) => alert("Profile enabled successfully"))
      .catch((e) => alert(JSON.stringify(e)));
  };
  return (
    <div className="card">
      <div className="card-body">
        <div>
          {error && error.status == 404 && (
            <>
              Matrimony profile is not enabled
              <div>
                <button className="btn btn-primary" onClick={handleClick}>
                  Enable
                </button>
              </div>
            </>
          )}
        </div>
        {data && (
          <>
            <div className="mb-4">
              <Calendar2 className="me-2" /> {JSON.stringify(data.createdDate)}
            </div>
            <Tabs defaultActiveKey={"requests"}>
              <Tab eventKey={"requests"} title="Request">
                <ProfileRequest user={user} />
              </Tab>
              <Tab eventKey={"accepted"} title="Accepted"></Tab>
            </Tabs>
          </>
        )}
      </div>
    </div>
  );
};
export { MatrimonyProfile };
