import { Link } from "react-router-dom";
import { useCountQuery, useGetAllQuery } from "../redux/prayer.api";

const PrayerRequests = (props) => {
  const { data, isLoading } = useGetAllQuery();
  const dataCount = useCountQuery();
  return (
    <>
      <div className="ftco-section">
        <div className="container">
          <div className="card">
            <div className="card-body">
              {dataCount.isLoading && <>loading...</>}
              {/* <h4>{dataCount.data}</h4> */}

              <table className="table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>About</th>
                    <th>Email</th>
                    <th>Mobile</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {!isLoading &&
                    data &&
                    data.map((item, index) => (
                      <tr>
                        <td>{index + 1}.</td>
                        <td>{item.name}</td>
                        <td>{item.aboutBeliever}</td>
                        <td>{item.email}</td>
                        <td>{item.mobile}</td>
                        <td>
                          <span class="badge rounded-pill text-bg-danger">
                            Primary
                          </span>
                        </td>
                        <td>
                          <Link className="btn btn-primary btn-sm me-2" to={item.Id}>
                            View
                          </Link>
                          <button className="btn btn-danger btn-sm me-2">
                            Del
                          </button>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { PrayerRequests };
export * from './prayer-request.details';