import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

import { apiBaseUrl } from "../_constants/index";

export const validateLogin = createAsyncThunk(
  "authentication/validate",
  async (params, thunkApi) => {
    try {
      const response = await axios.post(
        `${apiBaseUrl}/accounts/validate.php`,
        params
      );
      return response.data;
    } catch (err) {
      throw thunkApi.rejectWithValue(err.response);
    }
  }
);

const initialState = { user: JSON.parse(localStorage.getItem("auth") || null) };

export const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    getAll: (state, { payload }) => {
      state.items = payload;
    },
    getUser: (state) => {
      state.user = JSON.parse(localStorage.getItem("auth") || null);
    },
    setUser: (state, { payload }) => {
      localStorage.setItem("auth", JSON.stringify(payload));
      state.user = payload;
    },
    updateImage: (state, { action, payload }) => {
      state.user.profileImage = payload;
      localStorage.setItem("auth", JSON.stringify(state.user));
    },
    logout: (state) => {
      localStorage.removeItem("auth");
      state.error = null;
      state.user = null;
    },
  },
  extraReducers: {
    [validateLogin.pending]: (state) => {
      state.isLoading = true;
    },
    [validateLogin.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.user = payload;
      localStorage.setItem("auth", JSON.stringify(payload));
      state.error = null;
    },
    [validateLogin.rejected]: (state, { error, meta, payload }) => {
      state.isLoading = false;
      state.error = {
        status: `${payload.status}`,
        statusText: `${payload.statusText}`,
      };
    },
  },
});

export const { updateImage, getAll, getUser, setUser, logout } =
  authenticationSlice.actions;
export default authenticationSlice.reducer;
