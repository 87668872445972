import { useGetAllQuery } from "../redux/job-application.api";
import moment from "moment";
const JobItem = ({ job, index }) => (
  <div>
    <h5> {`# ${index}. ${job.title} `}</h5>
    <p>{job.company}</p>
    <p>
      {`${moment(job.postedDate).format("D/MM/yyyy")} =>  ${moment(
        job.appliedDate
      ).format("D/MM/yyyy")}`}{" "}
      {job.jobStatusId}
    </p>
    <p>{JSON.stringify(job)}</p>
  </div>
);
const JobsApplied = ({ user }) => {
  const { data } = useGetAllQuery({ profileId: user.Id });

  return (
    <div className="card mb-3">
      <div className="card-header">Applied</div>
      <div className="card-body">
        {data &&
          data.map((item, index) => (
            <JobItem job={item} index={index + 1} key={`applied-${item.Id}`} />
          ))}
      </div>
    </div>
  );
};

export { JobsApplied };
