import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiBaseUrl } from "../_constants/index";
import * as helpers from "../helpers";

export const jobApplicationsApi = createApi({
  reducerPath: "jobApplicationsApi",
  baseQuery: fetchBaseQuery({ baseUrl: apiBaseUrl }),
  tagTypes: [],
  endpoints: (builder) => ({
    getAll: builder.query({
      query: (params) => `/jobs/application.php${helpers.buildString(params)}`,
    }),
    getAppliedJobs: builder.query({
      query: (profileId) => `/jobs/applied-jobs.php?profileId=${profileId}`,
    }),
    getApplicationsForJob: builder.query({
      query: (jobId) => `/jobs/applied-jobs.php?jobId=${jobId}`,
    }),
    applyJob: builder.mutation({
      query: (obj) => ({
        url: `jobs/application.php`,
        method: "POST",
        body: obj,
      }),
      invalidatesTags: ["Post"],
    }),
    isApplied: builder.query({
      query: (params) => ({
        url: `jobs/isapplied.php${helpers.buildString(params)}`,
      }),
      providesTags: ["Post"],
    }),
  }),
});

export const {
  useGetAllQuery,
  useApplyJobMutation,
  useIsAppliedQuery,
  useGetAppliedJobsQuery,
  useGetApplicationsForJobQuery,
} = jobApplicationsApi;
