export const Services = () => (
  <section className="ftco-section bg-light">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-lg-5 heading-section text-center mb-5">
          <span className="subheading">Services</span>
          <h2 className="mb-4">Our Exclusive Services We Offer For You</h2>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-3 text-center d-flex align-items-stretch">
          <div className="flow-wrap flow-wrap-right">
            <div className="icon">
              <span className="flaticon-accounting"></span>
            </div>
            <div className="text">
              <h2>Financial Planning</h2>
              <p className="mb-4">
                Far far away, behind the word mountains, far from the countries
                Vokalia.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-3 text-center d-flex align-items-stretch">
          <div className="flow-wrap">
            <div className="icon">
              <span className="flaticon-financial"></span>
            </div>
            <div className="text">
              <h2>Investments Management</h2>
              <p className="mb-4">
                Far far away, behind the word mountains, far from the countries
                Vokalia.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-3 text-center d-flex align-items-stretch">
          <div className="flow-wrap">
            <div className="icon">
              <span className="flaticon-recession"></span>
            </div>
            <div className="text">
              <h2>Business Loan</h2>
              <p className="mb-4">
                Far far away, behind the word mountains, far from the countries
                Vokalia.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-3 text-center d-flex align-items-stretch">
          <div className="flow-wrap">
            <div className="icon">
              <span className="flaticon-tax"></span>
            </div>
            <div className="text">
              <h2>Taxes Consulting</h2>
              <p className="mb-4">
                Far far away, behind the word mountains, far from the countries
                Vokalia.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-3 text-center d-flex align-items-stretch">
          <div className="flow-wrap">
            <div className="icon">
              <span className="flaticon-insurance"></span>
            </div>
            <div className="text">
              <h2>Insurance Consulting</h2>
              <p className="mb-4">
                Far far away, behind the word mountains, far from the countries
                Vokalia.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-3 text-center d-flex align-items-stretch">
          <div className="flow-wrap">
            <div className="icon">
              <span className="flaticon-retirement-plan"></span>
            </div>
            <div className="text">
              <h2>Retirement Planning</h2>
              <p className="mb-4">
                Far far away, behind the word mountains, far from the countries
                Vokalia.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-3 text-center d-flex align-items-stretch">
          <div className="flow-wrap">
            <div className="icon">
              <span className="flaticon-risk"></span>
            </div>
            <div className="text">
              <h2>Risk Management</h2>
              <p className="mb-4">
                Far far away, behind the word mountains, far from the countries
                Vokalia.
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-3 text-center d-flex align-items-stretch">
          <div className="flow-wrap">
            <div className="icon">
              <span className="flaticon-technology"></span>
            </div>
            <div className="text">
              <h2>Technology Consulting</h2>
              <p className="mb-4">
                Far far away, behind the word mountains, far from the countries
                Vokalia.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);
