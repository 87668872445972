import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiBaseUrl } from "../_constants";
import * as helpers from "../helpers";
export const jobsApi = createApi({
  reducerPath: "jobsApi",
  baseQuery: fetchBaseQuery({ baseUrl: apiBaseUrl }),
  tagTypes: ["Post"],
  endpoints: (builder) => ({
    getAll: builder.query({
      query: (params) => `jobs/index.php${helpers.buildString(params)}`,
      providesTags: ["Post"],
    }),
    getJob: builder.query({
      query: (id) => `jobs/details.php?id=${id}`,
      providesTags: ["Post"],
    }),
    postJob: builder.mutation({
      query: (obj) => ({
        url: `jobs/index.php`,
        method: "POST",
        body: obj,
      }),
      invalidatesTags: ["Post"],
    }),
    putJob: builder.mutation({
      query: (obj) => ({
        url: `jobs/index.php`,
        method: "PUT",
        body: obj,
      }),
      invalidatesTags: ["Post"],
    }),
    applyJob: builder.mutation({
      query: (obj) => ({
        url: `jobs/application.php`,
        method: "POST",
        body: obj,
      }),
      invalidatesTags: ["Post"],
    }),
    isApplied: builder.query({
      query: (params) => ({
        url: `jobs/isapplied.php${helpers.buildString(params)}`,
      }),
      providesTags: ["Post"],
    }),
    uploadCV: builder.mutation({
      query: (params) => ({
        url: `jobs/upload.php`,
        method: "POST",
        body: params,
      }),
    }),
    getJobsCount: builder.query({
      query: (companyId) => ({
        url: `jobs/index.php?dataType=count&companyId=${companyId}`,
      }),
    }),
  }),
});

export const {
  useGetAllQuery,
  useGetJobsCountQuery,
  useGetJobQuery,
  usePostJobMutation,
  usePutJobMutation,
  useApplyJobMutation,
  useIsAppliedQuery,
  useUploadCVMutation,
} = jobsApi;
