export const Testimony = () => {
  return (
    <section className="ftco-section testimony-section bg-light">
      <div className="container-xl">
        <div className="row justify-content-center pb-4">
          <div className="col-lg-7 text-center heading-section">
            <span className="subheading">Testimonial</span>
            <h2 className="mb-5">Happy Customers</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="carousel-testimony">
              <div className="item">
                <div className="testimony-wrap">
                  <div className="icon d-flex align-items-center justify-content-center">
                    <span className="fa fa-quote-left"></span>
                  </div>
                  <div className="text">
                    <p className="mb-4">
                      Far far away, behind the word mountains, far from the
                      countries Vokalia and Consonantia, there live the blind
                      texts.
                    </p>
                    <div className="d-flex align-items-center">
                      <div
                        className="user-img"
                        style={{
                          backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/image_3.jpg)`,
                        }}
                      ></div>
                      <div className="ps-3">
                        <p className="name">Roger Scott</p>
                        <span className="position">Marketing Manager</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="testimony-wrap">
                  <div className="icon d-flex align-items-center justify-content-center">
                    <span className="fa fa-quote-left"></span>
                  </div>
                  <div className="text">
                    <p className="mb-4">
                      Far far away, behind the word mountains, far from the
                      countries Vokalia and Consonantia, there live the blind
                      texts.
                    </p>
                    <div className="d-flex align-items-center">
                      <div
                        className="user-img"
                        style={{
                          backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/image_3.jpg)`,
                        }}
                      ></div>
                      <div className="ps-3">
                        <p className="name">Roger Scott</p>
                        <span className="position">Marketing Manager</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="testimony-wrap">
                  <div className="icon d-flex align-items-center justify-content-center">
                    <span className="fa fa-quote-left"></span>
                  </div>
                  <div className="text">
                    <p className="mb-4">
                      Far far away, behind the word mountains, far from the
                      countries Vokalia and Consonantia, there live the blind
                      texts.
                    </p>
                    <div className="d-flex align-items-center">
                      <div
                        className="user-img"
                        style={{
                          backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/image_3.jpg)`,
                        }}
                      ></div>
                      <div className="ps-3">
                        <p className="name">Roger Scott</p>
                        <span className="position">Marketing Manager</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="testimony-wrap">
                  <div className="icon d-flex align-items-center justify-content-center">
                    <span className="fa fa-quote-left"> </span>{" "}
                  </div>
                  <div className="text">
                    <p className="mb-4">
                      Far far away, behind the word mountains, far from the
                      countries Vokalia and Consonantia, there live the blind
                      texts.
                    </p>
                    <div className="d-flex align-items-center">
                      <div
                        className="user-img"
                        style={{
                          backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/image_3.jpg)`,
                        }}
                      ></div>
                      <div className="ps-3">
                        <p className="name">Roger Scott</p>
                        <span className="position">Marketing Manager</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="item">
                <div className="testimony-wrap">
                  <div className="icon d-flex align-items-center justify-content-center">
                    <span className="fa fa-quote-left"> </span>
                  </div>
                  <div className="text">
                    <p className="mb-4">
                      Far far away, behind the word mountains, far from the
                      countries Vokalia and Consonantia, there live the blind
                      texts.
                    </p>
                    <div className="d-flex align-items-center">
                      <div
                        className="user-img"
                        style={{
                          backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/image_3.jpg)`,
                        }}
                      ></div>
                      <div className="ps-3">
                        <p className="name">Roger Scott</p>
                        <span className="position">Marketing Manager</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
