import { useRef, useState, useEffect } from "react";
import defaultLogo from "../images/business-background-check.jpg";
import { useAuthentication } from "../hooks/auth.hook";
import { Telephone, Envelope, Camera } from "react-bootstrap-icons";
import { useForm, Controller } from "react-hook-form";
import { useUpdateProfileImageMutation } from "../redux/profile.api";
import { ProfileImage } from "../components";
import { useProfile } from "../hooks";
const ProfileView = () => {
  const auth = useAuthentication();
  const {
    user: { profileImage },
  } = auth;
  const { updateProfileImage: setStoreImage } = useProfile();
  const [updateProfileImage, updateQuery] = useUpdateProfileImageMutation();
  const [imageUrl, setImageUrl] = useState(null);
  const fileRef = useRef();

  const fullName = () =>
    `${auth.user.title} ${auth.user.firstName} ${auth.user.lastName}`;

  const { register, control, handleSubmit } = useForm();

  const handleFilechange = () => {
    setImageUrl(URL.createObjectURL(fileRef.current.files[0]));

    const formData = new FormData();
    formData.append("profileId", auth.user.Id);
    formData.append("profileImage", fileRef.current.files[0]);
    formData.append(
      "fullName",
      `${auth.user.title}_${auth.user.firstName}_${auth.user.lastName}`
    );
    updateProfileImage(formData)
      .unwrap()
      .then((res) => {
        setStoreImage(fileRef.current.files[0]["name"]);
        //setImageUrl(null);
      })
      .catch((e) => {
        alert(JSON.stringify(e));
      });
  };
  useEffect(() => {
    return () => {
      setImageUrl(null);
    };
  }, []);
  return (
    <div>
      <div className="card">
        <div className="card-body profile-background-image--default">
          <div className="" style={{ paddingTop: "5rem" }}>
            <div className="">
              <ProfileImage
                user={auth.user}
                canupdate={true}
                previewImage={imageUrl}
                onChangeClicked={() => fileRef.current.click()}
                style={["medium rounded"]}
              />
            </div>
            <div className="mt-4">
              <p className="m-0 p-0 h4">{fullName()} </p>
              <div className="tagcloud">
                <a href="#" className="tag-cloud-link">
                  {auth.user.typeInfo.configValue}
                </a>
              </div>
              <p className="m-0 p-0">
                <Telephone className="me-2" /> {auth.user.mobile}
              </p>
              <p className="m-0 p-0">
                <Envelope className="me-2" /> {auth.user.email}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="card collapse">
        <div className="card-body">
          <form onSubmit={handleSubmit((e) => console.log(JSON.stringify(e)))}>
            <div className="collapse">
              <Controller
                name="logo"
                control={control}
                render={({ field: { onChange } }) => (
                  <input
                    name="logo"
                    type="file"
                    {...register("logo")}
                    ref={fileRef}
                    onChange={handleFilechange}
                  ></input>
                )}
              />
            </div>

            <div className="">
              <button className="btn btn-primary" type="submit">
                SAVE
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export { ProfileView };
