import { Routes, Route } from "react-router-dom";
import { useAuthentication } from "../hooks";
import { ProfileView } from "./profile";
import { MyChurch } from "../profile/church";
import { MessageRoute } from "../messages/index";
import { JobProfile } from "../profile/employee/index";
import { MatrimonyProfile } from "../profile/matrimony";
const ProfileAccount = () => {
  const { user } = useAuthentication();

  return (
    <div>
      <Routes>
        <Route path="/" element={<ProfileView />} />
        <Route path="/profile" element={<ProfileView />} />
        <Route path="/church" element={<MyChurch />} />
        <Route path="/job" element={<JobProfile user={user} />} />
        <Route path="/matrimony" element={<MatrimonyProfile user={user} />} />
        <Route path="/messages" element={<MessageRoute />} />
      </Routes>
    </div>
  );
};

export { ProfileAccount };
