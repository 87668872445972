import { useParams, Link } from "react-router-dom";
import { useGetPrayerRequestQuery } from "../redux/prayer.api";

const PrayerRequestDetails = () => {
  const { id } = useParams();
  const { data, isLoading } = useGetPrayerRequestQuery(id);

  return (
    <div className="ftco-section">
      <div className="container">
        <div className="card">
          <div className="card-body">
            {isLoading ? (
              <p>loading...</p>
            ) : (
              <>
                <h5>Prayer Request Details </h5>
                <hr />
               

                <div>
                  <div className="d-flex">
                    <div className="col-md-2">
                      <h6>Name</h6>
                    </div>
                    <div className="col-md-10">{data.name}</div>
                  </div>
                  <div className="d-flex">
                    <div className="col-md-2">
                      <h6>About</h6>
                    </div>
                    <div className="col-md-10">{data.aboutBeliever}</div>
                  </div>
                  <div className="d-flex">
                    <div className="col-md-2">
                      <h6>Mobile</h6>
                    </div>
                    <div className="col-md-10">{data.mobile}</div>
                  </div>
                  <div className="d-flex">
                    <div className="col-md-2">
                      <h6>Email</h6>
                    </div>
                    <div className="col-md-10">{data.email}</div>
                  </div>
                  <div className="d-flex">
                    <div className="col-md-2">
                      <h6>Category</h6>
                    </div>
                    <div className="col-md-10">{data.categoryId}</div>
                  </div>
                  <div className="d-flex">
                    <div className="col-md-2">
                      <h6>Date</h6>
                    </div>
                    <div className="col-md-10">{data.createdDate}</div>
                  </div>
                </div>

                <div>
                  <Link className="btn btn-primary" to={"/prayer-request"}>
                    Back
                  </Link>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export { PrayerRequestDetails };
