import { useEffect, useState } from "react";
import { useSearchConfigQuery } from "../redux/config.api";
const useRegistrationType = ({ configName, configValue }) => {
  const [config, setConfig] = useState();
  const { data, isLoading, isError } = useSearchConfigQuery({
    name: configName,
    value: configValue,
  });
  useEffect(() => {
    if (!isLoading && data) {
      setConfig(data);
    }
    return () => {};
  }, [isLoading]);
  return { config };
};
export { useRegistrationType };
