import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  steps: [
    { id: 1, name: "basicInfo", data: null, isComplete: false, type: "form" },
    { id: 2, name: "contactInfo", data: null, isComplete: false, type: "form" },
    { id: 3, name: "socialLinks", data: null, isComplete: false, type: "form" },
    {
      id: 4,
      name: "loginDetails",
      data: null,
      isComplete: false,
      type: "form",
    },
    // { id: 5, name:"summary", data: null, isComplete: false, type: "summary" },
    // { id: 6, name:"", data: null, isComplete: false, type: "summary" },
  ],
  currentStep: 1,
};

const companyWizardSlice = createSlice({
  name: "companyWizard",
  initialState,
  reducers: {
    updateStep: (state, { payload: { step, data } }) => {
      const __step = state.steps.find((f) => f.id == step);
      __step.isComplete = true;
      __step.data = data;
      const stepIndex = state.steps.findIndex((f) => f.id == step);
      state.steps.slice(stepIndex, 1, __step);
    },
    moveNext: (state) => {
      state.currentStep += 1;
    },
    moveBack: (state) => {
      state.currentStep -= 1;
    },
    reset: (state) => {
      state = initialState;
    },
  },
});

export const { updateStep, moveNext, moveBack, reset } = companyWizardSlice.actions;
export default companyWizardSlice.reducer;
