import { useChurchImageBase } from "../hooks/church-image.hook";

const ChurchImageComponent = ({ churchId }) => {
  const { imageBase } = useChurchImageBase(churchId);

  return (
    <div>
      <img src={imageBase} className="img-fluid" alt="..."></img>
    </div>
  );
};

export { ChurchImageComponent };
