import { useEffect, forwardRef } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

const StepThree = (props, ref) => {
  const { data: stepData } = useSelector(
    (state) => state.churchWizard.steps[2]
  );

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (stepData) {
      const fields = ["twitter", "facebook", "youtube", "instagram"];
      fields.map((f) => setValue(f, stepData[f]));
    }
  }, [stepData]);

  return (
    <form onSubmit={handleSubmit(props.onStepSubmit)}>
      <h4>Social Links</h4>
      <hr></hr>
      <div className="d-flex mb-3">
        <label className="col-form-label col-md-2">Twitter</label>
        <div className="col-md-10">
          <input
            className={`form-control ${errors.twitter && "is-invalid"}`}
            {...register("twitter", { required: "twitter page is required" })}
            placeholder="enter twitter channel link"
          ></input>
          <ErrorMessage
            errors={errors}
            name="twitter"
            render={({ message }) => (
              <p className="invalid-feedback">{message}</p>
            )}
          />
        </div>
      </div>
      <div className="d-flex mb-3">
        <label className="col-form-label col-md-2">facebook</label>
        <div className="col-md-10">
          <input
            className={`form-control ${errors.facebook && "is-invalid"}`}
            {...register("facebook", { required: "facebook page is required" })}
            placeholder="enter facebook page link"
          ></input>
          <ErrorMessage
            errors={errors}
            name="facebook"
            render={({ message }) => (
              <p className="invalid-feedback">{message}</p>
            )}
          />
        </div>
      </div>
      <div className="d-flex mb-3">
        <label className="col-form-label col-md-2">Youtube</label>
        <div className="col-md-10">
          <input
            className={`form-control ${errors.youtube && "is-invalid"}`}
            {...register("youtube", { required: "youtube channel link is required" })}
            placeholder="enter youtube channel link"
          ></input>{" "}
          <ErrorMessage
            errors={errors}
            name="youtube"
            render={({ message }) => (
              <p className="invalid-feedback">{message}</p>
            )}
          />
        </div>
      </div>
      <div className="d-flex mb-3">
        <label className="col-form-label col-md-2">Instragram</label>
        <div className="col-md-10">
          <input
            className={`form-control ${errors.instagram && "is-invalid"}`}
            {...register("instagram", { required: "Instagram channel link is required" })}
            placeholder="enter instragram page link"
          ></input>
          <ErrorMessage
            errors={errors}
            name="instagram"
            render={({ message }) => (
              <p className="invalid-feedback">{message}</p>
            )}
          />
        </div>
      </div>
      <button type="submit" className="collapse" ref={ref}>
        SAVE
      </button>
    </form>
  );
};

export default forwardRef(StepThree);
