import { useMemo, createRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  usePostJobProfileMutation,
  useUploadCVMutation,
} from "../../../redux/job-profile.api";
import {
  moveNext,
  moveBack,
  updateStep,
  reset,
} from "../../../redux/job-profile.wizard.slice";
import { ActiveStep } from "./active-step";

const EmployeeWizard = ({ user }) => {
  const { steps, currentStep } = useSelector((state) => state.jobProfileWizard);
  const stepsCount = steps.length;
  const [uploadCV, fileQuery] = useUploadCVMutation();
  const [postJobProfile, profileQuery] = usePostJobProfileMutation();

  const dispatch = useDispatch();

  const stepSubmitRef = useMemo(
    () =>
      Array(stepsCount)
        .fill(0)
        .map((i) => createRef()),
    []
  );

  const handleNext = () => {
    stepSubmitRef[currentStep - 1]?.current.click();
  };

  function __uploadCV() {
    const data = steps[1].data;
    return uploadCV(data)
      .unwrap()
      .then((res) => res)
      .catch((e) => e);
  }

  const handleStepSubmit = (data) => {
    if (currentStep < stepsCount) {
      dispatch(updateStep({ data, step: currentStep }));
      dispatch(moveNext());
    }
    if (currentStep == stepsCount) {
      // dispatch(updateStep({ data, step: currentStep }));
      postJobProfile({ ...steps[0].data, profileId: user.Id })
        .unwrap()
        .then(async (res) => {
          data.append("profileId", res.id);
          dispatch(updateStep({ data, step: currentStep }));
          return await __uploadCV(steps[1].data)
            .then((t) => t)
            .catch((e) => alert(JSON.stringify(e)));
        })
        .then((res) => ({}))
        .catch((e) => alert(JSON.stringify(e)));
    }
  };
  return (
    <div className="card">
      <div className="card-body">
        <ActiveStep
          stepSubmitRef={stepSubmitRef}
          currentStep={currentStep}
          handleStepSubmit={handleStepSubmit}
        />
      </div>
      <div className="card-footer">
        <div className="justify-content-end">
          <button
            className="btn btn-primary me-1"
            onClick={() => dispatch(moveBack())}
            disabled={currentStep < 1}
          >
            Back
          </button>
          <button className="btn btn-primary ms-1" onClick={handleNext}>
            {currentStep == stepsCount ? <>SAVE</> : <>NEXT</>}
          </button>
        </div>
      </div>
    </div>
  );
};
export { EmployeeWizard };
