import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiBaseUrl as baseUrl } from "../_constants";

export const companiesApi = createApi({
  reducerPath: "companiesApi",
  baseQuery: fetchBaseQuery({ baseUrl }),
  tagTypes: ["Post"],
  endpoints: (builder) => ({
    getAllCompany: builder.query({
      query: () => `/company/index.php`,
      providesTags: ["Post"],
    }),
    getCompany: builder.query({
      query: (id) => `/company/details.php?id=${id}`,
      providesTags: ["Post"],
    }),
    postCompany: builder.mutation({
      query: (obj) => ({
        url: `/company/index.php`,
        method: "POST",
        body: obj,
      }),
      invalidatesTags: ["Post"],
    }),
    putCompany: builder.mutation({
      query: (obj) => ({
        url: `/company/index.php`,
        method: "PUT",
        body: obj,
      }),
      invalidatesTags: ["Post"],
    }),
    deleteCompany: builder.mutation({
      query: (obj) => ({
        url: `/company/index.php`,
        method: "DELETE",
        body: obj,
      }),
      invalidatesTags: ["Post"],
    }),
    getImage: builder.query({
      query: (id) => `/company/image.php?id=${id}`,
    }),
    updateProfileImage: builder.mutation({
      query: (obj) => ({
        url: `/company/image.php`,
        method: "POST",
        body: obj,
      }),
    }),
  }),
});

export const {
  useGetAllCompanyQuery,
  useGetCompanyQuery,
  usePostCompanyMutation,
  usePutCompanyMutation,
  useDeleteCompanyMutation,
  useGetImageQuery,
  useUpdateProfileImageMutation,
} = companiesApi;
