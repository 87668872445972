import React, { useState, useEffect } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import * as Icons from "react-bootstrap-icons";
import { useSelector } from "react-redux";
import { Paging } from "../components";
import { WishComponent } from "./wish.component";
import { Sidebar } from "../blog/sidebar.component";

import { useGetAllQuery, useGetJobsCountQuery } from "../redux/jobs.api";

const Post = ({ item, user }) => {
  return (
    <div className="card mb-3">
      <div className="card-body">
        <div className="d-flex">
          <div className="col-md-11">
            <Link to={`${item.Id}`} className="h4  text-primary">
              {item.title}
            </Link>
            <p className="mt-3">{item.company}</p>
            <p className="mb-1">
              <Icons.VectorPen />
              <span className="ms-2">{item.experience}</span>
            </p>
            <p className="mb-1">
              <Icons.Map />
              <span className="ms-2">{item.jobLocation}</span>
            </p>
            <p className="mb-1 d-none">
              <Icons.Briefcase />
              <span className="ms-2">{item.department}</span>
            </p>
            <p className="mb-1">
              <Icons.FileText />
              <span className="ms-2">{item.description}</span>
            </p>
            <p className="mb-1">
              <Icons.Calendar3 />
              <span className="ms-2">{item.closeDate}</span>
            </p>
            {user && <WishComponent profileId={user.Id} jobId={item.Id} />}
          </div>
          <div className="col-md-1">
            <img
              src={
                "http://www.blairtg.com/media/logo_brand/ibm-logo_circle_600.png"
              }
              style={{ width: 32, height: 32 }}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const JobsMaster = () => {
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const dataCount = useGetJobsCountQuery();
  const { user } = useSelector((state) => state.auth);

  const [nav, setNav] = useState({
    page: 1,
    rows: 10,
  });

  const { data: jobs, isLoading, refetch } = useGetAllQuery(nav);
  const checkPageSize = (count) => {
    if (nav.rows > count) {
      return 1;
    } else {
      return count / nav.rows;
    }
  };
  useEffect(() => {
    setNav((v) => ({
      page: searchParams.get("page") ?? 1,
      rows: searchParams.get("rows") ?? 10,
    }));
  }, [searchParams]);

  return (
    <div className="row g-lg-5">
      <div className="col-lg-8">
        <div className="row justify-content-center">
          {user &&
            jobs?.map((item) => (
              <Post item={item} key={`job-${item.Id}`} user={user} />
            ))}
        </div>
        <div className="row mt-5">
          <button
            className="btn btn-primary d-none"
            disabled={isLoading}
            onClick={refetch}
          >
            {isLoading ? (
              <div className="spinner-border text-light me-3" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              <>
                <div>Load More {dataCount.data}</div>
              </>
            )}
          </button>
          {dataCount.isSuccess && (
            <Paging
              total={checkPageSize(dataCount.data)}
              onPageChanged={() => {}}
            />
          )}
        </div>
      </div>

      {/* <div className="col-lg-4 sidebar pl-md-4">
        <Sidebar />
      </div> */}
    </div>
  );
};

export { JobsMaster, Post };
export * from "./job-description";
