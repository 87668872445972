import { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Calendar3 } from "react-bootstrap-icons";
import { useGetMappingByUserIdQuery } from "../../redux/profile-mapping.api";
import { ModalComponent } from "../../components/modal.component";
import { useDeleteUserMutation } from "../../redux/user.api";
const LoginDetails = () => {
  const { id } = useParams();
  const [showDialog, setShowDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const { data, isLoading, isError } = useGetMappingByUserIdQuery(id);
  const [deleteUser, deleteQuery] = useDeleteUserMutation();
  const navigate = useNavigate();
  const handleDelete = () => {
    deleteUser({ id })
      .unwrap()
      .then((res) => navigate(-1))
      .catch((e) => alert(e));
  };
  return (
    <div className="card">
      <div className="card-body">
        {!isLoading && data && (
          <>
            <Link
              to={`/profiles/${data.Id}`}
            >{`${data.title} ${data.firstName} ${data.lastName}`}</Link>
            <p>
              <Calendar3 className="me-2" /> {JSON.stringify(data.createdDate)}
            </p>
            <p>{data.profileType}</p>
          </>
        )}
        <div>
          <button className="btn btn-primary me-1" onClick={() => navigate(-1)}>
            Back
          </button>
          <button
            className="btn btn-success ms-1"
            onClick={() => setShowDialog(true)}
          >
            Delete
          </button>
        </div>
      </div>
      <ModalComponent
        title="DELETE"
        message={""}
        showDialog={showDialog}
        onOkClick={handleDelete}
        onCancelClick={() => setShowDialog(false)}
      />
    </div>
  );
};
export { LoginDetails };
