import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiBaseUrl } from "../_constants";

export const subscriptionApi = createApi({
  reducerPath: "subscriptionApi",
  baseQuery: fetchBaseQuery({ baseUrl: apiBaseUrl }),
  tagTypes: ["LIST"],
  endpoints: (builder) => ({
    getAllSubscriptions: builder.query({
      query: () => "/subscriptions/index.php",
      providesTags: ["LIST"],
    }),
    getSubscriptionById: builder.query({
      query: (id) => `/subscriptions/index.php?profileId=${id}`,
      providesTags: ["LIST"],
    }),
    deleteSubscription: builder.mutation({
      query: (id) => ({
        url: `/subscriptions/index.php`,
        method: "DELETE",
        body: { id },
      }),
      invalidatesTags: ["LIST"],
    }),
  }),
});

export const {
  useGetAllSubscriptionsQuery,
  useGetSubscriptionByIdQuery,
  useDeleteSubscriptionMutation,
} = subscriptionApi;
