import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate } from "react-router-dom";
import { Building, Columns, People } from "react-bootstrap-icons";
// import { useSignupMutation } from "../redux/auth.api";

const SignUp = () => {
  const navigate = useNavigate();
  const validationSchema = yup.object({
    username: yup.string().required("user name is required"),
    password: yup.string().required("password is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) });

  // const [signup, request] = useSignupMutation();

  const onFormSubmit = (data) => {
    alert("sign up form submitted");
    // signup(data)
    //   .unwrap()
    //   .then((response) => navigate("/my-account"))
    //   .catch((error) => alert(JSON.stringify(error)));
  };

  return (
    <div className="ftco-section">
      <div className="container">
        {/* {request.isError && (
          <div className="alert alert-danger"> 
            {JSON.stringify(request.error)}
          </div>
        )} */}

        <div className="card">
          <div className="card-body">
            <div className="d-flex my-4">
              <div className="col-md-4 d-flex align-items-center justify-content-center">
                <div className="text-center">
                  <Building size={50} className="my-4" style={{color:'blue'}}/>
                  <h5>Church</h5>
                  <p className="p-4">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </p>
                  <Link className="btn btn-primary mt-2" to="/churches/create">
                    Register
                  </Link>
                </div>
              </div>
              <div className="col-md-4 d-flex align-items-center justify-content-center">
                <div className="text-center">
                  <Columns size={50} className="my-4" style={{color:'blue'}}/>
                  <h5>Company</h5>
                  <p className="p-4">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </p>
                  <Link className="btn btn-primary mt-2" to="/company/create">
                    Register
                  </Link>
                </div>
              </div>

              <div className="col-md-4 d-flex align-items-center justify-content-center">
                <div className="text-center">
                  <People size={50} className="my-4" style={{color:'blue'}}/>
                  <h5>Member</h5>
                  <p className="p-4">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </p>
                  <Link className="btn btn-primary mt-2" to="/members/create">
                    Register
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card collapse">
          <div className="card-body">
            <form onSubmit={handleSubmit(onFormSubmit)}>
              <div className="row mb-3">
                <label
                  className={`col-form-label col-md-2 ${
                    errors.username && "text-danger"
                  }`}
                >
                  Name
                </label>
                <div className="col-md-10">
                  <input
                    className={`form-control ${
                      errors.username && "is-invalid"
                    }`}
                    placeholder="enter name"
                    {...register("username")}
                  ></input>
                  <ErrorMessage
                    errors={errors}
                    name={"username"}
                    render={({ message }) => (
                      <p className="invalid-feedback">{message}</p>
                    )}
                  />
                </div>
              </div>

              <div className="row mb-3">
                <label
                  className={`col-form-label col-md-2 ${
                    errors.username && "text-danger"
                  }`}
                >
                  Password
                </label>
                <div className="col-md-10">
                  <input
                    type="password"
                    className={`form-control ${
                      errors.password && "is-invalid"
                    }`}
                    placeholder="password"
                    {...register("password")}
                  ></input>
                </div>
              </div>

              <div className="row mb-3">
                <div className="d-flex">
                  <button type="submit" className="btn btn-primary me-2">
                    Register
                  </button>
                  <Link to={"/"} className="btn btn-secondary">
                    CANCEL
                  </Link>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export { SignUp };
