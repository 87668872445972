import { GetStarted } from "../home/get-started.component";
import { Sidebar } from "./sidebar.component";

const BlogPostItem = ({ data }) => (
  <div className="col-md-12">
    <div className="blog-entry d-flex justify-content-end">
      <a
        href="blog-single.html"
        className="block-20 img"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/${data.image})`,
        }}
      ></a>
      <div className="text">
        <p className="meta">
          <span>
            <i className="fa fa-user me-1"></i>Admin
          </span>
          <span>
            <i className="fa fa-calendar me-1"></i>Jan. 27, 2021
          </span>
          <span>
            <a href="#">
              <i className="fa fa-comment me-1"></i> 3 Comments
            </a>
          </span>
        </p>
        <h3 className="heading mb-3">
          <a href="#">Incorporation is A Big Milestone for Your Business</a>
        </h3>
        <p>
          A small river named Duden flows by their place and supplies it with
          the necessary regelialia.
        </p>
        <a href="blog-single.html" className="btn btn-primary">
          Read more
        </a>
      </div>
    </div>
  </div>
);

const Blog = (props) => {
  const posts = [
    { id: 1, image: "ximage_1.jpg.pagespeed.ic.hLNkZmeXOJ.jpg" },
    { id: 2, image: "ximage_2.jpg.pagespeed.ic.mQGyn1P_Li.jpg" },
    { id: 3, image: "ximage_3.jpg.pagespeed.ic.CWHmT1gFhf.jpg" },
    { id: 4, image: "image_4.jpg" },
    { id: 5, image: "image_5.jpg" },
    { id: 6, image: "image_6.jpg" },
  ];

  return (
    <>
      <section className="ftco-section">
        <div className="container">
          <div className="row g-lg-5">
            <div className="col-lg-8">
              <div className="row justify-content-center">
                {posts.map((item) => (
                  <BlogPostItem data={item} />
                ))}
              </div>
              <div className="row mt-5">
                <div className="col text-center">
                  <div className="block-27">
                    <ul>
                      <li>
                        <a href="#">&lt;</a>
                      </li>
                      <li className="active">
                        <span>1</span>
                      </li>
                      <li>
                        <a href="#">2</a>
                      </li>
                      <li>
                        <a href="#">3</a>
                      </li>
                      <li>
                        <a href="#">4</a>
                      </li>
                      <li>
                        <a href="#">5</a>
                      </li>
                      <li>
                        <a href="#">&gt;</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 sidebar pl-md-4">
              <Sidebar />
            </div>
          </div>
        </div>
      </section>

      <GetStarted />
    </>
  );
};

export { Blog };
