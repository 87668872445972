import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Calendar2, At, Phone } from "react-bootstrap-icons";
import { Tab, Tabs } from "react-bootstrap";
import { ProfileImage } from "../components/profile-image.component";
import { useGetProfileQuery } from "../redux/profile.api";
import { useGetMappingByProfileIdQuery } from "../redux/church-mapping.api";
import {
  AgeGenderComponent,
  ChurchNameComponent,
  ChurchImageComponent,
} from "../components";
import moment from "moment";
import { JobsProfiles } from "./employee/job-profiles";

const ProfileChurch = ({ churchId }) => {
  return (
    <>
      <ChurchNameComponent id={churchId} />
    </>
  );
};

const ProfileDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data, isLoading } = useGetProfileQuery(id);
  const mappingQuery = useGetMappingByProfileIdQuery(id);
  const [church, setChurch] = useState();
  useEffect(() => {
    if (!mappingQuery.isLoading && mappingQuery.data) {
      console.log(JSON.stringify(mappingQuery));
      setChurch(mappingQuery.data.churchId);
    }

    return () => {};
  }, [mappingQuery.isLoading]);

  return (
    <div className="card">
      <div className="card-body p-4">
        {!isLoading && data && (
          <>
            <div className="d-flex mb-5">
              <ProfileImage user={data} style={["small"]} />
              <div className="mx-5">
                <div className="h5">
                  {`${data.title} ${data.firstName} ${data.lastName}`}
                  <small className="ms-3">
                    <AgeGenderComponent
                      dob={data.dob}
                      genderId={data.genderId}
                    />
                  </small>
                </div>
                <div className="mb-2">
                  <Calendar2 /> <span className="mx-2"> : </span>
                  {moment(data.dob).format("D-MM-yyyy")}
                </div>
                <div className="mb-2">
                  <At /> <span className="mx-2"> : </span> {data.email}
                </div>
                <div className="mb-2">
                  <Phone /> <span className="mx-2"> : </span> {data.mobile}
                </div>
                {/* <div className="mb-2 collapse">
                  Church : <ChurchNameComponent id={data.churchId} />
                </div> */}
                <button
                  to={"/profiles"}
                  onClick={() => navigate(-1)}
                  className="btn btn-primary collapse"
                >
                  Back
                </button>
              </div>
            </div>
            <Tabs defaultActiveKey={"church"}>
              <Tab eventKey={"church"} title="Church">
                <ProfileChurch churchId={church} />
              </Tab>
              <Tab eventKey={"matrimony"} title="Matrimony">
                details about matrimony
              </Tab>
              <Tab eventKey={"job"} title="Job">
                <JobsProfiles user={data} />
              </Tab>
            </Tabs>
          </>
        )}
      </div>
    </div>
  );
};

export { ProfileDetails };
