import { Routes, Route, Outlet } from "react-router-dom";

import { PrayerRequests } from "../prayery-request";
import { PrayerRequestForm } from "./prayer-request.form";
import { useAuthentication } from "../hooks/auth.hook";

export const PrayerRequest = (props) => {
  const { isLoggedIn } = useAuthentication();

  return (
    <>
      <Routes>
        {isLoggedIn && <Route path="/" element={<PrayerRequests />} />}
        <Route path="/" element={<PrayerRequestForm />} />
      </Routes>
      <Outlet />
    </>
  );
};
