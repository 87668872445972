import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { usePostRequestMutation } from "../redux/prayer.api";
import { PrayerRequests } from "../prayery-request";
import { useAuthentication } from "../hooks/auth.hook";

export const PrayerRequestForm = (props) => {
  const { isLoggedIn } = useAuthentication();

  const validationSchema = yup.object({
    name: yup.string().required("Name is required"),
    mobile: yup
      .number()
      .required("Mobile number is required")
      .typeError("Enter a valid mobile number"),
    emailAddress: yup
      .string()
      .email("Enter a valid email Id")
      .required("Email Id is required")
      .typeError("Enter a valid email Id"),
    categoryId: yup
      .number()
      .required("select an option")
      .typeError("select a request type"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) });

  const [postRequest] = usePostRequestMutation();

  const onFormSubmit = async (data) => {
    console.log(JSON.stringify(data));
    await postRequest(data);
  };

  return (
    <>
      <div className="ftco-section">
        <div className="container">
          <div className="card">
            <div className="card-body">
              <h4 className="mb-3">Prayer request</h4>
              <hr />
              <form onSubmit={handleSubmit(onFormSubmit)}>
                <div className="d-flex mb-3">
                  <label
                    className={`col-form-label col-md-2 ${
                      errors.name && "text-danger"
                    }`}
                  >
                    Name
                  </label>
                  <div className="col-md-10">
                    <input
                      className={`form-control ${errors.name && "is-invalid"}`}
                      placeholder="enter your Name"
                      {...register("name")}
                    ></input>
                    <ErrorMessage
                      errors={errors}
                      name="name"
                      render={({ message }) => (
                        <p className="invalid-feedback">{message}</p>
                      )}
                    />
                  </div>
                </div>

                <div className="d-flex mb-3">
                  <label
                    className={`col-form-label col-md-2 ${
                      errors.emailAddress && "text-danger"
                    } `}
                  >
                    Email
                  </label>
                  <div className="col-md-10">
                    <input
                      className={`form-control ${
                        errors.emailAddress && "is-invalid"
                      }`}
                      placeholder="enter a valid email"
                      {...register("emailAddress")}
                    ></input>
                    <ErrorMessage
                      errors={errors}
                      name="emailAddress"
                      render={({ message }) => (
                        <p className="invalid-feedback">{message}</p>
                      )}
                    />
                  </div>
                </div>

                <div className="d-flex mb-3">
                  <label
                    className={`col-form-label col-md-2  ${
                      errors.mobile && "text-danger"
                    }`}
                  >
                    Mobile
                  </label>
                  <div className="col-md-10">
                    <input
                      className={`form-control ${
                        errors.mobile && "is-invalid"
                      }`}
                      placeholder="enter mobile"
                      {...register("mobile")}
                    ></input>
                    <ErrorMessage
                      errors={errors}
                      name="mobile"
                      render={({ message }) => (
                        <p className="invalid-feedback">{message}</p>
                      )}
                    />
                  </div>
                </div>

                <div className="d-flex mb-3">
                  <label className="col-form-label col-md-2">About</label>
                  <div className="col-md-10">
                    <textarea
                      className="form-control"
                      rows={6}
                      placeholder="enter a few about yourself"
                      {...register("aboutBeliever")}
                    ></textarea>
                  </div>
                </div>

                <div className="d-flex mb-3">
                  <label
                    className={`col-form-label col-md-2 ${
                      errors.requestType && "text-danger"
                    }`}
                  >
                    Other
                  </label>
                  <div className="col-md-10">
                    <select
                      className={`form-control ${
                        errors.requestType && "is-invalid"
                      }`}
                      placeholder="enter mobile"
                      {...register("categoryId")}
                    >
                      <option value={null} selected disabled>
                        select an option
                      </option>
                      <option value={1}>One</option>
                      <option value={2}>Two</option>
                      <option value={3}>Three</option>
                    </select>
                    <ErrorMessage
                      errors={errors}
                      name="requestType"
                      render={({ message }) => (
                        <p className="invalid-feedback">{message}</p>
                      )}
                    />
                  </div>
                </div>

                <div className="d-flex mb-3">
                  <div className="col-md-10 offset-md-2">
                    <button className="btn btn-primary me-2">SAVE</button>
                    <Link to={"/"} className="btn btn-primary me-2">
                      CANCEL
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
