import { Route, Routes, Outlet } from "react-router-dom";

// pages
import {
  Connect,
  ContactPage,
  ForgotPassword,
  Login,
  MyAccount,
  News,
  PrayerRequest,
  Services,
  SignUp,
} from "../pages";

// home components
import { HomeComponent } from "../home/index";

// profile components
import { Profile, ProfileForm, ProfileDetails } from "../profile";

// job components
import { Jobs, JobDescription, JobForm } from "../jobs/index";

// company components
import { Company, CompanyDetails, CompanyForm } from "../company/index";
import { CompanyCreateWizard } from "../company/wizard";

// matrimony
import {
  Matrimony,
  MatrimonyMaster,
  ProfileDetails as MatrimonyProfile,
} from "../matrimony";

// churches
import { ChurchDetails, Churches, ChurchForm } from "../church";
import { ChurchWizard } from "../church/wizard";

// configs
import { ConfigForm, Configs } from "../config";

// components
import { Navigation } from "../components";

// blog
import { Blog } from "../blog";

//settings
import { Settings } from "../settings";
import { PrayerRequestDetails } from "../prayery-request";
import { Messages, MessageRoute } from "../messages";

const RouteComponent = () => {
  return (
    <>
      <Navigation />
      <Routes>
        <Route exact path={"/"} element={<HomeComponent />} />
        <Route path={"/home"} element={<HomeComponent />} />
        <Route path={"/connect"} element={<Connect />} />
        <Route path={"/contact"} element={<ContactPage />} />
        <Route path={"/news"} element={<News />} />
        <Route path={"/services"} element={<Services />} />
        <Route path={"/blog"} element={<Blog />} />
        <Route path={"/signup"} element={<SignUp />} />
        <Route path={"/login"} element={<Login />} />
        <Route path={"/prayer-request/*"} element={<PrayerRequest />}>
          <Route path={":id"} element={<PrayerRequestDetails />} />
        </Route>
        <Route path={"/my-account/*"} element={<MyAccount />} />
        <Route path={"/forgot-password"} element={<ForgotPassword />} />
        <Route path={"/company/*"} element={<Company />}>
          <Route path=":id" element={<CompanyDetails />} />
        </Route>
        <Route path={"/churches/*"} element={<Churches />}>
          <Route path="create" element={<ChurchWizard />} />
          <Route path=":id" element={<ChurchDetails />} />
        </Route>
        <Route path={"/profiles/*"} element={<Profile />}>
          <Route path="create" element={<ProfileForm />} />
          <Route path=":id" element={<ProfileDetails />} />
          <Route path="edit/:id" element={<ProfileForm />} />
        </Route>
        <Route path={"/jobs/*"} element={<Jobs />}>
          <Route path=":id" element={<JobDescription />} />
          {/* <Route path="create" element={<JobForm />} />
          <Route path="edit/:id" element={<JobForm />} /> */}
        </Route>
        <Route path={"/matrimony/*"} element={<Matrimony />}></Route>
        <Route path={"/settings/*"} element={<Settings />}>
          <Route path={"configs/*"} element={<Configs />}>
            <Route path="create" element={<ConfigForm />} />
            <Route path="edit/:id" element={<ConfigForm />} />
          </Route>
        </Route>
        <Route path="/messages/*" element={<MessageRoute />} />
        <Route path="/members/*" element={<Profile />} />
        <Route
          path="*"
          element={
            <main style={{ padding: "1rem" }}>
              <p>There's nothing here!</p>
            </main>
          }
        />
      </Routes>

      <Outlet />
    </>
  );
};

export { RouteComponent };
