import moment from "moment";

const AgeComponent = ({ dob }) => {
  return dob == null ? (
    ""
  ) : (
    <>{moment(new Date()).diff(moment(dob), "years")}</>
  );
};

export { AgeComponent };
