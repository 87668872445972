import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiBaseUrl } from "../_constants";
import * as helpers from "../helpers";
export const jobProfileApi = createApi({
  reducerPath: "jobProfileApi",
  baseQuery: fetchBaseQuery({ baseUrl: apiBaseUrl }),
  tagTypes: ["Post"],
  endpoints: (builder) => ({
    getAllProfiles: builder.query({
      query: (params) => `job-profile/index.php${helpers.buildString(params)}`,
      providesTags: ["Post"],
    }),
    getJobProfile: builder.query({
      query: (id) => `job-profile/index.php?id=${id}`,
      providesTags: ["Post"],
    }),
    getCV: builder.query({
      query: () => `job-profile/download.php`,
    }),
    getJobProfileByProfileId: builder.query({
      query: (id) => `job-profile/index.php?profileId=${id}`,
      providesTags: ["Post"],
    }),
    postJobProfile: builder.mutation({
      query: (obj) => ({
        url: `job-profile/index.php`,
        method: "POST",
        body: obj,
      }),
      invalidatesTags: ["Post"],
    }),
    putJobProfile: builder.mutation({
      query: (obj) => ({
        url: `job-profile/index.php`,
        method: "PUT",
        body: obj,
      }),
      invalidatesTags: ["Post"],
    }),
    deleteJobProfile: builder.mutation({
      query: (obj) => ({
        url: `job-profile/index.php`,
        method: "DELETE",
        body: obj,
      }),
      invalidatesTags: ["Post"],
    }),
    // applyJob: builder.mutation({
    //   query: (obj) => ({
    //     url: `jobs/application.php`,
    //     method: "POST",
    //     body: obj,
    //   }),
    //   invalidatesTags: ["Post"],
    // }),
    // isApplied: builder.query({
    //   query: (params) => ({
    //     url: `jobs/isapplied.php${helpers.buildString(params)}`,
    //   }),
    //   providesTags: ["Post"],
    // }),
    uploadCV: builder.mutation({
      query: (params) => ({
        url: `jobs/upload.php`,
        method: "POST",
        body: params,
      }),
    }),
    getJobsCount: builder.query({
      query: (companyId) => ({
        url: `jobs/index.php?dataType=count&companyId=${companyId}`,
      }),
    }),
  }),
});

export const {
  useGetAllProfilesQuery,
  useGetJobsCountQuery,
  useGetJobProfileQuery,
  useGetJobProfileByProfileIdQuery,
  usePostJobProfileMutation,
  usePutJobProfileMutation,
  useDeleteJobProfileMutation,
  useGetCVQuery,
  //   useApplyJobMutation,
  //   useIsAppliedQuery,
  useUploadCVMutation,
} = jobProfileApi;
