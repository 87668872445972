import { Link, useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorMessage } from "@hookform/error-message";
import * as yup from "yup";
import {
  usePostJobMutation,
  useGetJobQuery,
  usePutJobMutation,
} from "../redux/jobs.api";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useEffect } from "react";


const JobForm = (props) => {
  const params = useParams();
  const navigate = useNavigate();

  const [postJob] = usePostJobMutation();
  const [putJob] = usePutJobMutation();
  const { data, isLoading } = useGetJobQuery(params.id, { skip: !params.id });

  const schema = yup.object({
    title: yup.string().required("Job title is required"),
    description: yup.string().required("Job description is required"),
    experience: yup.string().required("Candidate experience is required"),
    jobLocation: yup.string().required("Job Location is required"),
    closeDate: yup
      .date()
      .typeError("Select a valid date")
      .required("Job close date is required"),
  });

  const {
    control,
    watch,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (data) {
      const fields = [
        "title",
        "description",
        "companyId",
        "experience",
        "jobLocation",
      ];
      fields.map((f) => setValue(f, data[f]));
    }
    return () => {};
  }, [isLoading]);

  const ShowErrorMessage = ({ field }) => (
    <ErrorMessage
      errors={errors}
      name={field}
      render={({ message }) => <p className="invalid-feedback">{message}</p>}
    />
  );

  const onSubmit = (data) => {
    const __data = {
      ...data,
      closeDate: moment(data.closeDate).toISOString(),
      companyId: props.companyId,
    };

    if (params.id) {
      putJob(__data)
        .unwrap()
        .then((res) => {
          navigate(-1);
        })
        .catch((e) => {
          console.error(e);
        });
    } else {
      postJob(__data)
        .unwrap()
        .then((res) => {
          navigate(-1);
        })
        .catch((e) => console.error(e));
    }
  };

  return (
    <div className="card">
      <div className="card-body">
        <h3 className="card-title">Job creation form</h3>
        <form onSubmit={handleSubmit(onSubmit)}>
          {params.id && (
            <>
              <input type="hidden" name="id" value={params.id}></input>
              <input
                type="hidden"
                name="companyId"
                value={params.companyId}
              ></input>
            </>
          )}
          <div className="row mb-3">
            <label className="col-form-label col-md-2">Job Title</label>
            <div className="col-md-10">
              <input
                type="text"
                className={`form-control ${errors.title && "is-invalid"}`}
                placeholder="enter job title"
                {...register("title", { required: true })}
              ></input>
              <ShowErrorMessage field="title" />
            </div>
          </div>

          <div className="row mb-3">
            <label className="col-form-label col-md-2">Description</label>
            <div className="col-md-10">
              <textarea
                className={`form-control ${errors.description && "is-invalid"}`}
                placeholder="enter job description"
                {...register("description", { required: true })}
              ></textarea>
              <ShowErrorMessage field="description" />
            </div>
          </div>

          <div className="row mb-3">
            <label className="col-form-label col-md-2">Exp Req.</label>
            <div className="col-md-10">
              <input
                type="text"
                className={`form-control ${errors.experience && "is-invalid"}`}
                placeholder="enter experience required"
                {...register("experience", { required: true })}
              ></input>
              <ShowErrorMessage field="experience" />
            </div>
          </div>

          <div className="row mb-3">
            <label className="col-form-label col-md-2">Location</label>
            <div className="col-md-10">
              <input
                type="text"
                className={`form-control ${errors.jobLocation && "is-invalid"}`}
                placeholder="enter location required"
                {...register("jobLocation", { required: true })}
              ></input>
              <ShowErrorMessage field="jobLocation" />
            </div>
          </div>

          <div className="row mb-3">
            <label className="col-form-label col-md-2">Close Date</label>
            <div className="col-md-10">
              <Controller
                control={control}
                name="closeDate"
                render={({ field }) => (
                  <DatePicker
                    className={`form-control ${
                      errors.closeDate && "is-invalid"
                    }`}
                    onChange={(date) => field.onChange(date)}
                    selected={field.value}
                  ></DatePicker>
                )}
              />
              <ShowErrorMessage field="closeDate" />
            </div>
          </div>

          <div className="row mb-3">
            <div className="offset-md-2 col-md-10">
              <button type="submit" className="btn btn-primary me-2">
                SAVE
              </button>
              <button type="reset" className="btn btn-secondary me-2">
                RESET
              </button>
              <Link className="btn btn-danger" to="../">
                CANCEL
              </Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export { JobForm };
