import { StarFill, StarHalf } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import { useGetAllCompanyQuery } from "../redux/company.api";

const CompanyItem = ({ item }) => {
  return (
    <div className="card mb-3">
      <div className="card-body">
        <Link to={`${item.Id}`} className="h6">
          {item.title}
        </Link>
        <div className="d-flex">
          <StarFill style={{ color: "#ffe200" }} />
          <StarFill style={{ color: "#ffe200" }} />
          <StarFill style={{ color: "#ffe200" }} />
          <StarFill style={{ color: "#ffe200" }} />
          <StarHalf style={{ color: "#ffe200" }} />
        </div>
      </div>
    </div>
  );
};

export const CompanyMaster = () => {
  const { data: companies, isSuccess } = useGetAllCompanyQuery();

  return (
    <div className="container">
      <div className="row d-flex mb-3">
        <div>
          You own company? you have job oppurtunities? Register here and find a
          matching employee.
          <Link to={"create"} className="ms-3">
            Register
          </Link>
        </div>
      </div>
      {isSuccess &&
        companies &&
        companies.map((item) => (
          <CompanyItem item={item} key={`company-${item.Id}`} />
        ))}
    </div>
  );
};
