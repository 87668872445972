import { useNavigate, useParams } from "react-router-dom";
import { Pencil, FileEarmarkText } from "react-bootstrap-icons";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import {
  usePostChurchEventMutation,
  usePutChurchEventMutation,
  useGetEventQuery,
} from "../../redux/church-events.api";
import { useEffect } from "react";

const EventsForm = ({ churchId }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [postChurchEvent, postQuery] = usePostChurchEventMutation();
  const [putChurchEvent, putQuery] = usePutChurchEventMutation();

  const query = useGetEventQuery(id, { skip: !id });
  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();

  useEffect(() => {
    if (!query.isLoading && query.data) {
      const fields = ["description", "eventDate"];
      fields.map((f) => setValue(f, query.data[f]));
      if (id) {
        setValue("Id", id);
        setValue("createdDate", query.data["createdDate"]);
      }
    }
    return () => {};
  }, [query.isLoading]);

  function __postForm(data) {
    return postChurchEvent(data)
      .unwrap()
      .then((res) => true)
      .catch((e) => false);
  }

  function __putForm(data) {
    return putChurchEvent(data)
      .unwrap()
      .then((res) => true)
      .catch((e) => false);
  }

  const onFormSubmit = async (data) => {
    const obj = { churchId, ...data };

    const result = id ? await __putForm(obj) : await __postForm(obj);

    if (result) {
      navigate("../");
    } else {
      alert("Error saving data... try later");
    }
  };

  return (
    <div className="card">
      <div className="card-body">
        <h4> {id ? <Pencil /> : <FileEarmarkText />} Event </h4> <hr />
        <form onSubmit={handleSubmit(onFormSubmit)}>
          {id && (
            <>
              <input type="hidden" {...register("Id")} />
              <input type="hidden" {...register("createdDate")} />
            </>
          )}
          <div className="row mb-3">
            <label className="col-md-2 col-form-label">Title</label>
            <div className="col-md-10">
              <input
                className={`form-control ${errors.description && "is-invalid"}`}
                placeholder="Enter title"
                {...register("description", { required: "Title is required" })}
              />
              <ErrorMessage
                errors={errors}
                name="description"
                render={({ message }) => (
                  <p className="invalid-feedback">{message}</p>
                )}
              />
            </div>
          </div>
          <div className="row mb-3">
            <label className="col-md-2 col-form-label">Date</label>
            <div className="col-md-10 d-flex">
              <div className="col">
                <input
                  className={`form-control ${errors.eventDate && "is-invalid"}`}
                  placeholder="Enter date and time"
                  {...register("eventDate", {
                    required: "Date is required",
                  })}
                />

                <ErrorMessage
                  errors={errors}
                  name="eventDate"
                  render={({ message }) => (
                    <p className="invalid-feedback">{message}</p>
                  )}
                />
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-10 col-md-2">
              <button className="btn btn-primary">SAVE</button>
              {!id && (
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => reset()}
                >
                  RESET
                </button>
              )}
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => navigate(-1)}
              >
                CANCEL
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export { EventsForm };
