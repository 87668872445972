import { Link, Routes, Route, Outlet } from "react-router-dom";
import { useGetAllMatrimonyQuery } from "../redux/matrimony.api";
const __data = [
  { id: 1, image: "ximage_1.jpg.pagespeed.ic.hLNkZmeXOJ.jpg" },
  { id: 2, image: "ximage_2.jpg.pagespeed.ic.mQGyn1P_Li.jpg" },
  { id: 3, image: "ximage_3.jpg.pagespeed.ic.CWHmT1gFhf.jpg" },
  { id: 4, image: "image_4.jpg" },
  { id: 5, image: "image_5.jpg" },
  { id: 6, image: "image_6.jpg" },
];

const ProfileItem = ({ data }) => {
  return (
    <div className="col-md-12">
      <div className="blog-entry d-flex justify-content-end">
        <a
          href="#"
          className="block-20 img"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/ximage_1.jpg.pagespeed.ic.hLNkZmeXOJ.jpg)`,
          }}
        ></a>
        <div className="text">
          <p className="meta">
            {/* <span>
              <i className="fa fa-user me-1"></i>Admin
            </span> */}
            <span>
              <i className="fa fa-calendar me-1"></i>{JSON.stringify(data.subscriptionDate)}
            </span>
            <span>
              <a href="#">
                <i className="fa fa-comment me-1"></i> 3 Comments
              </a>
            </span>
          </p>
          <h3 className="heading mb-3">
            <a href="#">{`${data.title} ${data.firstName} ${data.lastName}`}</a>
          </h3>
          <p>
           
          </p>
          <Link to={`${data.Id}`} className="btn btn-primary">
            Read more
          </Link>
        </div>
      </div>
    </div>
  );
};

const Index = () => {
  const { data, isLoading, isError, error } = useGetAllMatrimonyQuery();

  return (
    <>
      <section className="ftco-section">
        <div className="container">
          {isError && (
            <div className="alert alert-danger">{JSON.stringify(error)}</div>
          )}
          <div className="row g-lg-5">
            <div className="col-lg-8">
              <div className="row justify-content-center">  
                {!isLoading &&
                  !isError &&
                  data.map((item) => (
                    <ProfileItem key={`profile-${item.Id}`} data={item} />
                  ))}
              </div>
              <div className="row mt-5">
                <div className="col text-center">
                  <div className="block-27">
                    <ul>
                      <li>
                        <a href="#">&lt;</a>
                      </li>
                      <li className="active">
                        <span>1</span>
                      </li>
                      <li>
                        <a href="#">2</a>
                      </li>
                      <li>
                        <a href="#">3</a>
                      </li>
                      <li>
                        <a href="#">4</a>
                      </li>
                      <li>
                        <a href="#">5</a>
                      </li>
                      <li>
                        <a href="#">&gt;</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 sidebar pl-md-4">
              <div className="sidebar-box bg-light rounded">
                <form action="#" className="search-form">
                  <div className="form-group">
                    <span className="icon fa fa-search"></span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search..."
                    ></input>
                  </div>
                </form>
              </div>
              <div className="sidebar-box">
                <h3>Our Services</h3>
                <ul className="categories me-md-4">
                  <li>
                    <a href="#">
                      <span className="ion-ios-arrow-round-forward"></span>
                      Financial Planning
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span className="ion-ios-arrow-round-forward"></span>
                      Investments Management
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span className="ion-ios-arrow-round-forward"></span>
                      Business Loan
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span className="ion-ios-arrow-round-forward"></span>Taxes
                      Consulting
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span className="ion-ios-arrow-round-forward"></span>
                      Insurance Consulting
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span className="ion-ios-arrow-round-forward"></span>
                      Retirement Planning
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span className="ion-ios-arrow-round-forward"></span>Risk
                      Management
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span className="ion-ios-arrow-round-forward"></span>
                      Technology Consulting
                    </a>
                  </li>
                </ul>
              </div>
              <div className="sidebar-box">
                <h3>Recent Blog</h3>
                <div className="block-21 mb-4 d-flex">
                  <a
                    className="blog-img me-4"
                    style={{
                      backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/ximage_1.jpg.pagespeed.ic.hLNkZmeXOJ.jpg)`,
                    }}
                  ></a>
                  <div className="text">
                    <h3 className="heading">
                      <a href="#">
                        Incorporation is A Big Milestone for Your Business
                      </a>
                    </h3>
                    <div className="meta">
                      <div>
                        <a href="#">
                          <span className="fa fa-calendar"></span> January 27,
                          2021
                        </a>
                      </div>
                      <div>
                        <a href="#">
                          <span className="fa fa-user"></span> Admin
                        </a>
                      </div>
                      <div>
                        <a href="#">
                          <span className="fa fa-comment"></span> 19
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="block-21 mb-4 d-flex">
                  <a
                    className="blog-img me-4"
                    style={{
                      backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/ximage_2.jpg.pagespeed.ic.mQGyn1P_Li.jpg)`,
                    }}
                  ></a>
                  <div className="text">
                    <h3 className="heading">
                      <a href="#">
                        Incorporation is A Big Milestone for Your Business
                      </a>
                    </h3>
                    <div className="meta">
                      <div>
                        <a href="#">
                          <span className="fa fa-calendar"></span> January 27,
                          2021
                        </a>
                      </div>
                      <div>
                        <a href="#">
                          <span className="fa fa-user"></span> Admin
                        </a>
                      </div>
                      <div>
                        <a href="#">
                          <span className="fa fa-comment"></span> 19
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="block-21 mb-4 d-flex">
                  <a
                    className="blog-img me-4"
                    style={{
                      backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/image_3.jpg)`,
                    }}
                  ></a>
                  <div className="text">
                    <h3 className="heading">
                      <a href="#">
                        Incorporation is A Big Milestone for Your Business
                      </a>
                    </h3>
                    <div className="meta">
                      <div>
                        <a href="#">
                          <span className="fa fa-calendar"></span> January 27,
                          2021
                        </a>
                      </div>
                      <div>
                        <a href="#">
                          <span className="fa fa-user"></span> Admin
                        </a>
                      </div>
                      <div>
                        <a href="#">
                          <span className="fa fa-comment"></span> 19
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="sidebar-box">
                <h3>Tag Cloud</h3>
                <div className="tagcloud">
                  <a href="#" className="tag-cloud-link">
                    finance
                  </a>
                  <a href="#" className="tag-cloud-link">
                    fund
                  </a>
                  <a href="#" className="tag-cloud-link">
                    consult
                  </a>
                  <a href="#" className="tag-cloud-link">
                    business
                  </a>
                  <a href="#" className="tag-cloud-link">
                    funding
                  </a>
                  <a href="#" className="tag-cloud-link">
                    money
                  </a>
                  <a href="#" className="tag-cloud-link">
                    invest
                  </a>
                  <a href="#" className="tag-cloud-link">
                    plan
                  </a>
                  <a href="#" className="tag-cloud-link">
                    tax
                  </a>
                </div>
              </div>
              <div className="sidebar-box">
                <h3>Paragraph</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Ducimus itaque, autem necessitatibus voluptate quod mollitia
                  delectus aut, sunt placeat nam vero culpa sapiente consectetur
                  similique, inventore eos fugit cupiditate numquam!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
const MatrimonyMaster = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Index />} />
      </Routes>

      <Outlet />
    </div>
  );
};

export { MatrimonyMaster };
