import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiBaseUrl } from "../_constants";

export const matrimonyApi = createApi({
  reducerPath: "matrimony",
  baseQuery: fetchBaseQuery({ baseUrl: apiBaseUrl }),
  endpoints: (builder) => ({
    getAllMatrimony: builder.query({
      query: () => "/matrimony/index.php",
    }),
  }),
});

export const { useGetAllMatrimonyQuery } = matrimonyApi;
