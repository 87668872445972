import { Routes, Route, Outlet } from "react-router-dom";
import moment from "moment";

import { ChurchServices, ServiceForm } from "../church/services";
import { ChurchEvents } from "../church/events";
import { useGetChurchQuery } from "../redux/churches.api";
import { Notifications } from "../church/notification";
import { Members } from "../church/church.members";
import { useProfileChurchMapping } from "../hooks/church-mapping.hook";
import { ChurchImageComponent } from "../components";
const Dashboard = ({ user, churchMapping }) => {
  return (
    <div className="d-flex g-2">
      <div className="card">
        <div className="card-body">birthday counts</div>
      </div>
      <div className="card">
        <div className="card-body">wedding counts</div>
      </div>
    </div>
  );
};

const ChurchInfo = ({ id }) => {
  const { data, isLoading } = useGetChurchQuery(id);
  return (
    <>
      {isLoading ? (
        <>loading</>
      ) : (
        <div className="card">
          <div className="card-body">
            <ChurchImageComponent churchId={id} />
            <p>{data.title}</p>
            <p>{data.about}</p>
            <p>{moment(data.createdDate).format("D/MM/yyyy")}</p>
          </div>
        </div>
      )}
    </>
  );
};

const ChurchAccount = ({ user }) => {
  const { churchMapping } = useProfileChurchMapping(user.Id);
  return (
    <>
      {churchMapping && (
        <div className="">
          <Routes>
            <Route
              path="/"
              element={<Dashboard user={user} churchMapping={churchMapping} />}
            />
            <Route
              path="/profile"
              element={<Dashboard user={user} churchMapping={churchMapping} />}
            />
            <Route
              path="/services/*"
              element={<ChurchServices churchId={churchMapping.churchId} />}
            >
              <Route
                path="create"
                element={<ServiceForm churchId={churchMapping.churchId} />}
              />
            </Route>
            <Route
              path="/events/*"
              element={<ChurchEvents churchId={churchMapping.churchId} />}
            />
            <Route
              path="/notifications"
              element={<Notifications churchId={churchMapping.churchId} />}
            />
            <Route
              path="/members"
              element={<Members churchId={churchMapping.churchId} />}
            />
          </Routes>
          <Outlet />
        </div>
      )}
    </>
  );
};

export { ChurchAccount };
