import StepOne from "./step-one";
import StepTwo from "./step-two";
import StepThree from "./step-three";

const ActiveStep = ({ currentStep, handleStepSubmit, stepSubmitRef }) => {
  const steps = [
    {
      isAcive: currentStep == 1,
      component: (
        <StepOne
          ref={stepSubmitRef[currentStep - 1]}
          onStepSubmit={handleStepSubmit}
        />
      ),
    },
    {
      isAcive: currentStep == 2,
      component: (
        <StepThree
          ref={stepSubmitRef[currentStep - 1]}
          onStepSubmit={handleStepSubmit}
        />
      ),
    },
    
  ];
  const activeStep = steps.find((f) => f.isAcive == true);
  return <>{activeStep.component}</>;
};

export { ActiveStep };
