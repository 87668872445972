import { useState, useEffect } from "react";
import { useGetMappingByProfileIdQuery } from "../redux/church-mapping.api";


const useProfileChurchMapping = (profileId) => {
  const { data, isLoading } = useGetMappingByProfileIdQuery(profileId);
  const [churchMapping, setChurchMapping] = useState();
  useEffect(() => {
    if (!isLoading && data) {
      setChurchMapping(data);
    }
    return () => {};
  }, [isLoading]);
  return { churchMapping };
};


export { useProfileChurchMapping };
