import { RowIndex } from "../components/rowIndex.component";
import {
  useGetAllCompanyQuery,
  useDeleteCompanyMutation,
} from "../redux/company.api";
import { Trash3, ArrowClockwise } from "react-bootstrap-icons";
import { ModalComponent } from "../components/modal.component";
import { useState } from "react";
import { ProfileImage } from "../components/company-image.component";

const Company = () => {
  const [showDialog, setShowDialog] = useState(false);
  const [selectedItem, setselectedItem] = useState(false);
  const { data, isLoading, refetch } = useGetAllCompanyQuery();
  const [deleteCompany, deleteQuery] = useDeleteCompanyMutation();
  const handleDelete = () => {
    const obj = { id: selectedItem.Id };

    deleteCompany(obj)
      .unwrap()
      .then((res) => {
        setShowDialog(false);
      })
      .catch((e) => alert(alert(e)));
  };
  return (
    <div>
      <ModalComponent
        showDialog={showDialog}
        title="DELETE"
        message={selectedItem?.title}
        onCancelClick={() => setShowDialog(false)}
        onOkClick={handleDelete}
      />
      <div className="card">
        <div className="card-header">
          <div className="d-flex justify-content-between align-items-center">
            <h5>Companies</h5> <ArrowClockwise onClick={refetch} />
          </div>
        </div>

        <div className="card-body">
          <table className="table">
            <thead>
              <tr>
                <th>#</th>
                <th></th>
                <th>Name</th>
                <th>Date</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {!isLoading &&
                data &&
                data.map((item, index) => (
                  <tr key={item.Id}>
                    <td>
                      <RowIndex index={index} />
                    </td>
                    <td>
                      <ProfileImage style={"xx-small"} company={item} />
                    </td>
                    <td> {item.title} </td>
                    <td> {JSON.stringify(item.createdDate)} </td>
                    <td>
                      <Trash3
                        color="red"
                        onClick={() => {
                          setselectedItem(item);
                          setShowDialog(true);
                        }}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
export { Company };
