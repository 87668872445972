import { useMemo, createRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  moveBack,
  moveNext,
  reset,
  updateStep,
} from "../../redux/profile.wizard.slice";
import { ActiveStep } from "./active-step";
import { usePostProfileMutation } from "../../redux/profile.api";
import { useSearchConfigQuery } from "../../redux/config.api";
import { usePostUserMutation } from "../../redux/user.api";
import { usePostMappingMutation } from "../../redux/profile-mapping.api";
import { useNavigate } from "react-router-dom";
import { ModalComponent } from "../../components";
const ProfileWizard = () => {
  const { steps, currentStep } = useSelector((state) => state.profileWizard);
  const stepsCount = steps.length;
  const [postProfile, postQuery] = usePostProfileMutation();
  const [postUser, userQuery] = usePostUserMutation();
  const [postMapping, mappingQuery] = usePostMappingMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState(false);
  const configQuery = useSearchConfigQuery({
    name: "PROFILE_TYPE",
    value: "MEMBER",
  });
  
  const stepSubmitRef = useMemo(
    () =>
      Array(stepsCount)
        .fill(0)
        .map((i) => createRef()),
    []
  );

  const handleStepSubmit = async (data) => {
    dispatch(updateStep({ data: { ...data }, step: currentStep }));

    if (currentStep < stepsCount) {
      dispatch(moveNext());
    }

    if (currentStep == stepsCount) {
      const { Id: profileTypeId } = configQuery.data[0];

      const profile = { ...steps[0].data };

      postProfile(profile)
        .unwrap()
        .then(async ({ status, data }) => {
          return { ...data };
        })
        .then(async (res) => {
          console.log(JSON.stringify(res));
          return await createLogin(data)
            .then((t) => {
              const mapping = {
                profileId: res.id,
                userId: t.data.id,
                userTypeId: profileTypeId,
              };
              return mapping;
            })
            .catch((e) => alert(JSON.stringify(e)));
        })
        .then(async (res) => {
          return await createProfileMapping(res)
            .then((t) => {
              dispatch(reset());
              return t;
            })
            .catch((e) => {});
        })
        .then((res) => {
          setShowDialog(true);
        })
        .catch((e) => alert(JSON.stringify(e)));
    }
  };
  const handleNext = () => {
    stepSubmitRef[currentStep - 1]?.current.click();
  };

  //
  function createLogin(data) {
    return postUser(data)
      .unwrap()
      .then((t) => t)
      .catch((e) => e);
  }

  //
  function createProfileMapping(mapping) {
    return postMapping(mapping)
      .unwrap()
      .then((t) => {
        return t;
      })
      .catch((e) => e);
  }
  return (
    <>
      <div className="card">
        <div className="card-body p-5">
          <ActiveStep
            stepSubmitRef={stepSubmitRef}
            currentStep={currentStep}
            handleStepSubmit={handleStepSubmit}
          />
        </div>
        <div className="card-footer">
          {currentStep > 1 && (
            <button
              type="button"
              onClick={() => dispatch(moveBack())}
              className="btn btn-primary me-2"
            >
              BACK
            </button>
          )}
          <button
            type="button"
            onClick={handleNext}
            className="btn btn-primary"
          >
            {currentStep == stepsCount ? <>SAVE</> : <>NEXT</>}
          </button>
        </div>
      </div>
      <ModalComponent
        showDialog={showDialog}
        onOkClick={() => {
          navigate("/login");
        }}
        onCancelClick={() => setShowDialog(false)}
        title="Profile created successfully"
        data=""
        message={"Thank you."}
        okText="Ok"
      />
    </>
  );
};
export { ProfileWizard };
