import { forwardRef } from "react";
import { useForm } from "react-hook-form";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorMessage } from "@hookform/error-message";
const StepOne = (props, ref) => {
  const validationSchema = yup.object().shape({
    profileTitle: yup.string().required("Title is required"),
    description: yup.string().required("Description is required"),
  });
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ resolver: yupResolver(validationSchema) });
  const formSubmit = (data) => {
    props.onStepSubmit({ ...data });
  };

  return (
    <form onSubmit={handleSubmit(formSubmit)}>
      <div className="row mb-3">
        <label className="col-md-2 col-form-label">Title</label>
        <div className="col-md-10">
          <input
            className={`form-control ${errors.profileTitle && "is-invalid"} `}
            placeholder="Enter a profile title"
            {...register("profileTitle")}
          />
          <ErrorMessage
            errors={errors}
            name="profileTitle"
            render={({ message }) => (
              <p className="invalid-feedback">{message}</p>
            )}
          />
        </div>
      </div>
      <div className="row mb-3">
        <label className="col-md-2 col-form-label">Description</label>
        <div className="col-md-10">
          <input
            className={`form-control ${errors.description && "is-invalid"} `}
            placeholder="Describe your profile"
            {...register("description")}
          />
          <ErrorMessage
            errors={errors}
            name="description"
            render={({ message }) => (
              <p className="invalid-feedback">{message}</p>
            )}
          />
        </div>
      </div>
      <div>
        <button type="submit" ref={ref} className="collapse">
          Submit
        </button>
      </div>
    </form>
  );
};
export default forwardRef(StepOne);
