import { Routes, Route, Outlet } from "react-router-dom";
import { ConfigMaster } from "./config.master";
import { ConfigForm } from "./config.form";
export const Configs = (props) => {
  return (
    <div className="">
      <div className="">
        <Routes>
          <Route path="/" element={<ConfigMaster />} />
          <Route path="/create" element={<ConfigForm />} />
        </Routes>
        <Outlet />
      </div>
    </div>
  );
};

export * from "./config.form";
