export const Connect = () => {
  return (
    <div className="ftco-section">
      <div className="container">
        <div className="card">
          <div className="card-body">
            <h3>connect</h3>
          </div>
        </div>
      </div>
    </div>
  );
};
