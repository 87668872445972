import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useNavigate, useParams } from "react-router-dom";
import {
  useGetServiceQuery,
  usePostServiceMutation,
  usePutServiceMutation,
} from "../../redux/church-service.api";
import { useEffect } from "react";

import { Pencil, File, FileEarmarkText } from "react-bootstrap-icons";

const ServiceForm = ({ churchId }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const query = useGetServiceQuery(id, { skip: !id });
  const [postService, postQuery] = usePostServiceMutation();
  const [putService, putQuery] = usePutServiceMutation();
  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();

  function __postForm(data) {
    return postService(data)
      .unwrap()
      .then((res) => true)
      .catch((e) => false);
  }

  function __putForm(data) {
    return putService(data)
      .unwrap()
      .then((res) => true)
      .catch((e) => false);
  }
  const onFormSubmit = async (data) => {
    const obj = { ...data, churchId };

    const result = id ? await __putForm(obj) : await __postForm(obj);

    if (result) {
      navigate("../");
    } else {
      alert("Error saving data... try later");
    }
  };
  useEffect(() => {
    if (!query.isLoading && query.data) {
      const fields = ["description", "startTime", "endTime"];
      fields.forEach((f) => setValue(f, query.data[f]));
      if (id) {
        setValue("id", id);
        setValue("createdDate", query.data["createdDate"]);
      }
    }
  }, [query.isLoading]);
  return (
    <div className="card">
      <div className="card-body">
        <h4> {id ? <Pencil /> : <FileEarmarkText />} Service </h4> <hr />
        <form onSubmit={handleSubmit(onFormSubmit)}>
          {id && (
            <>
              <input type="hidden" {...register("Id")} />
              <input type="hidden" {...register("createdDate")} />
            </>
          )}
          <div className="row mb-3">
            <label className="col-md-2 col-form-label">Title</label>
            <div className="col-md-10">
              <input
                className={`form-control ${errors.title && "is-invalid"}`}
                placeholder="Enter title"
                {...register("description", { required: "Title is required" })}
              />
              <ErrorMessage
                errors={errors}
                name="description"
                render={({ message }) => (
                  <p className="invalid-feedback">{message}</p>
                )}
              />
            </div>
          </div>
          <div className="row mb-3">
            <label className="col-md-2 col-form-label">Time</label>
            <div className="col-md-10 d-flex">
              <div className="col me-2">
                <input
                  className={`form-control  ${
                    errors.startTime && "is-invalid"
                  }`}
                  placeholder="start time"
                  {...register("startTime", {
                    required: "start time is required",
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="startTime"
                  render={({ message }) => (
                    <p className="invalid-feedback">{message}</p>
                  )}
                />
              </div>
              <div className="col ms-2">
                <input
                  className={`form-control ${errors.endTime && "is-invalid"}`}
                  placeholder="end time"
                  {...register("endTime", { required: "end time is required" })}
                />
                <ErrorMessage
                  errors={errors}
                  name="endTime"
                  render={({ message }) => (
                    <p className="invalid-feedback">{message}</p>
                  )}
                />
              </div>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-10 col-md-2">
              <button className="btn btn-primary">SAVE</button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => reset()}
              >
                RESET
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => navigate(-1)}
              >
                CANCEL
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export { ServiceForm };
