import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import {
  usePostCompanyMutation,
  usePutCompanyMutation,
} from "../redux/company.api";
import { useAuthentication } from "../hooks";
import { useGetMappingByProfileIdQuery } from "../redux/profile-company-mappings.api";
import { useEffect } from "react";

export const CompanyForm = (props) => {
  const { user } = useAuthentication();
  const mappingQuery = useGetMappingByProfileIdQuery(user.Id);
  const schema = yup.object({
    title: yup.string().required("Company name is needed"),
    description: yup.string().required("Company description is needed"),
    address: yup.string().required("Company address is needed"),
  });

  const [postCompany, postQuery] = usePostCompanyMutation();
  const [putCompany, putQuery] = usePutCompanyMutation();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  function __post(data) {
    return postCompany(data)
      .unwrap()
      .then((response) => true)
      .catch((e) => false);
  }
  function __put(data) {
    return putCompany(data)
      .unwrap()
      .then((response) => true)
      .catch((e) => false);
  }
  const onSubmit = async (data) => {
    const status = mappingQuery.data ? await __put(data) : await __post(data);
    if (status) {
      navigate("/my-account");
    }
  };
  useEffect(() => {
    if (mappingQuery.data) {
      const fields = [
        "Id",
        "title",
        "description",
        "address",
        "email",
        "website",
        "createdDate",
      ];
      fields.map((f) => setValue(f, mappingQuery.data[f]));
    }
  }, [mappingQuery.isLoading]);
  return (
    <div className="card">
      <div className="card-body">
        {mappingQuery.data ? <h5>Edit</h5> : <h5>Register</h5>}
        <hr />
        <form className="my-4 mx-2" onSubmit={handleSubmit(onSubmit)}>
          {mappingQuery.data && (
            <>
              <input type="hidden" {...register("Id")} />
              <input type="hidden" {...register("createdDate")} />
            </>
          )}
          <div className="d-flex mb-3">
            <label className="col-form-label col-md-2">Title</label>
            <div className="col-md-10">
              <input
                className={`form-control ${errors.title && "is-invalid"}`}
                placeholder="enter company title"
                {...register("title", { required: true })}
              ></input>
              <p className="invalid-feedback d-block">
                {errors.title?.message}
              </p>
            </div>
          </div>
          <div className="d-flex mb-3">
            <label className="col-form-label col-md-2">Description</label>
            <div className="col-md-10">
              <textarea
                cols={5}
                className={`form-control ${errors.description && "is-invalid"}`}
                placeholder="enter company description"
                {...register("description", { required: true })}
              ></textarea>
              <p className="invalid-feedback d-block">
                {errors.description?.message}
              </p>
            </div>
          </div>
          <div className="d-flex mb-3">
            <label className="col-form-label col-md-2">Address</label>
            <div className="col-md-10">
              <textarea
                rows={4}
                className={`form-control ${errors.address && "is-invalid"}`}
                placeholder="enter company address"
                {...register("address", { required: true })}
              ></textarea>
              <p className="invalid-feedback d-block">
                {errors.address?.message}
              </p>
            </div>
          </div>

          <div className="d-flex mb-3">
            <label className="col-form-label col-md-2">Email</label>
            <div className="col-md-10">
              <input
                className={`form-control ${errors.email && "is-invalid"}`}
                placeholder="enter email Id"
                {...register("email", { required: true })}
              />
              <p className="invalid-feedback d-block">
                {errors.address?.email}
              </p>
            </div>
          </div>

          <div className="d-flex mb-3">
            <label className="col-form-label col-md-2">Website</label>
            <div className="col-md-10">
              <input
                className={`form-control ${errors.website && "is-invalid"}`}
                placeholder="enter email Id"
                {...register("website", { required: true })}
              />
              <p className="invalid-feedback d-block">
                {errors.address?.website}
              </p>
            </div>
          </div>

          <div className="d-flex mb-3">
            <div className="col-md-10 offset-md-2">
              <button className="btn btn-primary me-2">SAVE</button>
              <Link to={"/my-account"} className="btn btn-danger">
                CANCEL
              </Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
