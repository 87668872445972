import { useState, useEffect } from "react";
import { useGetChurchTitleImageQuery } from "../redux/churches.api";
const useChurchImageBase = (churchId) => {
  const { data, isLoading } = useGetChurchTitleImageQuery(churchId);
  const [imageBase, setImageBase] = useState();
  useEffect(() => {
    if (!isLoading && data) {
      const obj = JSON.parse(data);
      setImageBase(obj.src);
    }
  }, [isLoading]);
  return { imageBase };
};
export { useChurchImageBase };
