import { useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { useGetJobProfileByProfileIdQuery } from "../../redux/job-profile.api";
import { JobsProfiles } from "./job-profiles";
import { EmployeeWizard } from "./wizard";
import { JobsLiked } from "./jobs-liked";
import { JobsApplied } from "./jobs-applied";
const JobProfile = ({ user }) => {
  const query = useGetJobProfileByProfileIdQuery(user.Id);
  return (
    <div className="card">
      <div className="card-body">
        {!query.isLoading && query.error?.status == 404 ? (
          <>
            <h5>Creating a Job profile</h5>
            <div>
              <EmployeeWizard user={user} />
            </div>
          </>
        ) : (
          <Tabs defaultActiveKey={"profile"}>
            <Tab eventKey={"profile"} title="profile">
              <JobsProfiles user={user} />
            </Tab>
            <Tab eventKey={"liked"} title="Jobs Liked">
              <JobsLiked user={user} />
            </Tab>
            <Tab eventKey={"applied"} title="Jobs Applied">
              <JobsApplied user={user} />
            </Tab>
          </Tabs>
        )}
      </div>
    </div>
  );
};
export { JobProfile };
