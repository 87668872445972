import { Link } from "react-router-dom";


const AboutMatrimony = () => {
  return (
    <div className="">
      <section
        className="hero-wrap hero-wrap-2"
        style={{ backgroundImage: `url('assets/images/bg_3.jpg')` }}
      >
        <div className="overlay"></div>
        <div className="container">
          <div className="row no-gutters slider-text align-items-end justify-content-center">
            <div className="col-md-9 text-center mb-5">
              <p className="breadcrumbs">
                <span className="me-2">
                  <a href="index-2.html">
                    Home <i className="fa fa-chevron-right"></i>
                  </a>
                </span>
                <span>
                  Matrimony <i className="fa fa-chevron-right"></i>
                </span>
              </p>
              <h1 className="mb-0 bread">Matrimony</h1>
            </div>
          </div>
        </div>
      </section>

      <section className="ftco-section ftco-no-pt ftco-no-pb">
        <div className="container-xl">
          <div className="row g-lg-5">
            <div className="col-lg-6 d-flex align-items-stretch">
              <div
                className="img w-100"
                style={{
                  backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/about.jpg)`,
                }}
              ></div>
            </div>
            <div className="col-lg-6 heading-section d-flex align-items-center">
              <div className="mt-0 my-lg-5 py-5">
                <span className="subheading">About Union Corporation</span>
                <h2 className="mb-4">
                  More than 40M+ Trusted Our Financial &amp; Conusultation
                  Institution
                </h2>
                <p>
                  Far far away, behind the word mountains, far from the
                  countries Vokalia and Consonantia, there live the blind texts.
                  Separated they live in Bookmarksgrove right at the coast of
                  the Semantics, a large language ocean.
                </p>
                <p>
                  A small river named Duden flows by their place and supplies it
                  with the necessary regelialia. It is a paradisematic country,
                  in which roasted parts of sentences fly into your mouth.
                </p>
                <p className="mt-4">
                  <Link to="profiles" className="btn btn-primary py-3 px-4">
                    Learn More
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="ftco-section testimony-section bg-light">
        <div className="container-xl">
          <div className="row justify-content-center pb-4">
            <div className="col-lg-7 text-center heading-section">
              <span className="subheading">Testimonial</span>
              <h2 className="mb-5">Happy Customers</h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="carousel-testimony">
                <div className="item">
                  <div className="testimony-wrap">
                    <div className="icon d-flex align-items-center justify-content-center">
                      <span className="fa fa-quote-left"></span>
                    </div>
                    <div className="text">
                      <p className="mb-4">
                        Far far away, behind the word mountains, far from the
                        countries Vokalia and Consonantia, there live the blind
                        texts.
                      </p>
                      <div className="d-flex align-items-center">
                        <div
                          className="user-img"
                          style={{
                            backgroundImage: `url(assets/images/xperson_1.jpg.pagespeed.ic.P4pHl6glbj.jpg)`,
                          }}
                        ></div>
                        <div className="ps-3">
                          <p className="name">Roger Scott</p>
                          <span className="position">Marketing Manager</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="testimony-wrap">
                    <div className="icon d-flex align-items-center justify-content-center">
                      <span className="fa fa-quote-left"></span>
                    </div>
                    <div className="text">
                      <p className="mb-4">
                        Far far away, behind the word mountains, far from the
                        countries Vokalia and Consonantia, there live the blind
                        texts.
                      </p>
                      <div className="d-flex align-items-center">
                        <div
                          className="user-img"
                          style={{
                            backgroundImage: `url(assets/images/xperson_2.jpg.pagespeed.ic.yyrmjBH91b.jpg)`,
                          }}
                        ></div>
                        <div className="ps-3">
                          <p className="name">Roger Scott</p>
                          <span className="position">Marketing Manager</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="testimony-wrap">
                    <div className="icon d-flex align-items-center justify-content-center">
                      <span className="fa fa-quote-left"></span>
                    </div>
                    <div className="text">
                      <p className="mb-4">
                        Far far away, behind the word mountains, far from the
                        countries Vokalia and Consonantia, there live the blind
                        texts.
                      </p>
                      <div className="d-flex align-items-center">
                        <div
                          className="user-img"
                          style={{
                            backgroundImage: `url(assets/images/person_3.jpg)`,
                          }}
                        ></div>
                        <div className="ps-3">
                          <p className="name">Roger Scott</p>
                          <span className="position">Marketing Manager</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="testimony-wrap">
                    <div className="icon d-flex align-items-center justify-content-center">
                      <span className="fa fa-quote-left"></span>
                    </div>
                    <div className="text">
                      <p className="mb-4">
                        Far far away, behind the word mountains, far from the
                        countries Vokalia and Consonantia, there live the blind
                        texts.
                      </p>
                      <div className="d-flex align-items-center">
                        <div
                          className="user-img"
                          style={{
                            backgroundImage: `url(assets/images/xperson_4.jpg.pagespeed.ic.sgz97bCjv4.jpg)`,
                          }}
                        ></div>
                        <div className="ps-3">
                          <p className="name">Roger Scott</p>
                          <span className="position">Marketing Manager</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="item">
                  <div className="testimony-wrap">
                    <div className="icon d-flex align-items-center justify-content-center">
                      <span className="fa fa-quote-left"></span>
                    </div>
                    <div className="text">
                      <p className="mb-4">
                        Far far away, behind the word mountains, far from the
                        countries Vokalia and Consonantia, there live the blind
                        texts.
                      </p>
                      <div className="d-flex align-items-center">
                        <div
                          className="user-img"
                          style={{
                            backgroundImage: `url(assets/images/xperson_2.jpg.pagespeed.ic.yyrmjBH91b.jpg)`,
                          }}
                        ></div>
                        <div className="ps-3">
                          <p className="name">Roger Scott</p>
                          <span className="position">Marketing Manager</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      
    </div>
  );
};

export { AboutMatrimony };
