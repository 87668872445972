import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiBaseUrl } from "../_constants";

export const churchEventsApi = createApi({
  reducerPath: "churchEventsApi",
  baseQuery: fetchBaseQuery({ baseUrl: apiBaseUrl }),
  tagTypes: ["LIST"],
  endpoints: (builder) => ({
    getAllEvents: builder.query({
      query: () => `/churchEvents/index.php`,
      providesTags: ["LIST"],
    }),
    getChurchEvents: builder.query({
      query: (churchId) => `/churchEvents/index.php?churchId=${churchId}`,
      providesTags: ["LIST"],
    }),
    getEvent: builder.query({
      query: (id) => `/churchEvents/details.php?id=${id}`,
      providesTags: ["LIST"],
    }),

    postChurchEvent: builder.mutation({
      query: (obj) => ({
        url: "/churchEvents/index.php",
        method: "POST",
        body: obj,
      }),
      invalidatesTags: ["LIST"],
    }),

    putChurchEvent: builder.mutation({
      query: (obj) => ({
        url: "/churchEvents/index.php",
        method: "PUT",
        body: obj,
      }),
      invalidatesTags: ["LIST"],
    }),
  }),
});

export const {
  useGetAllEventsQuery,
  useGetChurchEventsQuery,
  useGetEventQuery,
  usePostChurchEventMutation,
  usePutChurchEventMutation,
} = churchEventsApi;
