import { useState, useEffect } from "react";
import { useGetImageQuery } from "../redux/company.api";
const useImageBase = (id) => {
  const { data, isLoading } = useGetImageQuery(id);
  const [imageBase, setImageBase] = useState();
  useEffect(() => {
    if (!isLoading && data) {
      const obj = JSON.parse(data);
      setImageBase(obj.src);
    }
  }, [isLoading]);
  return { imageBase };
};
export { useImageBase };
