import { useSelector } from "react-redux";

const Summary = (props, ref) => {
  const { steps, currentStep } = useSelector((state) => state.churchWizard);
  return (
    <div>
      form summary <hr /> {JSON.stringify(steps)}
    </div>
  );
};

export { Summary };
