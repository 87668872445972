import { Routes, Route, useNavigate, useParams } from "react-router-dom";
import * as Icons from "react-bootstrap-icons";
import { useGetAllProfilesQuery } from "../redux/profile.api";
import moment from "moment";
import { useEffect, useState } from "react";
import { Conversation } from "./conversation";

const ProfileImage = ({ user }) => {
  var intials;
  var fullName = `${user.firstName} ${user.lastName}`;
  intials = fullName
    .split(" ")
    .map((name) => name[0])
    .join("")
    .toUpperCase();

  return (
    <>
      {user.profileImage ? (
        <img
          src={user.profileImage}
          alt=""
          style={{ width: 100, height: 100, borderRadius: "50%" }}
        />
      ) : (
        <div className="d-flex">
          <div
            style={{
              fontSize: 18,
              padding: 10,
              background: "#336699",
              color: "#fff",
              borderRadius: "50%",
              width: 50,
              height: 50,
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
          >
            {intials}
          </div>
        </div>
      )}
    </>
  );
};

const SearchComponent = ({ onChange }) => {
  return (
    <div className="mb-4 d-flex justiy-content-center align-items-center ">
      <Icons.Search size={26} className="me-3" />
      <input
        className="form-control"
        placeholder="search"
        style={{ borderRadius: 6 }}
        onChange={onChange}
      />
    </div>
  );
};

const Contacts = () => {
  const query = useGetAllProfilesQuery({page:1, rows:100});

  const navigate = useNavigate();
  const { data, isLoading } = query;
  const [__data, setData] = useState([]);
  const handlesearch = (value) => {
    if (value) {
      const _data = data.filter(
        (f) =>
          f.firstName.toUpperCase().includes(value.toUpperCase()) ||
          f.lastName.toUpperCase().includes(value.toUpperCase())
      );
      setData(_data);
    } else {
      setData(data);
    }
  };

  useEffect(() => {
    if (!isLoading) {
      setData(data);
    }
  }, [isLoading]);

  return (
    <div className="row">
      <SearchComponent onChange={(arg) => handlesearch(arg.target.value)} /> 
      <div style={{ height: "65vh", overflow: "auto" }}>
        <ul className="list-unstyled">
          {__data &&
            __data.map((item) => (
              <li
                className={`row mb-3 is-invalid`}
                key={`contact-${item.Id}`}
                onClick={() => navigate(`/messages/${item.Id}`)}
              >
                <div className="d-flex">
                  <ProfileImage user={item} />
                  <div className="ms-3 w-100">
                    <div className="d-flex  justify-content-between w-100">
                      <div className="h6 p-0 m-0">{`${item.firstName} ${item.lastName}`}</div>
                      <div style={{ fontSize: 12 }}>
                        {moment(new Date()).format("hh:mm a")}
                      </div>
                    </div>
                    <span style={{ fontSize: 12 }}>
                      lastly received message
                    </span>
                  </div>
                </div>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

const MessageRoute = () => {
  return (
    <div className="ftco-section">
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <div className="card">
              <div className="card-body">
                <Routes>
                  <Route path="/" element={<Messages />} />
                  <Route path="/:userid" element={<Conversation />} />
                </Routes>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card">
              <div className="card-body">
                <Contacts />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Messages = () => {
  return (
    <div className="d-flex justify-content-center align-items-center">
      <p> Select contact to view the chats </p>
    </div>
  );
};
export { Messages, MessageRoute };
