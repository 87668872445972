import { createSlice } from "@reduxjs/toolkit";
import { messages } from "../data/messages.data";
const chatSlice = createSlice({
  name: "chatSlice",
  initialState: { items: [] },
  reducers: {
    sendMessage: (state, { type, payload }) => {
      state.items.push(payload);
    },
  },
});
export const { sendMessage } = chatSlice.actions;
export default chatSlice.reducer;
