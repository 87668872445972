const Messages = ({ user }) => {
  return (
    <div>
      <h4>this is your inbox</h4>
      jobs messages and notifications
    </div>
  );
};

export { Messages };
