import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  steps: [
    { id: 1, name: "basicInfo", data: null, isComplete: false },
    { id: 2, name: "cv-info", data: null, isComplete: false },
    // { id: 3, name: "socialMediaInfo", data: null, isComplete: false },
    // { id: 4, name: "loginCredentialsInfo", data: null, isComplete: false },
  ],
  currentStep: 1,
};

const jobProfileWizardSlice = createSlice({
  name: "jobProfileWizard",
  initialState,
  reducers: {
    updateStep: (state, { payload: { step, data } }) => {
      const __step = state.steps.find((f) => f.id == step);
      __step.isComplete = true;
      __step.data = data;
      const stepIndex = state.steps.findIndex((f) => f.id == step);
      state.steps.slice(stepIndex, 1, __step);
    },
    moveNext: (state) => {
      state.currentStep += 1;
    },
    moveBack: (state) => {
      state.currentStep -= 1;
    },
    reset: (state) => {
      state = initialState;
    },
  },
});

export const { updateStep, moveNext, moveBack, reset } =
  jobProfileWizardSlice.actions;
export default jobProfileWizardSlice.reducer;
