import { useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { useGetJobProfileByProfileIdQuery } from "../redux/job-profile.api";
import { useApplyJobMutation, useIsAppliedQuery } from "../redux/jobs.api";
const ApplyNow = ({ jobId, user }) => {
  const [selectedProfile, setSelectedProfile] = useState();
  const query = useIsAppliedQuery({ jobId, profileId: user.Id });
  const [applyJob, { isLoading }] = useApplyJobMutation();
  const jobProfileQuery = useGetJobProfileByProfileIdQuery(user.Id);
  const handleApplyClick = () => {
    if (selectedProfile == null) {
      alert("Select a Job profile");
      return;
    }
    if (user != null) {
      const entity = {
        jobId,
        profileId: user.Id,
        jobProfileId: selectedProfile.Id,
      };
      applyJob(entity)
        .unwrap()
        .then((res) => alert("you have applied successfully"))
        .catch((e) => alert("something went wrong, try again later"));
    }
  };
  return (
    <>
      {query.data == null ? (
        <div className="d-flex">
          <DropdownButton className="me-2"
            title={
              selectedProfile != null ? selectedProfile.profileTitle : "Select Profile"
            }
          >
            {jobProfileQuery.data?.map((item) => (
              <Dropdown.Item onClick={() => setSelectedProfile(item)}>
                {item.profileTitle}
              </Dropdown.Item>
            ))}
          </DropdownButton>
          <button
            className="btn btn-primary"
            onClick={handleApplyClick}
            disabled={isLoading}
          >
            Apply
          </button>
        </div>
      ) : (
        <button className="btn btn-primary" disabled>
          Applied
        </button>
      )}
    </>
  );
};

export { ApplyNow };
