import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorMessage } from "@hookform/error-message";
import * as yup from "yup";

import { Building, Columns, People } from "react-bootstrap-icons";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useValidateUserMutation } from "../redux/user.api";
import { useAuthentication } from "../hooks";
const Login = () => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const { user, setUserState } = useAuthentication();
  const validationSchema = yup.object({
    username: yup.string().required("Username is required"),
    password: yup.string().required("Password is required"),
  });
  const [validateUser, postQuery] = useValidateUserMutation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) });

  const ShowErrorMessage = ({ field }) => (
    <ErrorMessage
      errors={errors}
      name={field}
      render={({ message }) => <p className="invalid-feedback">{message}</p>}
    />
  );

  const onFormSubmit = async (data) => {
    try {
      validateUser(data)
        .unwrap()
        .then(async (res) => {
          setUserState(res);
          navigate("/my-account", { replace: true });
        })
        .catch((e) => alert(JSON.stringify(e)));
    } catch (err) {
      //console.error(err);
    }
  };

  return (
    <>
      <div className="ftco-section">
        <div className="container">
          {auth.error && (
            <div className="alert alert-danger">
              {JSON.stringify(auth.error)}
            </div>
          )}

          <div className="card">
            <div className="card-body d-flex">
              <div className="row col-md-12 gx-5">
                <div className="col-md-6 p-4 border-end">
                  <div className="">
                    <Building size={35} className="me-4" /> Companies
                  </div>
                  <div className="my-5">
                    <Columns size={35} className="me-4" /> Churches
                  </div>
                  <div className="">
                    <People size={35} className="me-4" /> Members
                  </div>
                </div>
                <div className="col-md-6 p-4">
                  <form onSubmit={handleSubmit(onFormSubmit)}>
                    <div className="row mb-3">
                      <label className="col-form-label col-md-2">
                        Username
                      </label>
                      <div className="col-md-10">
                        <input
                          className={`form-control ${
                            errors.username && "is-invalid"
                          }`}
                          placeholder="enter username"
                          {...register("username")}
                        ></input>
                        <ShowErrorMessage field={"username"} />
                      </div>
                    </div>

                    <div className="row mb-3">
                      <label className="col-form-label col-md-2">
                        Password
                      </label>
                      <div className="col-md-10">
                        <input
                          type={"password"}
                          className={`form-control ${
                            errors.password && "is-invalid"
                          }`}
                          placeholder="enter password"
                          {...register("password")}
                        ></input>
                        <ShowErrorMessage field={"password"} />
                      </div>
                    </div>

                    <div className="row mb-3">
                      <div className="col-md-12">
                        <button
                          className="btn btn-primary me-3"
                          disabled={auth.isLoading}
                        >
                          LOGIN
                        </button>
                        <Link className="btn btn-primary me-3" to={"/signup"}>
                          SIGN UP
                        </Link>
                        <Link
                          className="btn btn-primary me-3"
                          to={"/forgot-password"}
                        >
                          FORGOT PASSWORD
                        </Link>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { Login };
