import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ErrorMessage } from "@hookform/error-message";

const ForgotPassword = (props) => {
  const validationSchema = yup.object({
    username: yup.string().required("username"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) });

  const onFormSubmit = (data) => {
    console.log(JSON.stringify(data));
  };

  return (
    <div className="ftco-section">
      <div className="container">
        <div className="card">
          <div className="card-body">
            <form onSubmit={handleSubmit(onFormSubmit)}>
              <div className="row mb-3">
                <label className="col-form-label">Username or email</label>
                <div className="col-md-10">
                  <input
                    className={`form-control ${
                      errors.username && "is-invalid"
                    }`}
                    placeholder="enter your email or username."
                    {...register("username")}
                  ></input>
                  <ErrorMessage
                    errors={errors}
                    name={"username"}
                    render={({ message }) => (
                      <p className="invalid-feedback">{message}</p>
                    )}
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-md-12">
                  <button className="btn btn-primary">Submit</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export { ForgotPassword };
