import { useState } from "react";
import { Trash3 } from "react-bootstrap-icons";
import { ModalComponent } from "../components/modal.component";
import { RowIndex } from "../components/rowIndex.component";
import { useGetAllMatrimonyQuery } from "../redux/matrimony.api";

const Matrimony = () => {
  const [selectedItem, setSelectedItem] = useState();
  const [showDialog, setshowDialog] = useState();
  const { data, isLoading, error, isError } = useGetAllMatrimonyQuery();
  return (
    <>
      <div className="card">
        <div className="card-body">
          <table className="table">
            <thead>
              <tr>
                <th>#</th>
                <th>Name</th>
                <th>Date</th>
              </tr>
            </thead>
            <tbody>
              {!isLoading &&
                data &&
                data.map((item, index) => (
                  <tr>
                    <td>
                      <RowIndex index={index} />
                    </td>
                    <td>
                      {item.title} {item.firstName} {item.lastName}
                    </td>
                    <td>{JSON.stringify(item.createdDate)}</td>
                    <td>
                      <Trash3
                        color="red"
                        onClick={() => {
                          setSelectedItem(item);
                          setshowDialog(true);
                        }}
                      />
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
      <ModalComponent
        showDialog={showDialog}
        title={"DELETE"}
        message={`${selectedItem?.title} ${selectedItem?.firstName} ${selectedItem?.lastName}`}
        onCancelClick={() => setshowDialog(false)}
        onOkClick={() => alert("delete")}
      />
    </>
  );
};
export { Matrimony };
