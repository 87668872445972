import { useNavigate } from "react-router-dom";
import { useGetAllChurchesQuery } from "../redux/churches.api";
import { useChurchImageBase } from "../hooks/church-image.hook";

// church display component

const ChurchItem = ({ data, onItemClicked }) => {
  const { imageBase } = useChurchImageBase(data.Id);
  return (
    <div className="col-md-6 col-lg-3">
      <div className="staff">
        <div className="img-wrap d-flex align-items-stretch">
          <div
            onClick={() => onItemClicked(data.Id)}
            className="img align-self-stretch"
            style={{
              backgroundImage: `url(${imageBase})`,
            }}
          ></div>
        </div>
        <div className="text text-center">
          <h3 className="mb-2">{data.title} </h3>
          <span className="position mb-2">President &amp; CEO</span>
          <div className="faded">
            <ul className="ftco-social text-center">
              <li className="ftco-animate">
                <a
                  href="#"
                  className="d-flex align-items-center justify-content-center"
                >
                  <span className="fa fa-twitter"></span>
                </a>
              </li>
              <li className="ftco-animate">
                <a
                  href="#"
                  className="d-flex align-items-center justify-content-center"
                >
                  <span className="fa fa-facebook"></span>
                </a>
              </li>
              <li className="ftco-animate">
                <a
                  href="#"
                  className="d-flex align-items-center justify-content-center"
                >
                  <span className="fa fa-google"></span>
                </a>
              </li>
              <li className="ftco-animate">
                <a
                  href="#"
                  className="d-flex align-items-center justify-content-center"
                >
                  <span className="fa fa-instagram"></span>
                </a>
              </li>
            </ul>
            <p>
              I am an ambitious workaholic, but apart from that, pretty simple
              person.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export const ChurchMaster = () => {
  const { data, isLoading, isSuccess, refetch } = useGetAllChurchesQuery({page:1, rows:100});

  const navigate = useNavigate();

  return (
    <div className="ftco-section-1">
      <div className="container-1">
        <div className="row">
          {!isLoading &&
            isSuccess &&
            data.map((item, index) => (
              <ChurchItem
                key={`church-${item.Id}`}
                data={item}
                onItemClicked={(id) => navigate(`${id}`)}
              />
            ))}
        </div>
      </div>
    </div>
  );
};
