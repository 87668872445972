import { NavLink, Outlet, Routes, Route } from "react-router-dom";
import { Roles } from "./roles";
import { Users } from "./users";

const Dashboard = () => (
  <div className="d-flex">
    <div className="card">
      <div className="card-body">
        <NavLink to="configs">Configs</NavLink>
      </div>
    </div>

    <div className="card">
      <div className="card-body">
        <NavLink to="roles">Roles</NavLink>
      </div>
    </div>

    <div className="card">
      <div className="card-body">
        <NavLink to="users">Users</NavLink>
      </div>
    </div>
  </div>
);

const Settings = () => {
  return (
    <div className="card">
      <div className="card-body">
        <Routes>
          <Route exact path="/" element={<Dashboard />} />
          <Route exact path="/roles" element={<Roles />} />
          <Route exact path="/users" element={<Users />} />
        </Routes>

      </div>
    </div>
  );
};

export { Settings };
