import { Link } from "react-router-dom";
import { useSubscriptions } from "../hooks";

const Menus = ({ items }) => {
  return (
    <ul className="categories me-md-4">
      {items.map((item) => (
        <li key={item.url}>
          <Link to={item.url}>
            <span className="ion-ios-arrow-round-forward"></span> {item.title}
          </Link>
        </li>
      ))}
    </ul>
  );
};

const Sidebar = ({ profileId, userType }) => {
  const { response } = useSubscriptions(profileId);
  return (
    <>
      <div className="sidebar-box bg-light rounded collapse">
        <form action="#" className="search-form">
          <div className="form-group">
            <span className="icon fa fa-search"></span>
            <input
              type="text"
              className="form-control"
              placeholder="Search..."
            ></input>
          </div>
        </form>
      </div>
      <div className="sidebar-box">
        <h3>{userType}</h3>
        <ul className="categories me-md-4">
          {response && response.menus && <Menus items={response.menus} />}
        </ul>
      </div>
      <div className="sidebar-box collapse">
        <h3>Recent Blog</h3>
        <div className="block-21 mb-4 d-flex">
          <a
            className="blog-img me-4"
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/ximage_1.jpg.pagespeed.ic.hLNkZmeXOJ.jpg)`,
            }}
          ></a>
          <div className="text">
            <h3 className="heading">
              <a href="#">Incorporation is A Big Milestone for Your Business</a>
            </h3>
            <div className="meta">
              <div>
                <a href="#">
                  <span className="fa fa-calendar"></span> January 27, 2021
                </a>
              </div>
              <div>
                <a href="#">
                  <span className="fa fa-user"></span> Admin
                </a>
              </div>
              <div>
                <a href="#">
                  <span className="fa fa-comment"></span> 19
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="block-21 mb-4 d-flex">
          <a
            className="blog-img me-4"
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/ximage_2.jpg.pagespeed.ic.mQGyn1P_Li.jpg)`,
            }}
          ></a>
          <div className="text">
            <h3 className="heading">
              <a href="#">Incorporation is A Big Milestone for Your Business</a>
            </h3>
            <div className="meta">
              <div>
                <a href="#">
                  <span className="fa fa-calendar"></span> January 27, 2021
                </a>
              </div>
              <div>
                <a href="#">
                  <span className="fa fa-user"></span> Admin
                </a>
              </div>
              <div>
                <a href="#">
                  <span className="fa fa-comment"></span> 19
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="block-21 mb-4 d-flex">
          <a
            className="blog-img me-4"
            style={{
              backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/image_3.jpg)`,
            }}
          ></a>
          <div className="text">
            <h3 className="heading">
              <a href="#">Incorporation is A Big Milestone for Your Business</a>
            </h3>
            <div className="meta">
              <div>
                <a href="#">
                  <span className="fa fa-calendar"></span> January 27, 2021
                </a>
              </div>
              <div>
                <a href="#">
                  <span className="fa fa-user"></span> Admin
                </a>
              </div>
              <div>
                <a href="#">
                  <span className="fa fa-comment"></span> 19
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="sidebar-box">
        <h3>Tag Cloud</h3>
        <div className="tagcloud">
          <a href="#" className="tag-cloud-link">
            finance
          </a>
          <a href="#" className="tag-cloud-link">
            fund
          </a>
          <a href="#" className="tag-cloud-link">
            consult
          </a>
          <a href="#" className="tag-cloud-link">
            business
          </a>
          <a href="#" className="tag-cloud-link">
            funding
          </a>
          <a href="#" className="tag-cloud-link">
            money
          </a>
          <a href="#" className="tag-cloud-link">
            invest
          </a>
          <a href="#" className="tag-cloud-link">
            plan
          </a>
          <a href="#" className="tag-cloud-link">
            tax
          </a>
        </div>
      </div>
      <div className="sidebar-box">
        <h3>Paragraph</h3>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ducimus
          itaque, autem necessitatibus voluptate quod mollitia delectus aut,
          sunt placeat nam vero culpa sapiente consectetur similique, inventore
          eos fugit cupiditate numquam!
        </p>
      </div>
    </>
  );
};

export { Sidebar };
