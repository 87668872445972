import { Modal, Button } from "react-bootstrap";

const ModalComponent = ({
  showDialog,
  title,
  message = "Are you sure to delete this?",
  data,
  onOkClick,
  okText = "Confirm",
  onCancelClick,
}) => {
  return (
    <Modal show={showDialog} onHide={onCancelClick}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <h5>{message}</h5>
          <p>{data}</p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancelClick}>
          Cancel
        </Button>
        <Button variant="primary" onClick={onOkClick}>
          {okText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export { ModalComponent };
