import { Link, Outlet, Route, Routes } from "react-router-dom";

import { EventsForm } from "./event.form";
import { EventsList } from "./event.master";

const ChurchEvents = ({ churchId }) => {
  return (
    <>
      <Routes>
        <Route path="/" element={<EventsList churchId={churchId} />} />
        <Route path="/create" element={<EventsForm churchId={churchId} />} />
        <Route path="/edit/:id" element={<EventsForm churchId={churchId} />} />
      </Routes>
      <Outlet />
    </>
  );
};

export { ChurchEvents };
