import { forwardRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorMessage } from "@hookform/error-message";

const StepFour = (props, ref) => {
  const schema = yup.object().shape({
    //email: yup.string().required(),
    password: yup.string().required(),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("password"), null], "password does not match")
      .required(),
  });

  const {
    setValue,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const { data: stepData } = useSelector(
    (state) => state.churchWizard.steps[1]
  );

  useEffect(() => {
    setValue("username", stepData["email"]);
  }, []);

  const onFormSubmit = (data) => {
    props.onStepSubmit({ ...data });
  };
  return (
    <div>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <div className="row">
          <label className="col-form-label col-md-2"> Username </label>
          <div className="col-md-10">
            <input
              type="text"
              className="form-control"
              disabled
              {...register("username", { required: true })}
            ></input>
          </div>
        </div>
        <div className="row">
          <label className="col-form-label col-md-2"> Password </label>
          <div className="col-md-10">
            <input
              type="text"
              className={`form-control ${errors.password && "is-invalid"}`}
              {...register("password", {
                required: "Enter your required password",
              })}
            ></input>
            <ErrorMessage
              errors={errors}
              name="password"
              render={({ message }) => (
                <p className="invalid-feedback">{message}</p>
              )}
            />
          </div>
        </div>
        <div className="row">
          <label className="col-form-label col-md-2"> Confirm Password </label>
          <div className="col-md-10">
            <input
              type="text"
              className={`form-control ${
                errors.confirmPassword && "is-invalid"
              }`}
              {...register("confirmPassword", {
                required: "Retype your password",
              })}
            ></input>
            <ErrorMessage
              errors={errors}
              name="confirmPassword"
              render={({ message }) => (
                <p className="invalid-feedback">{message}</p>
              )}
            />
          </div>
        </div>
        <div className="row">
          <button type="submit" ref={ref} className="collapse">
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default forwardRef(StepFour);
