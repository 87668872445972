import { useForm } from "react-hook-form";
const ResponseForm = ({ onFormSubmit }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = (e) => {
    onFormSubmit(e);
    reset();
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="d-flex">
        <input
          className={`form-control ${errors.response && "is-invalid"} `}
          {...register("response", { required: "required" })}
        />
        <button type="submit" className="btn btn-primary ms-2">
          Send
        </button>
      </div>
    </form>
  );
};
export { ResponseForm };
