import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiBaseUrl } from "../_constants";

export const churchServicesApi = createApi({
  reducerPath: "churchServiceApi",
  baseQuery: fetchBaseQuery({ baseUrl: apiBaseUrl }),
  tagTypes: ["LIST"],
  endpoints: (builder) => ({

    getAllServices: builder.query({
      query: () => `/churchServices/index.php`,
      providesTags: ["LIST"],
    }),

    getChurchServices: builder.query({
      query: (churchId) => `/churchServices/index.php?churchId=${churchId}`,
      providesTags: ["LIST"],
    }),

    getService: builder.query({
      query: (id) => `/churchServices/details.php?id=${id}`,
      providesTags: ["LIST"],
    }),

    postService: builder.mutation({
      query: (obj) => ({
        url: "/churchServices/index.php",
        method: "PUT",
        body: obj,
      }),
      invalidatesTags: ["LIST"],
    }),
    
    putService: builder.mutation({
      query: (obj) => ({
        url: "/churchServices/index.php",
        method: "PUT",
        body: obj,
      }),
      invalidatesTags: ["LIST"],
    }),
  
  }),
});

export const {
  useGetAllServicesQuery,
  useGetChurchServicesQuery,
  useGetServiceQuery,
  usePostServiceMutation,
  usePutServiceMutation,
} = churchServicesApi;
