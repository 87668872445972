import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiBaseUrl } from "../_constants";

export const dashboardApi = createApi({
  reducerPath: "dashboardApi",
  baseQuery: fetchBaseQuery({ baseUrl: apiBaseUrl }),
  tagTypes: ["list"],
  endpoints: (builder) => ({
    getStats: builder.query({
      query: () => `/dashboard/stats.php`,
      providesTags: ["list"],
    }),
  }),
});

export const { useGetStatsQuery } = dashboardApi;
