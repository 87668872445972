import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiBaseUrl } from "../_constants";

export const profileMappingApi = createApi({
  reducerPath: "profileMappingApi",
  baseQuery: fetchBaseQuery({ baseUrl: apiBaseUrl }),
  endpoints: (builder) => ({
    getAllMappings: builder.query({
      query: `/profile-mapping/index.php`,
    }),
    getMapping: builder.query({
      query: (id) => `/profile-mapping/index.php?id=${id}`,
    }),
    getMappingByProfileId: builder.query({
      query: (id) => `/profile-mapping/index.php?profileId=${id}`,
    }),
    getMappingByUserId: builder.query({
      query: (id) => `/profile-mapping/index.php?userId=${id}`,
    }),
    postMapping: builder.mutation({
      query: (obj) => ({
        url: "/profile-mapping/index.php",
        method: "POST",
        body: obj,
      }),
    }),
  }),
});

export const {
  useGetAllMappingsQuery,
  useGetMappingQuery,
  useGetMappingByProfileIdQuery,
  useGetMappingByUserIdQuery,
  usePostMappingMutation,
} = profileMappingApi;
