const news = [
  {
    id: 1,
    title: "European refuges may be trapped in cold",
    author: "marwa",
    image:
      "https://elmanawy.info/demo/maro-news/images/uploads/gridpost-tab-2.jpg",
  },
  {
    id: 2,
    title: "European refuges may be trapped in cold",
    author: "marwa",
    image:
      "https://elmanawy.info/demo/maro-news/images/uploads/gridpost-tab-3.jpg",
  },
  {
    id: 3,
    title: "European refuges may be trapped in cold",
    author: "marwa",
    image:
      "https://elmanawy.info/demo/maro-news/images/uploads/gridpost-tab-4.jpg",
  },
  {
    id: 4,
    title: "European refuges may be trapped in cold",
    author: "marwa",
    image:
      "https://elmanawy.info/demo/maro-news/images/uploads/gridpost-tab-5.jpg",
  },
  {
    id: 5,
    title: "European refuges may be trapped in cold",
    author: "marwa",
    image:
      "https://elmanawy.info/demo/maro-news/images/uploads/gridpost-tab-6.jpg",
  },
  {
    id: 6,
    title: "European refuges may be trapped in cold",
    author: "marwa",
    image:
      "https://elmanawy.info/demo/maro-news/images/uploads/gridpost-tab-7.jpg",
  },
];

export { news };
