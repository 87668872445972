import { useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import {
  useGetChurchProfileQuery,
  useGetMappingsCountQuery,
} from "../../redux/church-mapping.api";
import {
  useDeleteChurchMutation,
  useGetChurchQuery,
} from "../../redux/churches.api";

import { ModalComponent, ChurchImageComponent } from "../../components";
import { Tab, Tabs } from "react-bootstrap";

const ChurchDetails = () => {
  const { id } = useParams();
  const { data, isLoading, isError } = useGetChurchQuery(id);
  const profileQuery = useGetChurchProfileQuery(id);
  const countQuery = useGetMappingsCountQuery(id);
  const [showDialog, setShowDialog] = useState(false);
  const [selectedItem, setSselectedItem] = useState();
  const navigate = useNavigate();
  const [deleteChurch, request] = useDeleteChurchMutation();
  const handleDelete = () => {
    deleteChurch(id)
      .unwrap()
      .then((res) => {
        setShowDialog(false);
        navigate(-1);
      })
      .catch((e) => alert(JSON.stringify(e)));
  };
  return (
    <div className="card">
      <div className="card-body">
        {!isLoading && data && (
          <div className="row">
            <div className="col">
              <ChurchImageComponent churchId={id} />
            </div>
            <div className="col-md-8">
              <h4>{data.title}</h4>
              <Link>
                {!profileQuery.isLoading && profileQuery.data && (
                  <>
                    {profileQuery.data["title"]}
                    {profileQuery.data["firstName"]}
                    {profileQuery.data["lastName"]}
                  </>
                )}
              </Link>
              <p>Members : {!countQuery.isLoading && <>{countQuery.data}</>}</p>
              <div>
                <div className="" style={{ flexDirection: "row" }}>
                  <button
                    className="btn btn-primary me-2"
                    onClick={() => navigate(-1)}
                  >
                    Back
                  </button>
                  <button
                    className="btn btn-danger ms-2"
                    disabled={request.isLoading}
                    onClick={() => setShowDialog(true)}
                  >
                    Delete
                  </button>
                </div>
              </div>
              {/* <Tabs>
                <Tab title="Contact"></Tab>
                <Tab title="Members"></Tab>
                <Tab title="Events"></Tab>
                <Tab title=""></Tab>
              </Tabs> */}
              <ModalComponent
                showDialog={showDialog}
                title={"DELETE"}
                message={data.title}
                onCancelClick={() => setShowDialog(false)}
                onOkClick={handleDelete}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export { ChurchDetails };
