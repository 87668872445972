import { Link, NavLink } from "react-router-dom";
import * as Icons from "react-bootstrap-icons";

const FooterComponent = () => {
  return (
    <>
      <div className="bg-dark text-white d-none">
        <div className="container py-5">
          <div className="row">
            <div className="col">
              <h4>Believers Network</h4>
              <p>
                Seddo eiusmod tempor incididunt ut labore et dolore magna
                aliqua. Conse ctetur pisicin elit, sed do eiusmod tempor.
              </p>
            </div>
            <div className="col">
              <ul>
                <li className="nav-item">
                  <NavLink
                    className={`nav-link ${({ isActive }) =>
                      isActive ? "active" : ""}`}
                    to="configs"
                  >
                    Configs
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={`nav-link ${({ isActive }) =>
                      isActive ? "active" : ""}`}
                    to="profiles"
                  >
                    Profile
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={`nav-link ${({ isActive }) =>
                      isActive ? "active" : ""}`}
                    to="jobs"
                  >
                    Jobs
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={`nav-link ${({ isActive }) =>
                      isActive ? "active" : ""}`}
                    to="company"
                  >
                    Company
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={`nav-link ${({ isActive }) =>
                      isActive ? "active" : ""}`}
                    to="matrimony"
                  >
                    Matrimony
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className={`nav-link ${({ isActive }) =>
                      isActive ? "active" : ""}`}
                    to="churches"
                  >
                    Churches
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="col">
              <ul className="social-icons">
                <li>
                  <Icons.Facebook />
                  {/* <i class='bi bi-facebook'></i> */}
                </li>
                <li>
                  <Icons.Twitter />
                  {/* <i class='bi bi-twitter'></i> */}
                </li>
                <li>
                  <Icons.Youtube />
                  {/* <i class='bi bi-youtube'></i> */}
                </li>
                <li>
                  <Icons.Skype />
                  {/* <i class='bi bi-skype'></i> */}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <footer
        className="ftco-footer img"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/slider-image-1.jpg)`,
        }}
      >
        <div className="overlay"></div>
        <div className="container-xl">
          <div className="row mb-5 justify-content-between">
            <div className="col-md-6 col-lg">
              <div className="ftco-footer-widget mb-4">
                <h2 className="ftco-heading-2 logo d-flex">
                  <a className="navbar-brand" href="index-2.html">
                    Union<small>corp.</small>
                    <span>Finance &amp; Consultation</span>
                  </a>
                </h2>
                <p>
                  A small river named Duden flows by their place and supplies it
                  with the necessary regelialia.
                </p>
                <ul className="ftco-footer-social mt-4">
                  <li>
                    <a href="#">
                      <span className="fa fa-twitter"></span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span className="fa fa-facebook"></span>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span className="fa fa-instagram"></span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6 col-lg-4">
              <div className="ftco-footer-widget mb-4">
                <h2 className="ftco-heading-2">Services</h2>
                <div className="d-md-flex">
                  <ul className="list-unstyled w-100">
                    <li>
                      <NavLink to={"/jobs"}>
                        <span className="ion ion-ios-arrow-round-forward me-2"></span>
                        Jobs
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={"/matrimony"}>
                        <span className="ion ion-ios-arrow-round-forward me-2"></span>
                        Matrimony
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={"churches"}>
                        <span className="ion ion-ios-arrow-round-forward me-2"></span>
                        Churches
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={"/configs"}>
                        <span className="ion ion-ios-arrow-round-forward me-2"></span>
                        Configs
                      </NavLink>
                    </li>
                  </ul>
                  <ul className="list-unstyled w-100">
                    <li>
                      <Link to={"/prayer-request"}>
                        <span className="ion ion-ios-arrow-round-forward me-2"></span>
                        Prayer Request
                      </Link>
                    </li>
                    <li>
                      <Link to={"/company"}>
                        <span className="ion ion-ios-arrow-round-forward me-2"></span>
                        Company
                      </Link>
                    </li>
                    <li>
                      <NavLink to={'/profiles'}>
                        <span className="ion ion-ios-arrow-round-forward me-2"></span>
                        Profiles
                      </NavLink>
                    </li>
                    <li>
                      <a href="#">
                        <span className="ion ion-ios-arrow-round-forward me-2"></span>
                        Technology Consulting
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg">
              <div className="ftco-footer-widget mb-4">
                <h2 className="ftco-heading-2">Recent Posts</h2>
                <div className="block-21 mb-4 d-flex">
                  <a
                    href="#3"
                    className="blog-img img rounded"
                    style={{
                      backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/ximage_1.jpg.pagespeed.ic.hLNkZmeXOJ.jpg)`,
                    }}
                  ></a>
                  <div className="text">
                    <div className="meta">
                      <div>
                        <a href="#">
                          <span className="fa fa-calendar"></span> Jan. 27, 2021
                        </a>
                      </div>
                      <div>
                        <a href="#">
                          <span className="fa fa-user"></span> Admin
                        </a>
                      </div>
                    </div>
                    <h3 className="heading">
                      <a href="#">
                        Incorporation is a big milestone for business
                      </a>
                    </h3>
                  </div>
                </div>
                <div className="block-21 mb-4 d-flex">
                  <a
                    href="#4"
                    className="blog-img img rounded"
                    style={{
                      backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/ximage_2.jpg.pagespeed.ic.mQGyn1P_Li.jpg)`,
                    }}
                  ></a>
                  <div className="text">
                    <div className="meta">
                      <div>
                        <a href="#">
                          <span className="fa fa-calendar"></span> Jan. 27, 2021
                        </a>
                      </div>
                      <div>
                        <a href="#">
                          <span className="fa fa-user"></span> Admin
                        </a>
                      </div>
                    </div>
                    <h3 className="heading">
                      <a href="#">
                        Incorporation is a big milestone for business
                      </a>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg">
              <div className="ftco-footer-widget mb-4">
                <h2 className="ftco-heading-2">Have a Questions?</h2>
                <div className="block-23 mb-3">
                  <ul>
                    <li>
                      <span className="icon fa fa-map marker"></span>
                      <span className="text">
                        203 Fake St. Mountain View, San Francisco, California,
                        USA
                      </span>
                    </li>
                    <li>
                      <a href="#">
                        <span className="icon fa fa-phone"></span>
                        <span className="text">+2 392 3929 210</span>
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <span className="icon fa fa-paper-plane"></span>
                        <span className="text">
                          <span
                            className="__cf_email__"
                            data-cfemail="88e1e6eee7c8f1e7fdfaece7e5e9e1e6a6ebe7e5"
                          >
                            [email&#160;protected]
                          </span>
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid px-0 py-5 bg-darken">
          <div className="container-xl">
            <div className="row">
              <div className="col-md-12 text-center">
                <p className="mb-0" style={{ fontSize: "14px" }}>
                  Copyright &copy;
                  <script
                    data-cfasync="false"
                    src="../../cdn-cgi/scripts/5c5dd728/cloudflare-static/email-decode.min.js"
                  ></script>
                  <script>document.write(new Date().getFullYear());</script> All
                  rights reserved | This template is made with{" "}
                  <i
                    className="fa fa-heart color-danger"
                    aria-hidden="true"
                  ></i>{" "}
                  by{" "}
                  <a
                    href="https://colorlib.com/"
                    target="_blank"
                    rel="nofollow noopener"
                  >
                    Colorlib
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export { FooterComponent };
