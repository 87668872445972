import { Route, Routes } from "react-router-dom";
import { LoginDetails } from "./login.details";
import { LoginList } from "./login.master";

const LoginRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<LoginList />} />
      <Route path="/:id" element={<LoginDetails />} />
    </Routes>
  );
};
export { LoginRoutes };
