import { At, Calendar2, Check2, XLg } from "react-bootstrap-icons";
import { ProfileImage } from "../../components";
import { useGetAllProfilesQuery } from "../../redux/profile.api";
const ProfileRequest = ({ user }) => {
  const query = useGetAllProfilesQuery({});
  return (
    <div>
      {query.data &&
        query.data.map((item) => (
          <div className="mb-3 d-flex">
            <ProfileImage user={item} style={"x-small rounded"} />
            <div className="ms-3 col">
              <h5>{`${item.title} ${item.firstName} ${item.lastName}`}</h5>
              <p className="m-0">
                <At className="me-2" /> {item.email}
              </p>
              <p className="m-0">
                <Calendar2 className="me-2" />
                {JSON.stringify(item.createdDate)}
              </p>
            </div>
            <div className="d-flex align-items-center">
              <button
                className="btn btn-primary btn-sm me-1"
                onClick={() =>
                  alert(`accept request from ${item.Id} by ${user.Id}`)
                }
              >
                Accept
              </button>
              <button className="btn btn-primary btn-sm ms-1">Reject</button>
              <button className="btn btn-primary btn-sm ms-1">Hide</button>
            </div>
          </div>
        ))}
    </div>
  );
};
export { ProfileRequest };
