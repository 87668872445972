import { Link } from "react-router-dom";
import moment from "moment";
import { Pencil, Trash, PlusCircle } from "react-bootstrap-icons";
import { useGetChurchServicesQuery } from "../../redux/church-service.api";

const TimeComponent = ({ time }) => {
  // {moment(item.endTime).format("hh:mm")}
  return <div>{time}</div>;
};

const ServiceList = ({ churchId, options }) => {
  const { data, isLoading } = useGetChurchServicesQuery(churchId);

  return (
    <div className="card">
      <div className="card-header">
        <div className="d-flex justify-content-between align-items-center">
          <h4>Services</h4>
          <Link className="btn btn-primary btn-sm" to={"create"}>
            ADD
          </Link>
        </div>
      </div>
      <div className="card-body">
        <div>
          <table className="table">
            <thead>
              <tr>
                <th>#</th>
                <th>Title</th>
                <th>Start</th>
                <th>End</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((item, index) => (
                  <tr key={`item-${item.Id}`}>
                    <td>{index + 1}</td>
                    <td>{item.description}</td>
                    <td>
                      <TimeComponent time={item.startTime} />
                    </td>
                    <td>
                      <TimeComponent time={item.endTime} />
                    </td>
                    {options && (
                      <td>
                        <Link to={`edit/${item.Id}`}>
                          <Pencil color="blue" />
                        </Link>
                        <Trash color="red" className="mx-3" />
                      </td>
                    )}
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export { ServiceList };
