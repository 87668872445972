import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiBaseUrl } from "../_constants";

export const usersApi = createApi({
  reducerPath: "usersApi",
  baseQuery: fetchBaseQuery({ baseUrl: apiBaseUrl }),
  tagTypes: ["LIST"],
  endpoints: (builder) => ({
    getAllUsers: builder.query({
      query: () => "/users",
      providesTags: ["LIST"],
    }),
    validateUser: builder.mutation({
      query: (user) => ({
        url: "accounts/validate.php",
        method: "POST",
        body: user,
      }),
    }),
    deleteUser: builder.mutation({
      query: (obj) => ({
        url: `/users/index.php`,
        method: "DELETE",
        body: obj,
      }),
      invalidatesTags: ["LIST"],
    }),
    postUser: builder.mutation({
      query: (obj) => ({ url: "/users/index.php", method: "POST", body: obj }),
    }),
  }),
});

export const {
  useGetAllUsersQuery,
  useValidateUserMutation,
  useDeleteUserMutation,
  usePostUserMutation,
} = usersApi;
