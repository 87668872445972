import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { ErrorMessage } from "@hookform/error-message";
// import { useUploadCVMutation } from "../redux/jobs.api";
import { forwardRef } from "react";

const StepTwo = (props, ref) => {
  const validationSchema = yup.object().shape({
    resume: yup
      .mixed()
      .required("resume is required")
      .test("required", "file required", (val) => val.length > 0)
      .test(
        "fileSize",
        "file is too large",
        (val) => val.length > 0 && val[0].size <= 1024 * 1024 * 1
      )
      .test("fileType", "unsupport file type", (val) => {
        return (
          val.length > 0 &&
          [
            ".doc",
            ".docx",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          ].includes(val[0].type)
        );
      }),
  });

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({ resolver: yupResolver(validationSchema) });

  //   const [uploadCV, fileQuery] = useUploadCVMutation();

  const onFormSubmit = (data) => {
    const formData = new FormData();
    formData.append("resume", data.resume[0]);
    props.onStepSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <div className="row d-flex mb-3">
        <label className="col-form-label col-md-2">file</label>
        <div className="col-md-10 custom-file">
          <Controller
            control={control}
            name="resume"
            render={({ field: { onChange } }) => (
              <input
                className={`form-control ${errors.resume && "is-invalid"} `}
                type="file"
                {...register("resume", { required: true })}
                onChange={onChange}
              ></input>
            )}
          />
          <ErrorMessage
            errors={errors}
            name={"resume"}
            render={({ message }) => (
              <p className="invalid-feedback">{message}</p>
            )}
          />
        </div>
      </div>
      <div className="row mb-3 collapse">
        <div className="col-md-10 offset-md-2">
          <button className="btn btn-primary" ref={ref}>
            Upload
          </button>
        </div>
      </div>
    </form>
  );
};

export default forwardRef(StepTwo);
