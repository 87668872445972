import { Sidebar } from "../blog/sidebar.component";

import { ProfileAccount } from "./profile.account";
import { ChurchAccount } from "./church.account";
import { EmployeeAccount } from "./employee.account";
import { CompanyAccount } from "./company.account";
import { AdminAccount } from "../admin";
import { useAuthentication } from "../hooks/auth.hook";

const MyAccount = (props) => {
  const { user, isLoggedIn } = useAuthentication();
  const {
    typeInfo: { configValue },
  } = user;
  return (
    <>
      {user && (
        <>
          <div className="ftco-section">
            <div className="container">
              <div className="row g-lg-5">
                <div className="col-lg-8">
                  {configValue == "MEMBER" && <ProfileAccount user={user} />}
                  {configValue == "CHURCH" && <ChurchAccount user={user} />}
                  {configValue == "EMPLOYEE" && <EmployeeAccount user={user} />}
                  {configValue == "COMPANY" && <CompanyAccount user={user} />}
                  {configValue == "ADMIN" && <AdminAccount user={user} />}
                </div>
                <div className="col-lg-4 sidebar pl-md-4">
                  <Sidebar userType={configValue} profileId={user.Id} />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export { MyAccount };
