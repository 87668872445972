import { useSelector, useDispatch } from "react-redux";
import { logout, setUser } from "../redux/auth.slice";
import { useValidateUserMutation } from "../redux/user.api";

const useAuthentication = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [validateUser, query] = useValidateUserMutation();
  const setUserState = (data) => {
    return new Promise((res, rej) => {
      localStorage.setItem("auth", JSON.stringify(data));
      dispatch(setUser(data));
      return res(data);
    });
  };
  const login = (creds) => {
    return validateUser(creds)
      .unwrap()
      .then((res) => {
        setUserState(res);
        return true;
      })
      .catch((e) => false);
  };
  return {
    isLoggedIn: user != null,
    user,
    login,
    logout,
    setUserState,
  };
};

export { useAuthentication };
