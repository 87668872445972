import { Trash3, ArrowClockwise } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import { RowIndex } from "../../components/rowIndex.component";
import { useGetAllUsersQuery } from "../../redux/user.api";
const LoginList = () => {
  const { data, isLoading, refetch } = useGetAllUsersQuery();
  return (
    <div className="card">
      <div className="card-header">
        <div className="d-flex justify-content-between align-items-center">
          <h5>Logins</h5>
          <ArrowClockwise onClick={refetch} />
        </div>
      </div>
      <div className="card-body">
        <table className="table">
          <thead>
            <tr>
              <th>#</th>
              <th>Username</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {!isLoading &&
              data &&
              data.map((item, index) => (
                <tr>
                  <td>
                    <RowIndex index={index} />
                  </td>
                  <td>
                    <Link to={`${item.Id}`}>{item.username}</Link>
                  </td>
                  <td>{item.statusId}</td>
                  <td>{JSON.stringify(item.createdDate)}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export { LoginList };
