import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { ActiveStep } from "./active-step";
import { usePostChurchMutation } from "../../redux/churches.api";
import { useAuthentication } from "../../hooks/auth.hook";
import {
  updateStep,
  moveNext,
  moveBack,
} from "../../redux/church.wizard.slice";
import { useRegistrationType } from "../../hooks/registration-type.hook";
const ChurchWizard = (props) => {
  const { steps, currentStep } = useSelector((state) => state.churchWizard);
  const [postChurch, postQuery] = usePostChurchMutation();
  const stepsCount = steps.length;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const stepSubmitRef = useMemo(
    () =>
      Array(stepsCount)
        .fill(0)
        .map((i) => React.createRef()),
    []
  );

  const { login } = useAuthentication();
  const { config } = useRegistrationType({
    configName: "PROFILE_TYPE",
    configValue: "CHURCH",
  });
  const handleStepSubmit = (data) => {
    dispatch(updateStep({ data: { ...data }, step: currentStep }));
    if (currentStep < stepsCount) {
      dispatch(moveNext());
    }

    if (currentStep == stepsCount) {
      const formData = new FormData();
      formData.append("churchImage", steps[0].data.churchImage);
      formData.append("churchTitle", steps[0].data.title);
      formData.append("shortNote", steps[0].data.shortNote);
      formData.append("address", steps[0].data.address);
      formData.append("title", steps[1].data.title);
      formData.append("firstName", steps[1].data.firstName);
      formData.append("lastName", steps[1].data.lastName);
      formData.append("genderId", steps[1].data.genderId);
      formData.append("dob", steps[1].data.dob);
      formData.append("email", steps[1].data.email);
      formData.append("mobile", steps[1].data.mobile);
      formData.append("facebook", steps[2].data.facebook);
      formData.append("twitter", steps[2].data.twitter);
      formData.append("youtube", steps[2].data.youtube);
      formData.append("instagram", steps[2].data.instagram);
      formData.append("userName", steps[1].data.email);
      formData.append("password", steps[3].data.password);
      formData.append("statusId", 6);
      formData.append("userTypeId", config[0].Id);

      
      postChurch(formData)
        .unwrap()
        .then(async (res) => {
          console.log(res);
          const checkLogin = await login({
            username: steps[1].data.email,
            password: steps[3].data.password,
          });
          if (checkLogin == true) {
            navigate(`/my-account`);
          } else {
          }
        })
        .catch((e) => alert(JSON.stringify(e)));
    }
  };

  const handleNext = () => {
    stepSubmitRef[currentStep - 1].current?.click();
  };

  return (
    <div className="card">
      <div className="card-body p-5">
        {JSON.stringify(steps)}
        <ActiveStep
          currentStep={currentStep}
          stepSubmitRef={stepSubmitRef}
          handleStepSubmit={handleStepSubmit}
        />
      </div>
      <div className="card-footer d-flex justify-content-end">
        {currentStep > 1 && (
          <button
            type="button"
            onClick={() => dispatch(moveBack())}
            className="btn btn-primary me-2"
          >
            BACK
          </button>
        )}
        <button type="button" onClick={handleNext} className="btn btn-primary">
          {currentStep == stepsCount ? <>SAVE</> : <>NEXT</>}
        </button>
      </div>
    </div>
  );
};

export { ChurchWizard };
