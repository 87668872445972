import { ChurchImageComponent } from "./church-image-component";
import { Check2Circle } from "react-bootstrap-icons";

const ChurchItemComponent = ({ item, selectedChurch, onChurchSelected }) => {
  return (
    <div className="card m-2" style={{ width: "30%" }}>
      <ChurchImageComponent churchId={item.Id} />
      <div className="card-body">
        <h5 className="title">{item.title}</h5>
        <p className="text"> {item.about}</p>
        <button
          disabled={selectedChurch == item.Id}
          class="btn btn-primary"
          onClick={() => onChurchSelected(item.Id)}
        >
          {selectedChurch == item.Id && (
            <Check2Circle size={20} className="me-2" />
          )}
          Select
        </button>
      </div>
    </div>
  );
};
export { ChurchItemComponent };
