import moment from "moment";
import { useEffect, useState } from "react";
import { useGetConfigQuery } from "../redux/config.api";
import { trimString } from "../helpers";
const AgeGenderComponent = ({ dob, genderId }) => {
  const [gender, setGender] = useState("...");
  const { data, isLoading } = useGetConfigQuery(genderId);
  useEffect(() => {
    if (!isLoading && data) {
      setGender(data);
    }

    return () => {};
  }, [isLoading]);

  return dob == null ? (
    ""
  ) : (
    <>
      {moment(new Date()).diff(moment(dob), "years")}/
      {trimString(gender.configValue, 1)}
    </>
  );
};

export { AgeGenderComponent };
