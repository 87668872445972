export function buildString(params) {
  let queryString = "";
  Object.keys(params).map((k) => {
    if (queryString.length == 0) {
      queryString += "?";
    } else {
      queryString += "&";
    }

    if (params[k] != null) {
      queryString += `${k}=${params[k]}`;
    }

    const qlength = queryString.length;
    if (queryString.lastIndexOf("&") == qlength) {
      queryString = queryString.substring(
        queryString.length - 1,
        queryString.length
      );
    }
  });
  return queryString;
}

export function trimString(str, len) {
  if (str && str.length > len) {
    return str.substring(0, len);
  } else {
    return str;
  }
}
