export const SliderComponent = () => {
  return (
    <>
      <section className="slider-hero">
        <div className="overlay"></div>
        <div className="hero-slider">
          <div className="item">
            <div className="work">
              <div
                className="img img2 d-flex align-items-center js-fullheight"
                style={{
                  backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/slider-image-1.jpg)`,
                }}
              >
                <div className="container-xl">
                  <div className="row d-flex justify-content-center">
                    <div className="col-md-7">
                      <div className="text text-center mt-lg-5">
                        <h1 className="mb-4">
                          We're Always Here To Give Financial Help!
                        </h1>
                        <p className="mb-4">
                          Far far away, behind the word mountains, far from the
                          countries Vokalia and Consonantia, there live the
                          blind texts.
                        </p>
                        <p>
                          <a href="#" className="btn btn-white p-4 py-3">
                            Get Started{" "}
                            <span className="ion-ios-arrow-round-forward"></span>
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="item d-none">
            <div className="work">
              <div
                className="img d-flex align-items-center justify-content-center js-fullheight"
                style={{
                  backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/slider-image-2.jpg)`,
                }}
              >
                <div className="container-xl">
                  <div className="row d-flex justify-content-center">
                    <div className="col-md-7">
                      <div className="text text-center mt-lg-5">
                        <h1 className="mb-4">
                          Build Your Financial Plan With Our Specialists
                        </h1>
                        <p className="mb-4">
                          Far far away, behind the word mountains, far from the
                          countries Vokalia and Consonantia, there live the
                          blind texts.
                        </p>
                        <p>
                          <a href="#" className="btn btn-white p-4 py-3">
                            Get Started{" "}
                            <span className="ion-ios-arrow-round-forward"></span>
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
