export const RecentBlogs = () => (
  <section className="ftco-section">
    <div className="container-xl">
      <div className="row justify-content-center mb-5">
        <div className="col-lg-7 heading-section text-center">
          <span className="subheading">Our Blog</span>
          <h2>Recent From Blog</h2>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-6 col-lg-3 d-flex">
          <div className="blog-entry justify-content-end">
            <a
              href="blog-single.html"
              className="block-20 img"
              style={{
                backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/blog-01.jpg)`,
              }}
            ></a>
            <div className="text">
              <p className="meta">
                <span>
                  <i className="fa fa-user me-1"></i>Admin
                </span>{" "}
                <span>
                  <i className="fa fa-calendar me-1"></i>Jan. 27, 2021
                </span>{" "}
                <span>
                  <a href="#">
                    <i className="fa fa-comment me-1"></i> 3 Comments
                  </a>
                </span>
              </p>
              <h3 className="heading mb-3">
                <a href="#">
                  Incorporation is A Big Milestone for Your Business
                </a>
              </h3>
              <p>A small river named Duden flows by their place.</p>
              <a
                href="blog.html"
                className="btn-custom d-flex align-items-center justify-content-center"
              >
                <span className="fa fa-link"></span>
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-3 d-flex">
          <div className="blog-entry justify-content-end">
            <a
              href="blog-single.html"
              className="block-20 img"
              style={{
                backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/ximage_2.jpg.pagespeed.ic.yG61gYt8iz.jpg)`,
              }}
            ></a>
            <div className="text">
              <p className="meta">
                <span>
                  <i className="fa fa-user me-1"></i>Admin
                </span>{" "}
                <span>
                  <i className="fa fa-calendar me-1"></i>Jan. 27, 2021
                </span>{" "}
                <span>
                  <a href="#">
                    <i className="fa fa-comment me-1"></i> 3 Comments
                  </a>
                </span>
              </p>
              <h3 className="heading mb-3">
                <a href="#">
                  Incorporation is A Big Milestone for Your Business
                </a>
              </h3>
              <p>A small river named Duden flows by their place.</p>
              <a
                href="blog.html"
                className="btn-custom d-flex align-items-center justify-content-center"
              >
                <span className="fa fa-link"></span>
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-3 d-flex">
          <div className="blog-entry justify-content-end">
            <a
              href="blog-single.html"
              className="block-20 img"
              style={{
                backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/ximage_3.jpg.pagespeed.ic.L063JoXyYB.jpg)`,
              }}
            ></a>
            <div className="text">
              <p className="meta">
                <span>
                  <i className="fa fa-user me-1"></i>Admin
                </span>{" "}
                <span>
                  <i className="fa fa-calendar me-1"></i>Jan. 27, 2021
                </span>{" "}
                <span>
                  <a href="#">
                    <i className="fa fa-comment me-1"></i> 3 Comments
                  </a>
                </span>
              </p>
              <h3 className="heading mb-3">
                <a href="#">
                  Incorporation is A Big Milestone for Your Business
                </a>
              </h3>
              <p>A small river named Duden flows by their place.</p>
              <a
                href="blog.html"
                className="btn-custom d-flex align-items-center justify-content-center"
              >
                <span className="fa fa-link"></span>
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-3 d-flex">
          <div className="blog-entry justify-content-end">
            <a
              href="blog-single.html"
              className="block-20 img"
              style={{
                backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/image_4.jpg)`,
              }}
            ></a>
            <div className="text">
              <p className="meta">
                <span>
                  <i className="fa fa-user me-1"></i>Admin
                </span>{" "}
                <span>
                  <i className="fa fa-calendar me-1"></i>Jan. 27, 2021
                </span>{" "}
                <span>
                  <a href="#">
                    <i className="fa fa-comment me-1"></i> 3 Comments
                  </a>
                </span>
              </p>
              <h3 className="heading mb-3">
                <a href="#">
                  Incorporation is A Big Milestone for Your Business
                </a>
              </h3>
              <p>A small river named Duden flows by their place.</p>
              <a
                href="blog.html"
                className="btn-custom d-flex align-items-center justify-content-center"
              >
                <span className="fa fa-link"></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);
