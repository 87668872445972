import { useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";

const Paging = ({ total, pageSize = 5 }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [rowsize, setRowsize] = useState(searchParams.get("rows") ?? pageSize);
  const calc = () => {
    let count = 1;
    if (pageSize > total) {
      count = 1;
    } else {
      count = Math.floor(total / pageSize);
      if (total % pageSize > 0) {
        count += 1;
      }
    }
    return count;
  };
  const onPageChanged = (page) => {
    setSearchParams({ page, rows: rowsize });
  };
  useEffect(() => {
    setSearchParams({ page: searchParams.get("page") ?? 1, rows: rowsize });
  }, [rowsize]);

  return (
    <div className="d-flex justify-content-end">
      <div className="d-flex align-items-center">
        <div className="d-flex align-items-center">
          <select
            className="form-control col mx-3"
            onChange={({ target: { value } }) => setRowsize(value)}
          >
            <option value={1}>1</option>
            <option value={2}>2</option>
          </select>
        </div>
        <ul className="pagination mb-0">
          <li className="page-item">
            <button className="page-link" onClick={() => onPageChanged(1)}>
              &laquo;
            </button>
          </li>
          {[...Array(calc()).keys()].map((i) => (
            <li className="page-item" key={`page-${i}`}>
              <button
                className="page-link"
                onClick={() => onPageChanged(i + 1)}
              >
                {i + 1}
              </button>
            </li>
          ))}
          <li className="page-item">
            <button className="page-link" onClick={() => onPageChanged(calc())}>
              &raquo;
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export { Paging };
