import { Routes, Route, Outlet } from "react-router-dom";
import { ProfileWizard } from "./wizard/index";
import { ProfileMaster } from "./profile.master";

const Profile = () => {
  return (
    <div className="ftco-section">
      <div className="container">
        <Routes>
          <Route exact path="/" element={<ProfileMaster />} />
          <Route exact path="/create" element={<ProfileWizard />} />
        </Routes>
        <Outlet />
      </div>
    </div>
  );
};

export { Profile };
export * from "./profile.form";
export * from "./profile.details";
