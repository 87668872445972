import { useState } from "react";
import { Trash3 } from "react-bootstrap-icons";
import { ModalComponent, RowIndex } from "../../components";
import {
  useGetJobProfileByProfileIdQuery,
  useDeleteJobProfileMutation,
} from "../../redux/job-profile.api";

const JobsProfiles = ({ user }) => {
  const query = useGetJobProfileByProfileIdQuery(user.Id);
  const [showDialog, setShowDialog] = useState(false);
  const [selectedItem, setselectedItem] = useState();
  const [deleteJobProfile, deleteQuery] = useDeleteJobProfileMutation();
  const handleModalOk = () => {
    const obj = { id: selectedItem?.Id };
    deleteJobProfile(obj)
      .unwrap()
      .then((res) => {})
      .catch((e) => alert(e));
    setShowDialog(false);
  };
  return (
    <>
      <ModalComponent
        title={"Delete"}
        message={"Are you sure to delete?"}
        data={selectedItem?.profileTitle}
        showDialog={showDialog}
        onOkClick={handleModalOk}
        onCancelClick={() => setShowDialog(false)}
      />
      <table className="table">
        <thead>
          <tr>
            <th>#</th>
            <th>Title</th>
            <th>Description</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {query.data &&
            query.data.map((item, index) => (
              <tr key={item.id}>
                <td>
                  <RowIndex index={index} />
                </td>
                <td>{item.profileTitle}</td>
                <td>{item.description}</td>
                <td>{JSON.stringify(item.createdDated)}</td>
                <td>
                  <Trash3
                    color="#f10"
                    onClick={() => {
                      setselectedItem(item);
                      setShowDialog(true);
                    }}
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
};
export { JobsProfiles };
