import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiBaseUrl } from "../_constants";

export const matrimonyProfileApi = createApi({
  reducerPath: "matrimonyProfile",
  baseQuery: fetchBaseQuery({ baseUrl: apiBaseUrl }),
  tagTypes: ["POST"],
  endpoints: (builder) => ({
    getMatrimonyByProfile: builder.query({
      query: (profileId) => `/matrimony/index.php?profileId=${profileId}`,
      providesTags: ["POST"],
    }),
    postMapping: builder.mutation({
      query: (obj) => ({
        url: `/matrimony/index.php`,
        method: "POST",
        body: obj,
      }),
      invalidatesTags: ["POST"],
    }),
  }),
});

export const { useGetMatrimonyByProfileQuery, usePostMappingMutation } =
  matrimonyProfileApi;
