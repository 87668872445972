import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiBaseUrl } from "../_constants";

export const churchMappingApi = createApi({
  reducerPath: "churchMappingApi",
  baseQuery: fetchBaseQuery({ baseUrl: apiBaseUrl }),
  tagTypes: ["list"],
  endpoints: (builder) => ({
    getAllMappings: builder.query({
      query: () => `/church-mapping/index.php`,
      providesTags: ["list"],
    }),
    getMapping: builder.query({
      query: (id) => `/churche-mapping/index.php?id=${id}`,
    }),
    getMappingByProfileId: builder.query({
      query: (id) => `/church-mapping/index.php?profileId=${id}`,
      providesTags: ["list"],
    }),
    getMemberRole: builder.query({
      query: (id) => `/church-mapping/profile-role.php?profileId=${id}`,
      providesTags: ["list"],
    }),
    getChurchProfile: builder.query({
      query: (churchId) => `/church-mapping/index.php?churchId=${churchId}`,
      providesTags: ["list"],
    }),
    getMappingsCount: builder.query({
      query: (id) => `/church-mapping/church-member-count.php?id=${id}`,
      providesTags: ["list"],
    }),

    postMapping: builder.mutation({
      query: (entity) => ({
        url: `/church-mapping/index.php`,
        method: "POST",
        body: entity,
      }),
      invalidatesTags: ["list"],
    }),

    deleteMapping: builder.mutation({
      query: (id) => ({
        url: `/church-mapping/index.php`,
        method: "DELETE",
        body: { Id: id },
      }),
      invalidatesTags: ["list"],
    }),

    getCount: builder.query({ query: () => `/church-mapping/count.php` }),
  }),
});

export const {
  useGetAllMappingsQuery,
  useGetMappingQuery,
  useGetMappingByProfileIdQuery,
  usePostMappingMutation,
  useDeleteMappingMutation,
  useGetCountQuery,
  useGetMemberRoleQuery,
  useGetChurchProfileQuery,
  useGetMappingsCountQuery,
} = churchMappingApi;
