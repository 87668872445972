const churches = [{ id: 1 }];
const churchEvents = [
  {
    id: 1,
    churchId: 1,
    name: "event one",
    eventDate: "2022-07-01",
    startTime: "",
    endTime: "",
    timeZone: "",
    info: [
      {
        di: 1,
        title: "Chief guest",
        description: "Ps.Antony sagayam, Ps.Williams",
      },
      {
        id: 2,
        title: "Archestra",
        description: "Divine musicals",
      },
    ],
  },
];

const churchServices = [
  {
    id: 1,
    churchId: 1,
    title: "",
    day: "Friday",
    language: "English",
    startTime: "7:30",
    endTime: "9:00",
    session: "A.M",
    timeZone: "AST",
  },
  {
    id: 2,
    churchId: 1,
    title: "",
    day: "Friday",
    language: "Tamil",
    startTime: "9:30",
    endTime: "11:00",
    session: "A.M",
    timeZone: "AST",
  },
  {
    id: 3,
    churchId: 1,
    title: "",
    day: "Saturday",
    language: "Tamil",
    startTime: "7:30",
    endTime: "9:00",
    session: "A.M",
    timeZone: "AST",
  },
];

export { churches, churchEvents, churchServices };
