import { useState, useRef, useEffect } from "react";
import { Link, Outlet, Route, Routes, useParams } from "react-router-dom";
import { useGetMappingByProfileIdQuery } from "../redux/profile-company-mappings.api";
import {
  useGetCompanyQuery,
  useUpdateProfileImageMutation,
} from "../redux/company.api";
import { JobForm } from "../jobs/job-form";
import { RowIndex } from "../components/rowIndex.component";
import { useGetJobQuery } from "../redux/jobs.api";
import { Tabs, Tab, Modal } from "react-bootstrap";
import {
  Pencil,
  Globe2,
  EnvelopeAt,
  Phone,
  ArrowRepeat,
  PlusLg,
  Link45deg,
  Pen,
} from "react-bootstrap-icons";
import {
  useGetAllProfilesQuery,
  useGetProfileQuery,
} from "../redux/profile.api";
import { AgeGenderComponent } from "../components/age-gender.component";
import { useGetJobsCountQuery } from "../redux/jobs.api";
import { ProfileImage as CompanyLogo } from "../components/company-image.component";
import { ProfileImage } from "../components/profile-image.component";
import { CompanyForm } from "../company";
import { useGetApplicationsForJobQuery } from "../redux/job-application.api";
import {
  useGetJobProfileByProfileIdQuery,
  useGetCVQuery,
} from "../redux/job-profile.api";
import { Document, Page } from "react-pdf";
const Dashboard = ({ companyId }) => {
  const [imageUrl, setImageUrl] = useState(null);
  const fileRef = useRef();
  const { data, isLoading } = useGetCompanyQuery(companyId);
  const countQuery = useGetJobsCountQuery(companyId);
  const [updateProfileImage, updateQuery] = useUpdateProfileImageMutation();

  const handleFilechange = () => {
    setImageUrl(URL.createObjectURL(fileRef.current.files[0]));
    const formData = new FormData();
    formData.append("id", data.company.Id);
    formData.append("profileImage", fileRef.current.files[0]);
    formData.append("fullName", `${data.company.title}`);

    // console.log(data.company.Id);
    // console.log(data.company.title);
    updateProfileImage(formData)
      .unwrap()
      .then((res) => {
        // console.log(res);
        // setStoreImage(fileRef.current.files[0]["name"]);
        //setImageUrl(null);
      })
      .catch((e) => {
        alert(JSON.stringify(e));
      });
  };
  useEffect(() => {
    return () => {
      setImageUrl(null);
    };
  }, []);

  return (
    <div className="card">
      <div className="card-body">
        {!isLoading && data && (
          <>
            <div className="d-flex">
              <div className="me-4">
                <CompanyLogo
                  onChangeClicked={() => fileRef.current.click()}
                  previewImage={imageUrl}
                  style={"small"}
                  canupdate={true}
                  company={data.company}
                />
              </div>
              <div className="col">
                <h5>
                  {data.company?.title} <Link to={"edit"}>edit</Link>
                </h5>
                <p>{data.company?.address}</p>
                <p className="p-0 m-0">
                  <EnvelopeAt className="me-2" />
                  {data.company.email ? (
                    <>{data.company.email}</>
                  ) : (
                    <small>N/A</small>
                  )}
                </p>
                <p className="p-0 m-0">
                  <Globe2 className="me-2" />
                  {data.company.website ? (
                    <> {data.company.website} </>
                  ) : (
                    <small>N/A</small>
                  )}
                </p>
              </div>
            </div>
            <div className="row my-5">
              <div className="col text-center">
                <h3>{countQuery.data}</h3> <p> Jobs Count</p>
              </div>
              <div className="col text-center">
                <h3>0</h3> <p>Application count </p>
              </div>
              <div className="col text-center">
                <h3>0</h3> <p>Selection count </p>
              </div>
            </div>
            <div className="card collapse">
              <div className="card-body">
                <input
                  name="logo"
                  type="file"
                  ref={fileRef}
                  onChange={handleFilechange}
                ></input>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
const Jobs = ({ companyId }) => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<JobsList companyId={companyId} />} />
        <Route path=":id" element={<JobDetails />} />
        <Route path="create" element={<JobForm companyId={companyId} />} />
        <Route path="edit/:id" element={<JobForm companyId={companyId} />} />
      </Routes>
      <Outlet />
    </div>
  );
};
const JobProfileItem = ({ profileId, jobProfileId }) => {
  const { data, isLoading } = useGetJobProfileByProfileIdQuery(jobProfileId);
  const cvQuery = useGetCVQuery();
  const base64toBlob = (data) => {
    // Cut the prefix `data:application/pdf;base64` from the raw base 64
    // const base64WithoutPrefix = data.substr(
    //   "data:application/pdf;base64,".length
    // );

    const bytes = atob(data);
    let length = bytes.length;
    let out = new Uint8Array(length);

    while (length--) {
      out[length] = bytes.charCodeAt(length);
    }

    return new Blob([out], { type: "application/pdf" });
  };
  const [cv, setCv] = useState();
  useEffect(() => {
    if (!cvQuery.isLoading) {
      //console.log(cvQuery.data);
      const blob = base64toBlob(cvQuery.data?.file);
      const url = URL.createObjectURL(blob);
      setCv(url.substring('blob:'.length));
      console.log(cv);
    }
  }, [cvQuery.isLoading]);
  return (
    <>
      {data && (
        <div>
          <div>
            <Pen className="me-2" />
            <small>{data.profileTitle}</small>
          </div>
          <div>
            <Link45deg className="me-2" />
            <small>{data.bioData}</small>
          </div>
        </div>
      )}
      <div> {cv && <Document file={cv}></Document>}</div> {cv}
    </>
  );
};
const ProfileComponent = ({ profileId, jobProfileId }) => {
  const { data, isLoading } = useGetProfileQuery(profileId);

  return (
    <>
      {!isLoading && (
        <li className="d-flex mb-3">
          <div className="me-3">
            <ProfileImage user={data} style={"x-small"} />
          </div>
          <div className="col">
            <h6>
              {`${data.title} ${data.firstName} ${data.lastName}`}
              <small className="ms-2">
                <AgeGenderComponent dob={data.dob} genderId={data.genderId} />
              </small>
            </h6>
            <div>
              <small>
                <EnvelopeAt className="me-2" /> {data.email}
              </small>
              <small className="d-block">
                <Phone className="me-2" /> {data.mobile}
              </small>
              <JobProfileItem
                profileId={profileId}
                jobProfileId={jobProfileId}
              />
            </div>
          </div>
        </li>
      )}
    </>
  );
};
const ProfileListComponent = ({ jobId }) => {
  const profilesQuery = useGetApplicationsForJobQuery(jobId);

  return (
    <div>
      <ul className="list-unstyled">
        {profilesQuery.data?.map((item) => (
          <>
            <ProfileComponent
              profileId={item.profileId}
              jobProfileId={item.jobProfileId}
            />
          </>
        ))}
      </ul>
    </div>
  );
};
const JobDetails = () => {
  const { id } = useParams();
  const { data: jobItem, isLoading, isSuccess } = useGetJobQuery(id);
  return (
    <div>
      {isLoading ? (
        <>loading...</>
      ) : (
        <>
          <Tabs defaultActiveKey="desc">
            <Tab eventKey={"desc"} title="Description">
              <div className="tab-content">
                <h5>{jobItem.title}</h5>
                <p>Desc: {jobItem.description}</p>
                <p>Exp: {jobItem.experience}</p>
                <p>Location: {jobItem.jobLocation}</p>
                <p>Date: {JSON.stringify(jobItem.createdDate)}</p>
                <p>Close: {JSON.stringify(jobItem.closeDate)}</p>
                <p>Views: {jobItem.viewCount}</p>
                <p>Likes: {jobItem.likeCount}</p>
              </div>
            </Tab>
            <Tab eventKey={"applied"} title="Applications">
              <div className="p-2">
                <ProfileListComponent jobId={id} />
              </div>
            </Tab>
            <Tab eventKey={"selected"} title="Selection">
              <div className="p-2"> selection history is here </div>
            </Tab>
          </Tabs>
        </>
      )}
      <div className="my-modal collapse">
        <div className="content">
          <div>this is modal content</div>
        </div>
      </div>
    </div>
  );
};
const JobsList = ({ companyId }) => {
  const { data, isLoading, refetch } = useGetCompanyQuery(companyId);
  return (
    <div className="card">
      <div className="card-header">
        <div className="d-flex justify-content-between align-items-center">
          <h5>Jobs</h5>
          <ul className="nav">
            <li className="nav-item">
              <Link className="nav-link" to={"create"}>
                <PlusLg />
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link">
                <ArrowRepeat onClick={refetch} />
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="card-body">
        {!isLoading && data && (
          <>
            <table className="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Title</th>
                  <th>Views</th>
                  <th>Likes</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                {data.jobs?.map((item, index) => (
                  <tr key={item.Id}>
                    <td>
                      <RowIndex index={index} />
                    </td>
                    <td>
                      <Link to={`${item.Id}`}>{item.title}</Link>
                    </td>
                    <td>{item.viewCount}</td>
                    <td>{item.likeCount}</td>
                    <td>{JSON.stringify(item.createdDate)}</td>
                    <td>
                      <Link to={`edit/${item.Id}`}>
                        {" "}
                        <Pencil />
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
      </div>
    </div>
  );
};
const CompanyProfile = ({ user, companyId }) => {
  const { data, isLoading } = useGetCompanyQuery(companyId);
  return (
    <div className="card">
      <div className="card-body">
        {!isLoading && data && (
          <>
            <h5>{data.company?.title}</h5> <p>{data.company?.address}</p>
          </>
        )}
      </div>
    </div>
  );
};
const CompanyAccount = ({ user }) => {
  const query = useGetMappingByProfileIdQuery(user.Id);

  return (
    <>
      {query.data && (
        <>
          <Routes>
            <Route path="/" element={<Dashboard companyId={query.data.Id} />} />
            <Route path="edit" element={<CompanyForm />} />
            <Route
              path="/jobs/*"
              element={<Jobs companyId={query.data.Id} />}
            />
            <Route
              path="profile"
              element={<CompanyProfile user={user} companyId={query.data.Id} />}
            />
          </Routes>
        </>
      )}
      <Outlet />
    </>
  );
};
export { CompanyAccount };
