import { Outlet, Route, Routes } from "react-router-dom";
import { ServiceForm } from "./service.form";
import { ServiceList } from "./service.master";

const ChurchServices = ({ churchId }) => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<ServiceList churchId={churchId} />} />
        <Route path="/edit/:id" element={<ServiceForm churchId={churchId} />} />
      </Routes>
      <Outlet />
    </div>
  );
};

export { ChurchServices };
export * from "./service.form";
export * from "./service.master";
