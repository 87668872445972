import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiBaseUrl } from "../_constants";
import * as helpers from "../helpers/index";

export const wishListApi = createApi({
  reducerPath: "wishListApi",
  baseQuery: fetchBaseQuery({ baseUrl: apiBaseUrl }),
  tagTypes: ["POST"],
  endpoints: (builder) => ({
    getAll: builder.query({
      query: (params) => `jobs/wishlist.php${helpers.buildString(params)}`,
      providesTags: ["POST"],
    }),
    addWish: builder.mutation({
      query: (params) => ({
        url: `jobs/wishlist.php`,
        method: "POST",
        body: params,
      }),
      invalidatesTags: ["POST"],
    }),
    removeWish: builder.mutation({
      query: (params) => ({
        url: `jobs/wishlist.php`,
        method: "DELETE",
        body: params,
      }),
      invalidatesTags: ["POST"],
    }),
  }),
});

export const { useGetAllQuery, useAddWishMutation, useRemoveWishMutation } =
  wishListApi;
