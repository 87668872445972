import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiBaseUrl } from "../_constants";

export const profileCompanyMappingApi = createApi({
  reducerPath: "profileCompanyMappingApi",
  baseQuery: fetchBaseQuery({ baseUrl: apiBaseUrl }),
  endpoints: (builder) => ({
    getAllMappings: builder.query({
      query: `/company-mapping/index.php`,
    }),
    getMapping: builder.query({
      query: (id) => `/company-mapping/index.php?id=${id}`,
    }),
    getMappingByProfileId: builder.query({
      query: (id) => `/company-mapping/index.php?profileId=${id}`,
    }),
    // getMappingByUserId: builder.query({
    //   query: (id) => `/company-mapping/index.php?userId=${id}`,
    // }),
    postCompanyMapping: builder.mutation({
      query: (obj) => ({
        url: "/company-mapping/index.php",
        method: "POST",
        body: obj,
      }),
    }),
  }),
});

export const {
  useGetAllMappingsQuery,
  useGetMappingQuery,
  useGetMappingByProfileIdQuery,
  usePostCompanyMappingMutation,
} = profileCompanyMappingApi;
