import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { NavAuth } from "./nav-auth.component";
export const Navigation = () => {
  const { user } = useSelector((state) => state.auth);
  return (
    <>
      <div className="top-wrap">
        <div className="container">
          <div className="row">
            <div className="col-md-3 d-flex align-items-center mb-2 mb-md-0">
              <div className="con">
                <p className="mb-0">
                  <span className="fa fa-phone"></span> Call Us: +2 392 3929 210
                </p>
              </div>
            </div>
            <div className="col-md-3 d-flex align-items-center mb-2 mb-md-0">
              <div className="con">
                <p className="mb-0">
                  <span className="fa fa-map-o"></span> Location: San Francisco,
                  California, USA
                </p>
              </div>
            </div>
            <div className="col-md d-flex justify-content-start justify-content-md-end align-items-center">
              <p className="ftco-social d-flex">
                <a
                  href="#"
                  className="d-flex align-items-center justify-content-center"
                >
                  <span className="fa fa-twitter"></span>
                </a>
                <a
                  href="#"
                  className="d-flex align-items-center justify-content-center"
                >
                  <span className="fa fa-facebook"></span>
                </a>
                <a
                  href="#"
                  className="d-flex align-items-center justify-content-center"
                >
                  <span className="fa fa-google"></span>
                </a>
                <a
                  href="#"
                  className="d-flex align-items-center justify-content-center"
                >
                  <span className="fa fa-instagram"></span>
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      <nav className="navbar navbar-expand-lg  ftco-navbar-light">
        <div className="container-xl">
          <a className="navbar-brand" href="index-2.html">
            Union<small>corp.</small>
            <span>Finance &amp; Consultation</span>
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="fa fa-bars"></span> Menu
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <NavLink className="nav-link" to={"/"}>
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to={"/connect"}>
                  Connect
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to={"/news"}>
                  News &amp; Trends
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to={"/blog"}>
                  Sharing &amp; Blog
                </NavLink>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="pricing.html">
                  Service
                </a>
              </li>

              <li className="nav-item">
                <NavLink className="nav-link" to={"/contact"}>
                  Contact
                </NavLink>
              </li>

              {user == null && (
                <li className="nav-item">
                  <NavLink className="nav-link" to={"/signup"}>
                    Signup
                  </NavLink>
                </li>
              )}

              <NavAuth user={user} />
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};
