import { useGetAppliedJobsQuery } from "../../redux/job-application.api";
import moment from "moment";
const JobItem = ({ job, index }) => (
  <div>
    <h5>
      {`${index + 1}. ${job.title} `} <small>{job.company}</small>
    </h5>

    <p className="m-0">Opening Date : {moment(job.postedDate).format("D/MM/yyyy")} </p>
    <p className="m-0">Closing Date : {moment(job.appliedDate).format("D/MM/yyyy")}</p>
    <p className="m-0">Status :  {job.jobStatusId} </p>
    <p>applied Date : {moment(job.appliedDate).format("D/MM/yyyy")}</p>
  </div>
);
const JobsApplied = ({ user }) => {
  const { data, isLoading } = useGetAppliedJobsQuery(user.Id);

  return (
    <div className="">
      {isLoading ? (
        <>loading...</>
      ) : (
        <>
          {data.map((item, index) => (
            <JobItem job={item} index={index} />
          ))}
        </>
      )}
    </div>
  );
};

export { JobsApplied };
