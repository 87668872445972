import { useDispatch } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { logout } from "../redux/auth.slice";

import * as Icons from "react-bootstrap-icons";
import { ProfileImage } from "./profile-image.component";

const NavAuth = ({ user }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <>
      {user ? (
        <>
          <li className="nav-item d-flex">
            <NavLink
              className="nav-link"
              to={"my-account"}
              title={`${user.firstName}, ${user.lastName}`}
            >
              <ProfileImage user={user} style={'xx-small rounded'} />
            </NavLink>
            <a
              className="nav-link"
              href="#logout"
              onClick={() => {
                dispatch(logout());
                navigate("/");
              }}
            >
              Logout
            </a>
          </li>
        </>
      ) : (
        <li className="nav-item">
          <NavLink className="nav-link" to={"/login"}>
            Login
          </NavLink>
        </li>
      )}
    </>
  );
};
export { NavAuth };
