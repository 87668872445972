import { useState } from "react";
import { ChurchImageComponent } from "../components";
import { ChurchItemComponent } from "../components/church-item-component";
import { useAuthentication } from "../hooks";
import { usePostMappingMutation } from "../redux/church-mapping.api";
import {
  useGetChurchOfMembersQuery,
  useGetAllChurchesQuery,
} from "../redux/churches.api";
import { useSearchConfigQuery } from "../redux/config.api";

const ChurchSelectionForm = ({ onSelect, selectedChurchId }) => {
  const [selectedChurch, setSelectedChurch] = useState(selectedChurchId);
  const churches = useGetAllChurchesQuery({ page: 1, rows: 100 });
  const [filteredChurch, setFilteredChurch] = useState(churches.data);
  const handleFilter = (str) => {
    const items = churches.data.filter((f) =>
      f.title.toUpperCase().includes(str.toUpperCase())
    );
    setFilteredChurch(items);
  };
  return (
    <div>
      <div className="row">
        <label className="col-form-label col-md-2">Search</label>
        <div className="col-md-10">
          <input
            type="text"
            className="form-control"
            onChange={({ target: { value } }) => handleFilter(value)}
          />
        </div>
      </div>
      <div className="row">
        {filteredChurch?.map((item) => (
          <ChurchItemComponent
            item={item}
            selectedChurch={selectedChurch}
            onChurchSelected={(arg) => {
              setSelectedChurch(arg);
              onSelect(arg);
            }}
          />
        ))}
      </div>
    </div>
  );
};
const ChurchRoleSelection = ({ onRoleSelected, selectedRole }) => {
  const { data, isLoading } = useSearchConfigQuery({
    name: "CHURCH_MEMBER_TYPE",
    value: "",
  });
  return (
    <div>
      {!isLoading && (
        <div className="d-flex">
          {data?.map((item) => (
            <div
              className={`card m-1 col-md-3 ${
                selectedRole == item.Id && "border border-primary"
              } `}
              style={{ cursor: "pointer" }}
              onClick={() => onRoleSelected(item)}
            >
              <div className="card-body text-center">
                <h5 className={`${selectedRole == item.Id && "text-primary"} `}>
                  {item.configValue}
                </h5>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
const MyChurch = () => {
  const { user } = useAuthentication();
  const { data, error } = useGetChurchOfMembersQuery(user.Id);
  const [postMapping, postMappingQuery] = usePostMappingMutation();
  const [selectedChurch, setSelectedChurch] = useState();
  const [selectedRole, setSelectedRole] = useState();
  const handleChurchSelection = (params) => {
    setSelectedChurch(params);
  };
  const handleRoleSelection = (params) => {
    setSelectedRole(params.Id);
  };

  const steps = [{ name: "churchSelection" }, { name: "typeSelection" }];
  const [wizardSteps, setWizardSteps] = useState(steps);
  const [currentStep, setCurrentStep] = useState(0);
  const handleNext = () => {
    if (currentStep < wizardSteps.length - 1 && selectedChurch != undefined) {
      setCurrentStep(currentStep + 1);
    }
    if (currentStep == wizardSteps.length - 1) {
      const mapping = {
        profileId: user.Id,
        churchId: selectedChurch,
        churchRole: selectedRole,
        isDefaultChurch: 1,
      };
      postMapping(mapping)
        .unwrap()
        .then((res) => {
          alert("Thank you");
        })
        .catch((e) => {
          alert(JSON.stringify(e));
        });
    }
  };
  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  return (
    <div className="card">
      <div className="card-body">
        {error && (
          <>
            <div className="alert alert- collapse">{JSON.stringify(error)}</div>{" "}
            <h5>you are not part of any church</h5>
            <div className="mb-4">
              {currentStep == 0 && (
                <ChurchSelectionForm
                  onSelect={handleChurchSelection}
                  selectedChurchId={selectedChurch}
                />
              )}
              {currentStep == 1 && (
                <ChurchRoleSelection
                  selectedRole={selectedRole}
                  onRoleSelected={(params) => {
                    handleRoleSelection(params);
                  }}
                />
              )}
            </div>
            <div className="justify-content-end">
              <button
                className="btn btn-primary me-1"
                onClick={handleBack}
                disabled={currentStep < 1}
              >
                Back
              </button>
              <button
                className="btn btn-primary ms-1"
                onClick={handleNext}
                disabled={currentStep == wizardSteps.length}
              >
                Next
              </button>
            </div>
          </>
        )}
        {data && (
          <div className="d-flex">
            <ChurchImageComponent churchId={data[0].churchId} />
            <div className="p-2 ms-2">
              <p className="card-text">{data[0].churchName}</p>
              <p className="card-text">{data[0].role}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export { MyChurch };
