import { Outlet, Route, Routes } from "react-router-dom";
import { JobsLiked } from "./jobs-liked.component";
import { JobsApplied } from "./jobs-applied.component";
import { Messages } from "../components/messages.component";
import { ProfileView } from "./profile";

const EmployeeHome = () => {
  return <div>this is dashboard or like statics page</div>;
};

const EmployeeAccount = ({ user }) => {
  return (
    <div className="">
      {" "}
      {JSON.stringify(user)}
      <Routes>
        <Route path="/" element={<EmployeeHome />} />
        <Route path="/profile" element={<ProfileView />} />
        <Route path="/jobs-liked" element={<JobsLiked user={user} />} />
        <Route path="/jobs-applied" element={<JobsApplied user={user} />} />
        <Route path="../messages" element={<Messages user={user} />} />
      </Routes>
      <Outlet />
    </div>
  );
};

export { EmployeeAccount };
