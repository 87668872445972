import { useGetAllQuery } from "../../redux/wishlist.api";
import moment from "moment";
import { ArrowRight } from "react-bootstrap-icons";
import { Link } from "react-router-dom";

const JobItem = ({ job, index }) => (
  <div>
    <Link> {`# ${index}. ${job.title} `}</Link>
    <p>
      <small>{moment(job.postedDate).format("D/MM /yyyy")} </small>
      <ArrowRight className="mx-2" />
      <small>{moment(job.appliedDate).format("D/MM/yyyy")} </small>
    </p>
  </div>
);

const JobsLiked = ({ user }) => {
  const { data } = useGetAllQuery({ profileId: user.Id });

  return (
    <div className="p-1">
      {data &&
        data.map((item, index) => (
          <JobItem key={`job-${item.Id}`} index={index + 1} job={item} />
        ))}
    </div>
  );
};

export { JobsLiked };
