const messages = [
  {
    id: 1,
    sender: { id: 1 },
    receiverId: 20,
    messageText: "this for user 20 from user 1",
    createdDate: new Date(),
    isRead: false,
    fromInfo: { fistName: "Jesu Ronald", lastName: "Rajesh" },
    toInfo: { firstName: "Aloysius Deo" },
  },
];

export { messages };
