import { useState } from "react";
import { Routes, Route, Outlet } from "react-router-dom";
import moment from "moment";
import { Trash3 } from "react-bootstrap-icons";
import {
  useGetAllSubscriptionsQuery,
  useDeleteSubscriptionMutation,
} from "../redux/subscription.api";
import { ModalComponent } from "../components/modal.component";

const SubscriptionMaster = () => {
  const { data, isLoading } = useGetAllSubscriptionsQuery();
  const [deleteSubscription, deleteQuery] = useDeleteSubscriptionMutation();
  const [dialog, setDialog] = useState();
  const [selectedItem, setseletedItem] = useState();

  const handeModalConfirm = () => {
    deleteSubscription(selectedItem.id)
      .unwrap()
      .then((res) => setDialog(false))
      .catch((e) => alert(e));
  };
  const handleModalClose = () => {
    setDialog(false);
  };

  return (
    <div className="card">
      <div className="card-body">
        {isLoading ? (
          "loading data"
        ) : (
          <>
            <table className="table">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Subscription</th>
                  <th>Date</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.map((item, index) => (
                    <tr key={`subscription-id-${index}`}>
                      <td>{index + 1}.</td>
                      <td>
                        {item.profileInfo && (
                          <>
                            {item.profileInfo["title"]}
                            {item.profileInfo["firstName"]}
                            {item.profileInfo["lastName"]}
                          </>
                        )}
                      </td>
                      <td>{item.subscriptions}</td>
                      <td>{moment(item.createdDate).format("DD/MM/yyyy")}</td>
                      <td>
                        <Trash3 className="text-danger"
                          onClick={() => {
                            setseletedItem(item);
                            setDialog(true);
                          }}
                        />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <ModalComponent
              showDialog={dialog}
              title="Delete"
              message={`${selectedItem?.subscriptions}`}
              onCancelClick={handleModalClose}
              onOkClick={handeModalConfirm}
            />
          </>
        )}
      </div>
    </div>
  );
};

const Subscriptons = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<SubscriptionMaster />} />
      </Routes>
      <Outlet />
    </>
  );
};
export { Subscriptons };
