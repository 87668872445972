import { Camera } from "react-bootstrap-icons";
import { useImageBase } from "../hooks/company-image.hook";
const ProfileImage = ({
  canupdate = false,
  previewImage,
  company,
  style,
  onChangeClicked,
}) => {
   
  var initials;
  //   var fullName = user != null ? `${user.firstName} ${user.lastName}` : "";
  initials = company.title
    .split(" ")
    .map((name) => name[0])
    .join("")
    .toUpperCase();
  if (initials.length > 2) {
    initials = initials.substring(0, 2);
  }

  const { imageBase } = useImageBase(company.Id);
  return (
    <div>
      <div className={`img-preview-container ${style}`}>
        {previewImage ? (
          <img src={previewImage} alt="" className={`profile-image ${style}`} />
        ) : (
          <>
            {company.profileImage ? (
              <img
                src={imageBase}
                className={`profile-image ${style}`}
                alt=""
              />
            ) : (
              <>
                <div className={`profile-initials ${style}`}>{initials}</div>
              </>
            )}
          </>
        )}
        {canupdate && (
          <button
            type="button"
            className="icon-button bg-primary rounded-button"
            onClick={onChangeClicked}
          >
            <Camera />
          </button>
        )}
      </div>
    </div>
  );
};
export { ProfileImage };
