export const Leadership = () => (
  <section className="ftco-section">
    <div className="container">
      <div className="row justify-content-center pb-4">
        <div className="col-lg-7 text-center heading-section">
          <span className="subheading">Meet Our Brilliant Minds</span>
          <h2 className="mb-5">Our Leadership Team</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 col-lg-3">
          <div className="staff">
            <div className="img-wrap d-flex align-items-stretch">
              <div
                className="img align-self-stretch"
                style={{
                  backgroundImage: `url(../assets/images/xstaff-1.jpg.pagespeed.ic.eGaw1QpE70.jpg)`,
                }}
              ></div>
            </div>
            <div className="text text-center">
              <h3 className="mb-2">Jason Smith</h3>
              <span className="position mb-2">President &amp; CEO</span>
              <div className="faded">
                <ul className="ftco-social text-center">
                  <li className="ftco-animate">
                    <a
                      href="#"
                      className="d-flex align-items-center justify-content-center"
                    >
                      <span className="fa fa-twitter"></span>
                    </a>
                  </li>
                  <li className="ftco-animate">
                    <a
                      href="#"
                      className="d-flex align-items-center justify-content-center"
                    >
                      <span className="fa fa-facebook"></span>
                    </a>
                  </li>
                  <li className="ftco-animate">
                    <a
                      href="#"
                      className="d-flex align-items-center justify-content-center"
                    >
                      <span className="fa fa-google"></span>
                    </a>
                  </li>
                  <li className="ftco-animate">
                    <a
                      href="#"
                      className="d-flex align-items-center justify-content-center"
                    >
                      <span className="fa fa-instagram"></span>
                    </a>
                  </li>
                </ul>
                <p>
                  I am an ambitious workaholic, but apart from that, pretty
                  simple person.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-3">
          <div className="staff">
            <div className="img-wrap d-flex align-items-stretch">
              <div
                className="img align-self-stretch"
                style={{
                  backgroundImage: `url(../assets/images/xstaff-1.jpg.pagespeed.ic.eGaw1QpE70.jpg)`,
                }}
              ></div>
            </div>
            <div className="text text-center">
              <h3 className="mb-2">Jeffrey Rockenson</h3>
              <span className="position mb-2">Executive Vice President</span>
              <div className="faded">
                <ul className="ftco-social text-center">
                  <li className="ftco-animate">
                    <a
                      href="#"
                      className="d-flex align-items-center justify-content-center"
                    >
                      <span className="fa fa-twitter"></span>
                    </a>
                  </li>
                  <li className="ftco-animate">
                    <a
                      href="#"
                      className="d-flex align-items-center justify-content-center"
                    >
                      <span className="fa fa-facebook"></span>
                    </a>
                  </li>
                  <li className="ftco-animate">
                    <a
                      href="#"
                      className="d-flex align-items-center justify-content-center"
                    >
                      <span className="fa fa-google"></span>
                    </a>
                  </li>
                  <li className="ftco-animate">
                    <a
                      href="#"
                      className="d-flex align-items-center justify-content-center"
                    >
                      <span className="fa fa-instagram"></span>
                    </a>
                  </li>
                </ul>
                <p>
                  I am an ambitious workaholic, but apart from that, pretty
                  simple person.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-3">
          <div className="staff">
            <div className="img-wrap d-flex align-items-stretch">
              <div
                className="img align-self-stretch"
                style={{
                  backgroundImage: `url(../assets/images/xstaff-1.jpg.pagespeed.ic.eGaw1QpE70.jpg)`,
                }}
              ></div>
            </div>
            <div className="text text-center">
              <h3 className="mb-2">Jason Smith</h3>
              <span className="position mb-2">General Manager</span>
              <div className="faded">
                <ul className="ftco-social text-center">
                  <li className="ftco-animate">
                    <a
                      href="#"
                      className="d-flex align-items-center justify-content-center"
                    >
                      <span className="fa fa-twitter"></span>
                    </a>
                  </li>
                  <li className="ftco-animate">
                    <a
                      href="#"
                      className="d-flex align-items-center justify-content-center"
                    >
                      <span className="fa fa-facebook"></span>
                    </a>
                  </li>
                  <li className="ftco-animate">
                    <a
                      href="#"
                      className="d-flex align-items-center justify-content-center"
                    >
                      <span className="fa fa-google"></span>
                    </a>
                  </li>
                  <li className="ftco-animate">
                    <a
                      href="#"
                      className="d-flex align-items-center justify-content-center"
                    >
                      <span className="fa fa-instagram"></span>
                    </a>
                  </li>
                </ul>
                <p>
                  I am an ambitious workaholic, but apart from that, pretty
                  simple person.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-3">
          <div className="staff">
            <div className="img-wrap d-flex align-items-stretch">
              <div
                className="img align-self-stretch"
                style={{
                  backgroundImage: `url(../assets/images/xstaff-1.jpg.pagespeed.ic.eGaw1QpE70.jpg)`,
                }}
              ></div>
            </div>
            <div className="text text-center">
              <h3 className="mb-2">Jason Smith</h3>
              <span className="position mb-2">Strategic Consultant</span>
              <div className="faded">
                <ul className="ftco-social text-center">
                  <li className="ftco-animate">
                    <a
                      href="#"
                      className="d-flex align-items-center justify-content-center"
                    >
                      <span className="fa fa-twitter"></span>
                    </a>
                  </li>
                  <li className="ftco-animate">
                    <a
                      href="#"
                      className="d-flex align-items-center justify-content-center"
                    >
                      <span className="fa fa-facebook"></span>
                    </a>
                  </li>
                  <li className="ftco-animate">
                    <a
                      href="#"
                      className="d-flex align-items-center justify-content-center"
                    >
                      <span className="fa fa-google"></span>
                    </a>
                  </li>
                  <li className="ftco-animate">
                    <a
                      href="#"
                      className="d-flex align-items-center justify-content-center"
                    >
                      <span className="fa fa-instagram"></span>
                    </a>
                  </li>
                </ul>
                <p>
                  I am an ambitious workaholic, but apart from that, pretty
                  simple person.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-3">
          <div className="staff">
            <div className="img-wrap d-flex align-items-stretch">
              <div
                className="img align-self-stretch"
                style={{
                  backgroundImage: `url(../assets/images/xstaff-1.jpg.pagespeed.ic.eGaw1QpE70.jpg)`,
                }}
              ></div>
            </div>
            <div className="text text-center">
              <h3 className="mb-2">Jason Smith</h3>
              <span className="position mb-2">President &amp; CEO</span>
              <div className="faded">
                <ul className="ftco-social text-center">
                  <li className="ftco-animate">
                    <a
                      href="#"
                      className="d-flex align-items-center justify-content-center"
                    >
                      <span className="fa fa-twitter"></span>
                    </a>
                  </li>
                  <li className="ftco-animate">
                    <a
                      href="#"
                      className="d-flex align-items-center justify-content-center"
                    >
                      <span className="fa fa-facebook"></span>
                    </a>
                  </li>
                  <li className="ftco-animate">
                    <a
                      href="#"
                      className="d-flex align-items-center justify-content-center"
                    >
                      <span className="fa fa-google"></span>
                    </a>
                  </li>
                  <li className="ftco-animate">
                    <a
                      href="#"
                      className="d-flex align-items-center justify-content-center"
                    >
                      <span className="fa fa-instagram"></span>
                    </a>
                  </li>
                </ul>
                <p>
                  I am an ambitious workaholic, but apart from that, pretty
                  simple person.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-3">
          <div className="staff">
            <div className="img-wrap d-flex align-items-stretch">
              <div
                className="img align-self-stretch"
                style={{
                  backgroundImage: `url(../assets/images/xstaff-1.jpg.pagespeed.ic.eGaw1QpE70.jpg)`,
                }}
              ></div>
            </div>
            <div className="text text-center">
              <h3 className="mb-2">Jeffrey Rockenson</h3>
              <span className="position mb-2">Executive Vice President</span>
              <div className="faded">
                <ul className="ftco-social text-center">
                  <li className="ftco-animate">
                    <a
                      href="#"
                      className="d-flex align-items-center justify-content-center"
                    >
                      <span className="fa fa-twitter"></span>
                    </a>
                  </li>
                  <li className="ftco-animate">
                    <a
                      href="#"
                      className="d-flex align-items-center justify-content-center"
                    >
                      <span className="fa fa-facebook"></span>
                    </a>
                  </li>
                  <li className="ftco-animate">
                    <a
                      href="#"
                      className="d-flex align-items-center justify-content-center"
                    >
                      <span className="fa fa-google"></span>
                    </a>
                  </li>
                  <li className="ftco-animate">
                    <a
                      href="#"
                      className="d-flex align-items-center justify-content-center"
                    >
                      <span className="fa fa-instagram"></span>
                    </a>
                  </li>
                </ul>
                <p>
                  I am an ambitious workaholic, but apart from that, pretty
                  simple person.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-3">
          <div className="staff">
            <div className="img-wrap d-flex align-items-stretch">
              <div
                className="img align-self-stretch"
                style={{
                  backgroundImage: `url(../assets/images/xstaff-1.jpg.pagespeed.ic.eGaw1QpE70.jpg)`,
                }}
              ></div>
            </div>
            <div className="text text-center">
              <h3 className="mb-2">Jason Smith</h3>
              <span className="position mb-2">General Manager</span>
              <div className="faded">
                <ul className="ftco-social text-center">
                  <li className="ftco-animate">
                    <a
                      href="#"
                      className="d-flex align-items-center justify-content-center"
                    >
                      <span className="fa fa-twitter"></span>
                    </a>
                  </li>
                  <li className="ftco-animate">
                    <a
                      href="#"
                      className="d-flex align-items-center justify-content-center"
                    >
                      <span className="fa fa-facebook"></span>
                    </a>
                  </li>
                  <li className="ftco-animate">
                    <a
                      href="#"
                      className="d-flex align-items-center justify-content-center"
                    >
                      <span className="fa fa-google"></span>
                    </a>
                  </li>
                  <li className="ftco-animate">
                    <a
                      href="#"
                      className="d-flex align-items-center justify-content-center"
                    >
                      <span className="fa fa-instagram"></span>
                    </a>
                  </li>
                </ul>
                <p>
                  I am an ambitious workaholic, but apart from that, pretty
                  simple person.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-3">
          <div className="staff">
            <div className="img-wrap d-flex align-items-stretch">
              <div
                className="img align-self-stretch"
                style={{
                  backgroundImage: `url(../assets/images/xstaff-1.jpg.pagespeed.ic.eGaw1QpE70.jpg)`,
                }}
              ></div>
            </div>
            <div className="text text-center">
              <h3 className="mb-2">Jason Smith</h3>
              <span className="position mb-2">Strategic Consultant</span>
              <div className="faded">
                <ul className="ftco-social text-center">
                  <li className="ftco-animate">
                    <a
                      href="#"
                      className="d-flex align-items-center justify-content-center"
                    >
                      <span className="fa fa-twitter"></span>
                    </a>
                  </li>
                  <li className="ftco-animate">
                    <a
                      href="#"
                      className="d-flex align-items-center justify-content-center"
                    >
                      <span className="fa fa-facebook"></span>
                    </a>
                  </li>
                  <li className="ftco-animate">
                    <a
                      href="#"
                      className="d-flex align-items-center justify-content-center"
                    >
                      <span className="fa fa-google"></span>
                    </a>
                  </li>
                  <li className="ftco-animate">
                    <a
                      href="#"
                      className="d-flex align-items-center justify-content-center"
                    >
                      <span className="fa fa-instagram"></span>
                    </a>
                  </li>
                </ul>
                <p>
                  I am an ambitious workaholic, but apart from that, pretty
                  simple person.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);
