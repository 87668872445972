import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useGetJobQuery } from "../redux/jobs.api";
import { ApplyNow } from "./apply.component";

import { FileUpload } from "./upload-cv.component";

const JobDescription = (props) => {
  const { id } = useParams();
  const { data: jobItem, isLoading, isSuccess } = useGetJobQuery(id);
  const { user } = useSelector((state) => state.auth);

  // action handlers

  return (
    <>
      <div className="row">
        <div className="col-md-8">
          {!isLoading && isSuccess && jobItem != null && (
            <div>
              <h4>{jobItem.title}</h4>
              {JSON.stringify(jobItem)}
              <div className="my-5 d-flex">
                <ApplyNow user={user} jobId={jobItem.Id} />
                <Link className="btn btn-primary ms-2 me-2" to="/jobs">
                  Back
                </Link>

                {/* <Link className="btn btn-primary" to={`/jobs/edit/${id}`}>
                  Edit
                </Link> */}
              </div>
            </div>
          )}
        </div>

        <div className="col-md-4">
          <div className="card">
            <div className="card-body">
              <p>Applications 10</p>
              <p>match 80%</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { JobDescription };
