import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { apiBaseUrl as baseUrl } from "../_constants/index";

export const configApi = createApi({
  reducerPath: "configApi",
  baseQuery: fetchBaseQuery({ baseUrl }),
  tagTypes: ["Post", "DELETE"],
  endpoints: (builder) => ({
    getAllConfigs: builder.query({
      query: () => `/configs/index.php`,
      providesTags: ["Post"],
    }),
    getConfig: builder.query({
      query: (id) => `/configs/details.php?id=${id}`,
      providesTags: ["Post"],
    }),
    searchConfig: builder.query({
      query: ({ name, value }) =>
        `/configs/search.php?configName=${name}&configValue=${value}`,
    }),
    postConfig: builder.mutation({
      query: (obj) => ({
        url: `/configs/index.php`,
        method: "POST",
        body: obj,
      }),
      invalidatesTags: ["Post"],
    }),
    putConfig: builder.mutation({
      query: (obj) => ({
        url: `/configs/index.php`,
        method: "PUT",
        body: obj,
      }),
      invalidatesTags: ["Post"],
    }),
    deleteConfig: builder.mutation({
      query: (id) => ({
        url: `/configs/index.php`,
        method: "DELETE",
        body: JSON.stringify({ id }),
      }),
      invalidatesTags: ["Post"],
    }),
  }),
});

export const {
  useGetConfigQuery,
  useGetAllConfigsQuery,
  useSearchConfigQuery,
  usePostConfigMutation,
  usePutConfigMutation,
  useDeleteConfigMutation,
} = configApi;
