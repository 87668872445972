export const GetStarted = () => (
  <section
    className="ftco-intro-2 img"
    style={{
      backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/image-01.jpg)`,
    }}
  >
    <div className="overlay"></div>
    <div className="container-xl">
      <div className="row justify-content-center">
        <div className="col-lg-10 col-xl-10">
          <div className="row">
            <div className="col-md-8 d-flex align-items-center">
              <div>
                <span className="subheading">Prepare for takeoff</span>
                <h1 className="mb-md-0 mb-4">Looking for business opportunity?</h1>
              </div>
            </div>
            <div className="col-md-4 d-flex align-items-center">
              <p className="mb-0">
                <a href="#" className="btn btn-white py-md-4 py-3 px-md-5 px-4">
                  Get Started
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
);
