import { useEffect } from "react";
import { NavLink, useSearchParams } from "react-router-dom";
import * as Icons from "react-bootstrap-icons";

import { useGetAllProfilesQuery } from "../redux/profile.api";
import { useAuthentication } from "../hooks/auth.hook";
import { GridDisplay } from "./grid";
import { ListItem } from "./list";

const ProfileMaster = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { data, isSuccess, isLoading, refetch } = useGetAllProfilesQuery({});
  const { isLoggedIn } = useAuthentication();
  const view = searchParams.get("view");

  const changeDisplay = () => {
    if (view === "list") {
      setSearchParams({ view: "grid" });
    } else {
      setSearchParams({ view: "list" });
    }
  };

  return (
    <>
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <h4>Profiles</h4>
        <div>
          <button className="btn btn-primary" onClick={refetch}>
            <Icons.ArrowRepeat size={20} />
          </button>
          {isLoggedIn && (
            <>
              <NavLink to="/profiles/create" className="btn btn-primary mx-2">
                ADD
              </NavLink>
            </>
          )}
        </div>
      </div>

      {isLoading && <>loading...</>}
      {isSuccess && data && (view === null || view === "grid") && (
        <GridDisplay data={data} />
      )}

      {isSuccess && data && view === "list" && <ListItem data={data} />}
    </>
  );
};

export { ProfileMaster };
