import { Routes, Route, Outlet } from "react-router-dom";
import { JobsMaster } from "./jobs.master";

const Jobs = () => {
  return (
    <div className="ftco-section">
      <div className="container">
        <Routes>
          <Route exact path="/" element={<JobsMaster />} />
        </Routes>
        <Outlet />
      </div>
    </div>
  );
};

export { Jobs };
export * from "./job-description";
export * from "./job-form";
