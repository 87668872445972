const ProfileDetails = () => {
  return (
    <>
      <section
        className="hero-wrap hero-wrap-2"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/xbg_3.jpg.pagespeed.ic.M6GN2K7j8o.jpg)`,
        }}
      >
        <div className="overlay"></div>
        <div className="container">
          <div className="row no-gutters slider-text align-items-end justify-content-center">
            <div className="col-md-9 text-center mb-5">
              <p className="breadcrumbs">
                <span className="me-2">
                  <a href="index-2.html">
                    Home <i className="fa fa-chevron-right"></i>
                  </a>
                </span>
                <span className="me-2">
                  <a href="/matrimony">
                    Matrimony <i className="fa fa-chevron-right"></i>
                  </a>
                </span>
                <span>
                  Profile Details <i className="fa fa-chevron-right"></i>
                </span>
              </p>
              <h1 className="mb-0 bread">Profile Details</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="ftco-section">
        <div className="container">
          <div className="row g-lg-5">
            <div className="col-lg-8">
              <p>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/image_7.jpg`}
                  alt=""
                  className="img-fluid"
                ></img>
              </p>
              <h2 className="mb-3">
                Incorporation is A Big Milestone for Your Business
              </h2>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                Reiciendis, eius mollitia suscipit, quisquam doloremque
                distinctio perferendis et doloribus unde architecto optio
                laboriosam porro adipisci sapiente officiis nemo accusamus ad
                praesentium? Esse minima nisi et. Dolore perferendis, enim
                praesentium omnis, iste doloremque quia officia optio deserunt
                molestiae voluptates soluta architecto tempora.
              </p>
              <p>
                Molestiae cupiditate inventore animi, maxime sapiente optio,
                illo est nemo veritatis repellat sunt doloribus nesciunt! Minima
                laborum magni reiciendis qui voluptate quisquam voluptatem
                soluta illo eum ullam incidunt rem assumenda eveniet eaque sequi
                deleniti tenetur dolore amet fugit perspiciatis ipsa, odit.
                Nesciunt dolor minima esse vero ut ea, repudiandae suscipit!
              </p>
              <h2 className="mb-3 mt-5">#2. Creative WordPress Themes</h2>
              <p>
                Temporibus ad error suscipit exercitationem hic molestiae totam
                obcaecati rerum, eius aut, in. Exercitationem atque quidem
                tempora maiores ex architecto voluptatum aut officia doloremque.
                Error dolore voluptas, omnis molestias odio dignissimos culpa ex
                earum nisi consequatur quos odit quasi repellat qui officiis
                reiciendis incidunt hic non? Debitis commodi aut, adipisci.
              </p>
              <p>
                <img
                  src={`${process.env.PUBLIC_URL}/assets/images/ximage_3.jpg.pagespeed.ic.CWHmT1gFhf.jpg`}
                  alt=""
                  className="img-fluid"
                ></img>
              </p>
              <p>
                Quisquam esse aliquam fuga distinctio, quidem delectus veritatis
                reiciendis. Nihil explicabo quod, est eos ipsum. Unde aut non
                tenetur tempore, nisi culpa voluptate maiores officiis quis vel
                ab consectetur suscipit veritatis nulla quos quia aspernatur
                perferendis, libero sint. Error, velit, porro. Deserunt minus,
                quibusdam iste enim veniam, modi rem maiores.
              </p>
              <div className="tag-widget post-tag-container mb-5 mt-5">
                <div className="tagcloud">
                  <a href="#" className="tag-cloud-link">
                    finance
                  </a>
                  <a href="#" className="tag-cloud-link">
                    funds
                  </a>
                  <a href="#" className="tag-cloud-link">
                    invest
                  </a>
                  <a href="#" className="tag-cloud-link">
                    business
                  </a>
                </div>
              </div>
              <div className="about-author d-flex p-4 bg-light rounded">
                <div
                  className="bio me-md-4 img"
                  style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/xperson_1.jpg.pagespeed.ic.P4pHl6glbj.jpg)`,
                  }}
                ></div>
                <div className="desc">
                  <h3>John Henderson</h3>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                    Ducimus itaque, autem necessitatibus voluptate quod mollitia
                    delectus aut, sunt placeat nam vero culpa sapiente
                    consectetur similique, inventore eos fugit cupiditate
                    numquam!
                  </p>
                </div>
              </div>
              <div className="pt-5 mt-5">
                <h3 className="mb-5" style={{ fontSize: "34px" }}>
                  6 Comments
                </h3>
                <ul className="comment-list">
                  <li className="comment">
                    <div className="vcard bio">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/xperson_1.jpg.pagespeed.ic.P4pHl6glbj.jpg`}
                        alt="placeholder"
                      ></img>
                    </div>
                    <div className="comment-body">
                      <h3>John Doe</h3>
                      <div className="meta">January 27, 2021 at 7:20am</div>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Pariatur quidem laborum necessitatibus, ipsam
                        impedit vitae autem, eum officia, fugiat saepe enim
                        sapiente iste iure! Quam voluptas earum impedit
                        necessitatibus, nihil?
                      </p>
                      <p>
                        <a href="#" className="reply">
                          Reply
                        </a>
                      </p>
                    </div>
                  </li>
                  <li className="comment">
                    <div className="vcard bio">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/xperson_1.jpg.pagespeed.ic.P4pHl6glbj.jpg`}
                        alt="placeholder"
                      ></img>
                    </div>
                    <div className="comment-body">
                      <h3>John Doe</h3>
                      <div className="meta">January 27, 2021 at 7:20am</div>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Pariatur quidem laborum necessitatibus, ipsam
                        impedit vitae autem, eum officia, fugiat saepe enim
                        sapiente iste iure! Quam voluptas earum impedit
                        necessitatibus, nihil?
                      </p>
                      <p>
                        <a href="#" className="reply">
                          Reply
                        </a>
                      </p>
                    </div>
                    <ul className="children">
                      <li className="comment">
                        <div className="vcard bio">
                          <img
                            src={`${process.env.PUBLIC_URL}/assets/images/xperson_1.jpg.pagespeed.ic.P4pHl6glbj.jpg`}
                            alt="placeholder"
                          ></img>
                        </div>
                        <div className="comment-body">
                          <h3>John Doe</h3>
                          <div className="meta">January 27, 2021 at 7:20am</div>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipisicing
                            elit. Pariatur quidem laborum necessitatibus, ipsam
                            impedit vitae autem, eum officia, fugiat saepe enim
                            sapiente iste iure! Quam voluptas earum impedit
                            necessitatibus, nihil?
                          </p>
                          <p>
                            <a href="#" className="reply">
                              Reply
                            </a>
                          </p>
                        </div>
                        <ul className="children">
                          <li className="comment">
                            <div className="vcard bio">
                              <img
                                src={`${process.env.PUBLIC_URL}/assets/images/xperson_1.jpg.pagespeed.ic.P4pHl6glbj.jpg`}
                                alt="placeholder"
                              ></img>
                            </div>
                            <div className="comment-body">
                              <h3>John Doe</h3>
                              <div className="meta">
                                January 27, 2021 at 7:20am
                              </div>
                              <p>
                                Lorem ipsum dolor sit amet, consectetur
                                adipisicing elit. Pariatur quidem laborum
                                necessitatibus, ipsam impedit vitae autem, eum
                                officia, fugiat saepe enim sapiente iste iure!
                                Quam voluptas earum impedit necessitatibus,
                                nihil?
                              </p>
                              <p>
                                <a href="#" className="reply">
                                  Reply
                                </a>
                              </p>
                            </div>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                  <li className="comment">
                    <div className="vcard bio">
                      <img
                        src={`${process.env.PUBLIC_URL}/assets/images/xperson_1.jpg.pagespeed.ic.P4pHl6glbj.jpg`}
                        alt="placeholder"
                      ></img>
                    </div>
                    <div className="comment-body">
                      <h3>John Doe</h3>
                      <div className="meta">January 27, 2021 at 7:20am</div>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing
                        elit. Pariatur quidem laborum necessitatibus, ipsam
                        impedit vitae autem, eum officia, fugiat saepe enim
                        sapiente iste iure! Quam voluptas earum impedit
                        necessitatibus, nihil?
                      </p>
                      <p>
                        <a href="#" className="reply">
                          Reply
                        </a>
                      </p>
                    </div>
                  </li>
                </ul>

                <div className="comment-form-wrap pt-5">
                  <h3 className="mb-5" style={{ fontSize: "34px" }}>
                    Leave a comment
                  </h3>
                  <form action="#" className="p-4 p-lg-5 comment-form">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label for="name">Name *</label>
                          <input
                            type="text"
                            className="form-control"
                            id="name"
                          ></input>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label for="email">Email *</label>
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                          ></input>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label for="website">Website</label>
                          <input
                            type="url"
                            className="form-control"
                            id="website"
                          ></input>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <label for="message">Message</label>
                          <textarea
                            name=""
                            id="message"
                            cols="30"
                            rows="10"
                            className="form-control"
                          ></textarea>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <input
                            type="submit"
                            value="Post Comment"
                            className="btn py-3 px-4 btn-primary"
                          ></input>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-4 sidebar pl-md-4">
              <div className="sidebar-box bg-light rounded">
                <form action="#" className="search-form">
                  <div className="form-group">
                    <span className="icon fa fa-search"></span>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search..."
                    ></input>
                  </div>
                </form>
              </div>
              <div className="sidebar-box">
                <h3>Our Services</h3>
                <ul className="categories me-md-4">
                  <li>
                    <a href="#">
                      <span className="ion-ios-arrow-round-forward"></span>
                      Financial Planning
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span className="ion-ios-arrow-round-forward"></span>
                      Investments Management
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span className="ion-ios-arrow-round-forward"></span>
                      Business Loan
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span className="ion-ios-arrow-round-forward"></span>Taxes
                      Consulting
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span className="ion-ios-arrow-round-forward"></span>
                      Insurance Consulting
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span className="ion-ios-arrow-round-forward"></span>
                      Retirement Planning
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span className="ion-ios-arrow-round-forward"></span>Risk
                      Management
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <span className="ion-ios-arrow-round-forward"></span>
                      Technology Consulting
                    </a>
                  </li>
                </ul>
              </div>
              <div className="sidebar-box">
                <h3>Recent Blog</h3>
                <div className="block-21 mb-4 d-flex">
                  <a
                    className="blog-img me-4"
                    style={{
                      backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/ximage_1.jpg.pagespeed.ic.hLNkZmeXOJ.jpg)`,
                    }}
                  ></a>
                  <div className="text">
                    <h3 className="heading">
                      <a href="#">
                        Incorporation is A Big Milestone for Your Business
                      </a>
                    </h3>
                    <div className="meta">
                      <div>
                        <a href="#">
                          <span className="fa fa-calendar"></span> January 27,
                          2021
                        </a>
                      </div>
                      <div>
                        <a href="#">
                          <span className="fa fa-user"></span> Admin
                        </a>
                      </div>
                      <div>
                        <a href="#">
                          <span className="fa fa-comment"></span> 19
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="block-21 mb-4 d-flex">
                  <a
                    className="blog-img me-4"
                    style={{
                      backgroundImage: `url(${process.env.PUBLIC_URL}/assets.images/ximage_2.jpg.pagespeed.ic.mQGyn1P_Li.jpg)`,
                    }}
                  ></a>
                  <div className="text">
                    <h3 className="heading">
                      <a href="#">
                        Incorporation is A Big Milestone for Your Business
                      </a>
                    </h3>
                    <div className="meta">
                      <div>
                        <a href="#">
                          <span className="fa fa-calendar"></span> January 27,
                          2021
                        </a>
                      </div>
                      <div>
                        <a href="#">
                          <span className="fa fa-user"></span> Admin
                        </a>
                      </div>
                      <div>
                        <a href="#">
                          <span className="fa fa-comment"></span> 19
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="block-21 mb-4 d-flex">
                  <a
                    className="blog-img me-4"
                    style={{
                      backgroundImage: `url(${process.env.PUBLIC_URL}/assets/images/ximage_3.jpg.pagespeed.ic.CWHmT1gFhf.jpg)`,
                    }}
                  ></a>
                  <div className="text">
                    <h3 className="heading">
                      <a href="#">
                        Incorporation is A Big Milestone for Your Business
                      </a>
                    </h3>
                    <div className="meta">
                      <div>
                        <a href="#">
                          <span className="fa fa-calendar"></span> January 27,
                          2021
                        </a>
                      </div>
                      <div>
                        <a href="#">
                          <span className="fa fa-user"></span> Admin
                        </a>
                      </div>
                      <div>
                        <a href="#">
                          <span className="fa fa-comment"></span> 19
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="sidebar-box">
                <h3>Tag Cloud</h3>
                <div className="tagcloud">
                  <a href="#" className="tag-cloud-link">
                    finance
                  </a>
                  <a href="#" className="tag-cloud-link">
                    fund
                  </a>
                  <a href="#" className="tag-cloud-link">
                    consult
                  </a>
                  <a href="#" className="tag-cloud-link">
                    business
                  </a>
                  <a href="#" className="tag-cloud-link">
                    funding
                  </a>
                  <a href="#" className="tag-cloud-link">
                    money
                  </a>
                  <a href="#" className="tag-cloud-link">
                    invest
                  </a>
                  <a href="#" className="tag-cloud-link">
                    plan
                  </a>
                  <a href="#" className="tag-cloud-link">
                    tax
                  </a>
                </div>
              </div>
              <div className="sidebar-box">
                <h3>Paragraph</h3>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Ducimus itaque, autem necessitatibus voluptate quod mollitia
                  delectus aut, sunt placeat nam vero culpa sapiente consectetur
                  similique, inventore eos fugit cupiditate numquam!
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export { ProfileDetails };
